import { Injectable } from '@angular/core';
import { MemoryStorageService } from './memory-storage.service';
import { Subject } from 'rxjs';

@Injectable()
export class CacheService {
  public cacheItemInvalidated$;
  private readonly cacheKeyPrefix = 'DataCacheService_';
  private readonly tagStorageKey = 'DataCacheServiceTags';
  private readonly cacheItemInvalidatedSource = new Subject<{
    key: string;
    local: boolean;
  }>();
  private storageTags;

  constructor(readonly storageService: MemoryStorageService) {
    this.cacheItemInvalidated$ = this.cacheItemInvalidatedSource.asObservable();
  }

  public removeTag(tag: string, local = true) {
    this.cacheItemInvalidatedSource.next({ key: tag, local });
    if (tag) {
      if (!this.tags[tag]) {
        return;
      }
      this.tags[tag].forEach((key: string) => {
        this.storageService.remove(this.toStorageKey(key));
        this.cacheItemInvalidatedSource.next({ key, local });
      });
      delete this.tags[tag];
      this.set(this.tagStorageKey, this.tags);
      const exactMatch = this.storageService.getKeys().find((k) => k === tag);
      if (exactMatch) {
        this.storageService.remove(this.toStorageKey(exactMatch));
        this.cacheItemInvalidatedSource.next({ key: exactMatch, local });
      }
    }
  }

  public get<T>(key: string): T {
    const storageValue = this.storageService.get(this.toStorageKey(key));
    if (!storageValue) {
      return null;
    }
    // TagStorageKey never expires
    if (storageValue.expires > Date.now() || key === this.tagStorageKey) {
      return storageValue.value as T;
    } else {
      this.remove(key);
      return null;
    }
  }

  public set(
    key: string,
    value: any,
    options: { maxAge: number; tag?: string } = { maxAge: 60 }
  ) {
    const storageKey = this.toStorageKey(key);
    const expirationDate = new Date();
    expirationDate.setMinutes(expirationDate.getMinutes() + options.maxAge);
    const storageValue = {
      value,
      expires: expirationDate.valueOf(),
    };
    if (this.storageService.set(storageKey, storageValue) && options.tag) {
      if (
        this.tags[options.tag] &&
        this.tags[options.tag].indexOf(key) === -1
      ) {
        this.tags[options.tag].push(key);
      } else if (!this.tags[options.tag]) {
        this.tags[options.tag] = [key];
      }
      this.set(this.tagStorageKey, this.tags);
    }
  }

  public removePartial(partialKey: string) {
    this.getKeys()
      .filter((k) => k.toUpperCase().includes(partialKey.toUpperCase()))
      .forEach((k) => this.remove(k));
  }

  public remove(key: string) {
    this.storageService.remove(this.toStorageKey(key));
    // Add back when starting to deal with tags with multiple values
    //let index: number;
    //for (let tag in this.tags) {
    //   console.log(tag);
    //   index = this.tags[tag].indexOf(key);
    //   if (index !== -1) {
    //      this.tags[tag].splice(index, 1);
    //      this.set(this.tagStorageKey, this.tags);
    //      break;
    //   }
    //}
  }

  public clear() {
    this.storageService.clear();
  }

  private get tags(): { [key: string]: string[] } {
    if (!this.storageTags) {
      this.storageTags = this.get(this.tagStorageKey) || {};
    }
    return this.storageTags;
  }
  // TODO: This might not be the right way to go

  private getKeys() {
    return this.storageService
      .getKeys()
      .map((k) => k.replace(this.cacheKeyPrefix, ''));
  }

  private toStorageKey(key: string) {
    return this.cacheKeyPrefix + key;
  }
}
