<ng-container
  *ngIf="
    filteredUpcomingEventsByDate?.length ||
    filteredPastEventsByDate?.length ||
    hasMoreEvents
  "
>
  <div class="events__scroll" data-testid="events">
    <div class="events__scroll-line"></div>
    <div class="events__content">
      <!-- Upcoming Events -->
      <div
        *ngIf="filteredUpcomingEventsByDate?.length"
        class="events__upcoming"
      >
        <ng-container *ngIf="loaded">
          <ion-item-divider class="divider">
            <ion-label class="body2" data-testid="past-events">
              {{ "FARM_DETAIL_PAGE.SUBSEGMENTS.UPCOMING" | translate }}
              {{ "FARM_DETAIL_PAGE.SEGMENTS.EVENTS" | translate }}
            </ion-label>
          </ion-item-divider>
          <!-- Events -->
          <div
            *ngFor="let upcomingEvent of filteredUpcomingEventsByDate"
            class="event"
          >
            <div
              class="event__date"
              id="{{ upcomingEvent.id }}"
              data-testid="upcoming-event-date"
            >
              <div
                class="dot {{
                  upcomingEvent?.color ? upcomingEvent?.color : 'default'
                }}"
              ></div>
              <span class="caption">{{ upcomingEvent?.dateLabel }}</span>
              <span class="line"></span>
            </div>
            <div
              *ngFor="let event of upcomingEvent?.events"
              class="event__card {{
                event?.eventColor ? event?.eventColor : 'default'
              }}"
              data-pendoid="upcoming-event-timeline-card"
              data-testid="upcoming-event-card"
              id="{{ event?.id }}"
              (click)="onSelectEvent(event)"
            >
              <div class="event__card-wrapper">
                <div class="event__card-header">
                  <span class="body2">{{ event?.eventName | translate }}</span>
                  <span class="subtitle caption">{{
                    event?.formattedTimestamp
                  }}</span>
                </div>

                <div class="event__card-subheader">
                  <span class="caption"
                    >{{ "HOUSE" | translate }} {{ event?.houseNo }}</span
                  >
                </div>
              </div>

              <div class="event__card-kpi">
                <span class="title">{{ event?.kpi ?? "-" }} </span>
                <span *ngIf="!!event?.refNo" class="info caption">
                  {{ "LOAD" | translate }}:
                  {{ event?.refNo ? event?.refNo : "-" }}
                </span>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!loaded">
          <div *ngFor="let upcomingEvent of [].constructor(2)" class="event">
            <div class="event__date">
              <div class="dot"></div>
              <span class="caption">
                <ion-skeleton-text
                  animated
                  style="width: 80px"
                ></ion-skeleton-text>
              </span>
              <span class="line"></span>
            </div>
            <div *ngFor="let event of [].constructor(2)" class="event__card">
              <div class="event__card-wrapper">
                <div class="event__card-header">
                  <span class="body2">
                    <ion-skeleton-text
                      animated
                      style="width: 96px"
                    ></ion-skeleton-text>
                  </span>
                  <span class="subtitle caption">
                    <ion-skeleton-text
                      animated
                      style="width: 64px"
                    ></ion-skeleton-text>
                  </span>
                </div>

                <div class="event__card-subheader">
                  <span class="caption">
                    <ion-skeleton-text
                      animated
                      style="width: 64px"
                    ></ion-skeleton-text>
                  </span>
                </div>
              </div>

              <div class="event__card-kpi">
                <span class="title">
                  <ion-skeleton-text
                    animated
                    style="width: 96px"
                  ></ion-skeleton-text>
                </span>
                <span class="info caption">
                  <ion-skeleton-text
                    animated
                    style="width: 80px"
                  ></ion-skeleton-text>
                </span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- Past Events -->
      <div *ngIf="filteredPastEventsByDate?.length" class="events__past">
        <ng-container *ngIf="loaded">
          <div *ngIf="upcomingEventsByDate?.length">
            <div class="events__past-line"></div>
            <div class="marker"></div>
          </div>
          <ion-item-divider class="divider">
            <ion-label class="body2" data-testid="past-events">
              {{ "FARM_DETAIL_PAGE.SUBSEGMENTS.PAST" | translate }}
              {{ "FARM_DETAIL_PAGE.SEGMENTS.EVENTS" | translate }}
            </ion-label>
          </ion-item-divider>
          <!-- Events -->
          <div *ngFor="let pastEvent of filteredPastEventsByDate" class="event">
            <div
              class="event__date"
              id="{{ pastEvent.id }}"
              data-testid="past-event-date"
            >
              <div
                class="dot {{
                  pastEvent?.color ? pastEvent?.color : 'default'
                }}"
              ></div>
              <span class="caption">{{ pastEvent?.dateLabel }}</span>
              <div class="line"></div>
            </div>
            <div
              *ngFor="let event of pastEvent?.events"
              class="event__card {{
                event?.eventColor ? event?.eventColor : 'default'
              }}"
              id="{{ event?.id }}"
              data-pendoid="past-event-timeline-card"
              data-testid="past-event-card"
              (click)="onSelectEvent(event)"
            >
              <div class="event__card-wrapper">
                <div class="event__card-header">
                  <span class="body2">{{ event?.eventName | translate }}</span>
                  <span class="subtitle caption">{{
                    event?.formattedTimestamp
                  }}</span>
                </div>

                <div class="event__card-subheader">
                  <span
                    *ngIf="event.eventType !== eventTypeEnum.Processing"
                    class="caption"
                    >{{ "HOUSE" | translate }} {{ event?.houseNo }}</span
                  >
                  <span
                    *ngIf="event.eventType === eventTypeEnum.Processing"
                    class="caption"
                  >
                    {{ "REF_NO" | translate }} {{ event?.refNo }}</span
                  >
                </div>
              </div>

              <div class="event__card-kpi">
                <span class="title">{{ event?.kpi ?? "-" }} </span>
                <span class="info caption">
                  <ng-container
                    *ngIf="
                      event.eventType !== eventTypeEnum.Processing &&
                      !event.isActualFeedEvent &&
                      event?.refNo
                    "
                  >
                    {{ "LOAD" | translate }}:
                    {{ event?.refNo ? event?.refNo : "-" }}
                  </ng-container>
                </span>
                <ng-container *ngIf="event.isActualFeedEvent">
                  {{ event.feedTransCodeLabel | translate }}
                </ng-container>
                <ng-container *ngIf="event.isActualPlacementEvent">
                  {{ event?.himTransCodeLabel | translate }}
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!loaded">
          <div *ngFor="let upcomingEvent of [].constructor(2)" class="event">
            <div class="event__date">
              <div class="dot"></div>
              <span class="caption">
                <ion-skeleton-text
                  animated
                  style="width: 80px"
                ></ion-skeleton-text>
              </span>
              <span class="line"></span>
            </div>
            <div *ngFor="let event of [].constructor(2)" class="event__card">
              <div class="event__card-wrapper">
                <div class="event__card-header">
                  <span class="body2">
                    <ion-skeleton-text
                      animated
                      style="width: 96px"
                    ></ion-skeleton-text>
                  </span>
                  <span class="subtitle caption">
                    <ion-skeleton-text
                      animated
                      style="width: 64px"
                    ></ion-skeleton-text>
                  </span>
                </div>

                <div class="event__card-subheader">
                  <span class="caption">
                    <ion-skeleton-text
                      animated
                      style="width: 64px"
                    ></ion-skeleton-text>
                  </span>
                </div>
              </div>

              <div class="event__card-kpi">
                <span class="title">
                  <ion-skeleton-text
                    animated
                    style="width: 96px"
                  ></ion-skeleton-text>
                </span>
                <span class="info caption">
                  <ion-skeleton-text
                    animated
                    style="width: 80px"
                  ></ion-skeleton-text>
                </span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="hasMoreEvents" class="events__load">
        <ion-button color="primary" expand="block" (click)="loadMoreEvents()">
          {{ "LOAD_MORE" | translate }}
        </ion-button>
      </div>
    </div>
  </div>
</ng-container>
<ng-container
  *ngIf="
    !filteredUpcomingEventsByDate?.length &&
    !filteredPastEventsByDate?.length &&
    !hasMoreEvents
  "
>
  <div class="empty-events" data-testid="empty-state-events">
    <div class="empty-state__wrapper" text-center>
      <img
        class="empty-state__image"
        src="assets/sonar/empty-states/sonar-no-events-available.png"
      />
      <div class="empty-state__title">
        {{ "FARM_DETAIL_PAGE.EMPTY_STATE.EVENTS_TITLE" | translate }}
      </div>
      <p class="empty-state__message">
        {{ "FARM_DETAIL_PAGE.EMPTY_STATE.EVENTS_MESSAGE" | translate }}
      </p>
    </div>
  </div>
</ng-container>
