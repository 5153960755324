import { SonarDataType } from "@sonar/shared/sensor-type";
import { SonarData } from "./sonar-data";
import { NumberSymbol } from "@angular/common";
import { UnitConversionService } from "@sonar/core/unit-conversion.service";

/** @deprecated */
export class UnknownSonarData extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Decimal;
  isSensor: boolean = false;
  sonarDataType: SonarDataType = -1 as SonarDataType;
  decimalPlaces: string = '1.0-0';
  maxSensorCount: number = 0;


  labelKey: string = '--';
  statistic: string = '';
  iconName: string = '';
  sensorStyleClass: string = '';

  getPropertyNames = (prefix?: string, suffix?: string): string[] => [];
  getSensorUnit = (unitConversionService: UnitConversionService): string => ''; 
  convertUnit = (value: number, unitConversionService: UnitConversionService): number => value;
}