<div *ngIf="loaded" class="table__container">
  <table
    mat-table
    matSort
    [dataSource]="dataSource"
    class="table__container-wrapper"
  >
    <ng-container
      *ngFor="let column of columns; let i = index"
      [matColumnDef]="column.columnDef"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="caption"
        [class.cell-number]="column.clientDataType === clientDataType.Number"
        [class.cell-string]="column.clientDataType === clientDataType.String"
      >
        <span>{{ column?.header ? (column?.header | translate) : "-" }}</span>
      </th>
      <td mat-cell *matCellDef="let row" class="body2">
        <div
          *ngIf="row"
          class="cell-inner"
          [class.cell-number]="column.clientDataType === clientDataType.Number"
          [class.cell-string]="column.clientDataType === clientDataType.String"
          [class.cell-icon]="column.clientDataType === clientDataType.Icon"
        >
          <ng-container *ngIf="column.clientDataType === clientDataType.Icon">
            <button (click)="iconClicked.emit(row)">
              <i
                class="ami ami-{{ column?.cell(row) }}"
                [attr.pendoid]="row?.pendoid"
              ></i>
            </button>
          </ng-container>
          <ng-container *ngIf="i === 0 || row?.sensorType === null">
            {{
              row[column?.columnDef] !== undefined && column?.cell(row)
                ? column?.cell(row)
                : "-"
            }}
          </ng-container>
          <ng-container
            *ngIf="
              i !== 0 && row?.sensorType !== null && !column?.ignoreConvert
            "
          >
            {{
              row[column?.columnDef] !== undefined && column?.cell(row)
                ? (column?.cell(row) | sensor : row?.sensorType : true)
                : "-"
            }}
          </ng-container>
          <ng-container *ngIf="i !== 0 && column?.ignoreConvert">
            {{
              row[column?.columnDef] !== undefined && column?.cell(row)
                ? column?.cell(row)
                : "-"
            }}
          </ng-container>
          <ng-container *ngIf="i === 1 && row?.showArrow">
            <i class="ami ami-drop-{{ row?.infoArrow }}"></i>
          </ng-container>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div *ngIf="!loaded" class="table__container">
  <table mat-table [dataSource]="loaderData" class="table__container-wrapper">
    <ng-container matColumnDef="col1">
      <th mat-header-cell *matHeaderCellDef>
        <ion-skeleton-text animated class="skeleton-loader"></ion-skeleton-text>
      </th>
      <td mat-cell *matCellDef="let element">
        <ion-skeleton-text animated class="skeleton-loader"></ion-skeleton-text>
      </td>
    </ng-container>

    <ng-container matColumnDef="col2">
      <th mat-header-cell *matHeaderCellDef>
        <ion-skeleton-text animated class="skeleton-loader"></ion-skeleton-text>
      </th>
      <td mat-cell *matCellDef="let element">
        <ion-skeleton-text animated class="skeleton-loader"></ion-skeleton-text>
      </td>
    </ng-container>

    <ng-container matColumnDef="col3">
      <th mat-header-cell *matHeaderCellDef>
        <ion-skeleton-text animated class="skeleton-loader"></ion-skeleton-text>
      </th>
      <td mat-cell *matCellDef="let element">
        <ion-skeleton-text animated class="skeleton-loader"></ion-skeleton-text>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedLoaderColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedLoaderColumns"></tr>
  </table>
</div>
