import { UnitConversionService } from "@sonar/core/unit-conversion.service";
import { SonarData } from "./sonar-data";
import { SonarDataType } from "@sonar/shared/sensor-type";
import { NumberSymbol } from "@angular/common";


export class AirFlowSensor extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;
  isSensor = true;
  sonarDataType: SonarDataType = SonarDataType.AirFlow;
  
  statistic = 'air-flow';
  iconName = 'air-flow';
  sensorStyleClass = 'ami-air-flow';
  decimalPlaces = '1.0-0';
  maxSensorCount = 4;
  labelKey = 'KPIS.AIR_FLOW';

  getSensorUnit = (unitConversionService: UnitConversionService): string => unitConversionService.getAirFlowUnit();
  getPropertyNames = (prefix?: string, suffix?: string): string[] => this.generatePropertyNames(prefix, 'airFlow', suffix);
  
  convertUnit(value: number, unitConversionService: UnitConversionService): number {
    if (value === 0) return value;
    return unitConversionService.convertAirFlow(value);
  }

}
