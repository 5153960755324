import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'mt-swiper-slide',
  templateUrl: './mt-swiper-slide.component.html',
  styleUrls: ['./mt-swiper-slide.component.scss'],
})
export class MtSwiperSlideComponent {
  @HostBinding('class') class = 'swiper-slide';
}
