import { Injectable, Pipe, PipeTransform, Inject } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { UnitConversionService } from '../unit-conversion.service';
import { SensorType } from '@sonar/shared/sensor-type';

@Pipe({
  name: 'airFlow',
})
@Injectable()
export class AirFlowPipe implements PipeTransform {
  constructor(
    private readonly unitConversionService: UnitConversionService,
    private readonly translateService: TranslateService
  ) {}

  transform(item: number, unit: 'CFM' | 'CMH', showUnits?: boolean) {
    const numberPipe = new DecimalPipe(
      this.translateService.currentLang || this.translateService.defaultLang
    );
    if (unit === 'CFM') {
      const cfmValue = numberPipe.transform(
        this.unitConversionService.convertAirFlow(item),
        SensorType.getDecimalPlaces(SensorType.AirFlow)
      );
      if (showUnits) {
        return `${cfmValue} ${this.unitConversionService.getAirFlowUnit()}`;
      }
      return cfmValue;
    }
    const value = numberPipe.transform(
      item,
      SensorType.getDecimalPlaces(SensorType.AirFlow)
    );
    if (showUnits) {
      return `${value} ${this.unitConversionService.getAirFlowUnit()}`;
    }
    return value;
  }
}
