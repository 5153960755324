/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/naming-convention */
export enum ClientDataType {
  NotSet = 0,
  Boolean = 1,
  String = 2,
  Number = 3,
  Date = 4,
  DateTime = 5,
  Time = 6,
  Enum = 7,
  Icon = 8,
}
