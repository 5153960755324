/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import {
  initialize,
  LDClient,
  LDContext,
  LDFlagSet,
} from 'launchdarkly-js-client-sdk';
import * as _ from 'lodash';
import { Subject } from 'rxjs/internal/Subject';
import { User } from '../shared/user';
import { UserInfo } from './user-settings/user-info';
import { SonarUserRole } from '@sonar/shared/sonar-user-role';

@Injectable()
export class LaunchDarklyService {
  public readonly flagChange$ = new Subject<Object>();
  private ldClient: LDClient;
  private flags: LDFlagSet;

  async bootstrap(env: 'dev' | 'prod' | 'demo', user: UserInfo) {
    this.flags = {};

    const clientSideId =
      env === 'dev' ? '5cc31820de24cc081ce03d05' : '5c4786dfab80a738c5132232';
    const lDUser = this.getLDUser(user);
    this.ldClient = initialize(clientSideId, lDUser);

    this.ldClient.on('change', (flags) => {
      _.keys(flags)
        .filter((flagName) => !_.isUndefined(flags[flagName]))
        .forEach(
          (flagName) => (this.flags[flagName] = flags[flagName].current)
        );
      this.flagChange$.next(this.flags);
    });

    await this.ldClient.waitUntilReady();
    this.flags = this.ldClient.allFlags();
    this.flagChange$.next(this.flags);
  }

  async changeUser(user: UserInfo) {
    const lDUser = this.getLDUser(user);
    this.flags = await this.ldClient.identify(lDUser);
  }

  boolVariation(key: string, defaultValue = false): boolean {
    if (_.isBoolean(this.flags[key])) {
      return this.flags[key];
    }
    return defaultValue;
  }

  private getLDUser(user: UserInfo): LDContext {
    if (user?.userId) {
      return {
        key: `app${user.userId.toString()}`,
        name: user.fullName,
        kind: 'user',
        companyId: user.sonarCompaniesId,
        isAdmin: user.userRole === SonarUserRole.Admin ||
          user.userRole === SonarUserRole.Support,
        integrationOption: user.integrationOption,
      };
    }
    return { key: 'anonymous', anonymous: true };
  }
}
