import { UnitConversionService } from "@sonar/core/unit-conversion.service";
import { SonarData } from "./sonar-data";
import { SonarDataType } from "@sonar/shared/sensor-type";
import { NumberSymbol } from "@angular/common";


export class LightSensor extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;
  isSensor = true;
  sonarDataType: SonarDataType = SonarDataType.Light;
  
  statistic = 'light';
  iconName = 'light-intensity';
  sensorStyleClass = 'ami-light-intensity';
  decimalPlaces = '1.2-2';
  maxSensorCount = 10;
  labelKey = 'KPIS.LIGHT';

  getPropertyNames = (prefix?: string, suffix?: string): string[] => this.generatePropertyNames(prefix, 'light', suffix);
  getSensorUnit = (unitConversionService: UnitConversionService): string => unitConversionService.getLightUnit();
  convertUnit(value: number, unitConversionService: UnitConversionService): number {
    if (value == 0) return value;
    return unitConversionService.convertLight(value);
  }
}
