<ion-header class="navigation">
  <ion-toolbar color="secondary">
    <ion-title> {{ "HOUSE" | translate }} {{ currentItem.house }} </ion-title>
    <ion-buttons slot="end">
      <div end class="navigation__cancel-icon" (click)="goBack()">
        <i class="ami-cancel"></i>
      </div>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ng-container *ngIf="isBrimFarmType">
    <ion-list #list *ngFor="let data of dataSource" id="{{ data.house }}">
      <ion-item>
        <ion-label>{{
          "FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.MORTALITY" | translate
        }}</ion-label>
        <ion-input
          id="{{ data.house }}"
          min="0"
          name="mortality"
          type="number"
          [value]="data.mortality"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-label>{{
          "FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.CULLS" | translate
        }}</ion-label>
        <ion-input
          id="{{ data.house }}"
          min="0"
          name="culls"
          type="number"
          [value]="data.culls"
        ></ion-input>
      </ion-item>
    </ion-list>
  </ng-container>

  <ng-container *ngIf="isBimFarmType">
    <ion-list #list *ngFor="let data of dataSource" id="{{ data.house }}">
      <ion-item>
        <ion-label>{{
          "FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.MORTALITY_FEMALE" | translate
        }}</ion-label>
        <ion-input
          id="{{ data.house }}"
          min="0"
          name="mortalityF"
          type="number"
          [value]="data.mortalityF"
          data-testid="mortalityF"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-label>{{
          "FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.MORTALITY_MALE" | translate
        }}</ion-label>
        <ion-input
          id="{{ data.house }}"
          min="0"
          name="mortalityM"
          type="number"
          [value]="data.mortalityM"
          data-testid="mortalityM"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-label>{{
          "FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.CULLS_FEMALE" | translate
        }}</ion-label>
        <ion-input
          id="{{ data.house }}"
          min="0"
          name="femalesCulled"
          type="number"
          [value]="data.femalesCulled"
          data-testid="femalesCulled"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-label>{{
          "FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.CULLS_MALE" | translate
        }}</ion-label>
        <ion-input
          id="{{ data.house }}"
          min="0"
          name="malesCulled"
          type="number"
          [value]="data.malesCulled"
          data-testid="malesCulled"
        ></ion-input>
      </ion-item>
    </ion-list>
  </ng-container>

  <div class="buttons">
    <ion-button
      color="danger"
      (click)="closeModal()"
      data-testid="finish-button"
      >{{ "FINISH" | translate }}</ion-button
    >
    <div>
      <ion-button
        [disabled]="itemIndex === 0"
        fill="outline"
        (click)="previous()"
        data-testid="previous-button"
        >{{ "PREVIOUS" | translate }}</ion-button
      >
      <ion-button
        [disabled]="itemIndex === lastItemIndex"
        (click)="next()"
        data-testid="next-button"
        >{{ "NEXT" | translate }}</ion-button
      >
    </div>
  </div>
</ion-content>
