import { Injectable, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { UnitConversionService } from '../unit-conversion.service';
import { SensorType } from '@sonar/shared/sensor-type';

@Pipe({
  name: 'light',
})
@Injectable()
export class LightPipe implements PipeTransform, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly unitConversionService: UnitConversionService,
    private readonly translateService: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  transform(item: number, unit: 'lux' | 'fc', showUnits?: boolean) {
    const numberPipe = new DecimalPipe(
      this.translateService.currentLang || this.translateService.defaultLang
    );

    if (unit === 'fc') {
      const imperialValue = numberPipe.transform(
        this.unitConversionService.convertLight(item),
        SensorType.getDecimalPlaces(SensorType.Light)
      );
      if (showUnits) {
        return `${imperialValue} ${this.unitConversionService.getLightUnit()}`;
      }
      return imperialValue;
    }

    const metricValue = numberPipe.transform(
      item,
      SensorType.getDecimalPlaces(SensorType.Light)
    );
    if (showUnits) {
      return `${metricValue} ${this.unitConversionService.getLightUnit()}`;
    }
    return metricValue;
  }
}
