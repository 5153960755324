import { UnitConversionService } from "@sonar/core/unit-conversion.service";
import { SonarData } from "./sonar-data";
import { SonarDataType } from "@sonar/shared/sensor-type";
import { NumberSymbol } from "@angular/common";


export class AnimalWelfareIndexSensor extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;
  isSensor = true;
  sonarDataType: SonarDataType = SonarDataType.AnimalWelfareIndex;
  
  statistic = 'animal-welfare-index';
  iconName = 'animal-welfare-index';
  sensorStyleClass = 'ami-animal-welfare-index';
  decimalPlaces = '1.0-0';
  maxSensorCount = 1;
  labelKey = 'KPIS.ANIMAL_WELFARE_INDEX';

  convertUnit = (value: number, u: UnitConversionService) => value;
  getSensorUnit = (unitConversionService: UnitConversionService): string => unitConversionService.getAnimalWelfareIndexUnit();
  getPropertyNames = (prefix?: string, suffix?: string): string[] => this.generatePropertyNames(prefix, 'animalWelfareIndex', suffix);
}
