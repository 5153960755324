/* eslint-disable @angular-eslint/component-selector */
import { Component, Input } from '@angular/core';

@Component({
  selector: 'mt-card',
  templateUrl: 'mt-card.component.html',
  styleUrls: ['./mt-card.component.scss']
})
export class MtCardComponent {
  @Input() icon: string;
  @Input() subtitle: string;
  @Input() title: string;
}
