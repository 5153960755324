/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers, Storage } from '@ionic/storage';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginModule } from './login/login.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ApiTranslateLoader, AuthInterceptor, CORE_PROVIDERS } from './core';
import { ShellModule } from './shell/shell.module';
import { AppSettingsService } from './core/app-settings.service';
import { LaunchDarklyService } from './core/launch-darkly.service';
import { BootstrapperService } from './core/bootstrapper.service';

import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import localePtPt from '@angular/common/locales/pt-PT';
import localePtExtra from '@angular/common/locales/extra/pt';
import localeDa from '@angular/common/locales/da';
import localeEnAu from '@angular/common/locales/en-AU';
import localeIt from '@angular/common/locales/it';
import localeSv from '@angular/common/locales/sv';
import localeRu from '@angular/common/locales/ru';
import localVi from '@angular/common/locales/vi';
import { registerLocaleData } from '@angular/common';
import { UserSettingsService } from './core/user-settings/user-settings.service';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';

registerLocaleData(localeEn);
registerLocaleData(localeEs);
registerLocaleData(localePt, localePtExtra);
registerLocaleData(localePtPt);
registerLocaleData(localeDa);
registerLocaleData(localeEnAu);
registerLocaleData(localeIt);
registerLocaleData(localeSv);
registerLocaleData(localeRu);
registerLocaleData(localVi);

export function createTranslateLoader(
  httpClient: HttpClient,
  appSettingsService: AppSettingsService,
  userSettingsService: UserSettingsService
) {
  return new ApiTranslateLoader(
    httpClient,
    appSettingsService,
    userSettingsService
  );
}

export function createBootstrapperService(
  appSettingsService: AppSettingsService,
  launchDarklyService: LaunchDarklyService,
  userSettingsService: UserSettingsService
) {
  return new BootstrapperService(
    appSettingsService,
    launchDarklyService,
    userSettingsService
  );
}

export function createAppInitializer(bootstrapperService: BootstrapperService) {
  return () => bootstrapperService.bootstrap();
}

export const sonarAppVersion = '2.0.15';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    IonicModule.forRoot({
      mode: 'md',
      innerHTMLTemplatesEnabled: true,
    }),
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en-US',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, AppSettingsService, UserSettingsService],
      },
    }),
    IonicStorageModule.forRoot({
      driverOrder: [
        Drivers.SecureStorage,
        Drivers.IndexedDB,
        Drivers.LocalStorage,
      ],
    }),
    LoginModule,
    ShellModule,
  ],
  exports: [TranslateModule],
  providers: [
    Storage,
    {
      provide: APP_INITIALIZER,
      useFactory: createAppInitializer,
      deps: [BootstrapperService],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    CORE_PROVIDERS,
    {
      provide: BootstrapperService,
      useFactory: createBootstrapperService,
      deps: [AppSettingsService, LaunchDarklyService, UserSettingsService],
    },
    Deeplinks,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
