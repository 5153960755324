import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe, getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingsService } from '../user-settings/user-settings.service';
import { UnitConversionService } from '../unit-conversion.service';
import { SensorPipe } from './sensor.pipe';
import { PercentDepletionPipe } from './percent-depletion.pipe';
import { SensorType, SonarDataType } from '@sonar/shared/sensor-type';
import { DepletionCountPipe } from './depletion-count.pipe';

@Pipe({
  name: 'sonarData',
})
@Injectable()
export class SonarDataPipe implements PipeTransform {
  constructor(
    private readonly userSettingsService: UserSettingsService,
    private readonly unitConversionService: UnitConversionService,
    private readonly translateService: TranslateService
  ) {}

  transform(
    item: number | number[],
    sonarDataType: SonarDataType | SensorType,
    showUnits?: boolean
  ) {
    if (Array.isArray(item)) {
      const itemsTransformed = [];
      if (item.some((v) => !v)) {
        return `N/A`;
      }
      item.forEach((i) =>
        itemsTransformed.push(this.transform(i, sonarDataType, showUnits))
      );
      return itemsTransformed.join(' / ');
    } else {
      return this.convert(item, sonarDataType, showUnits);
    }
  }

  private convert(item: number, sonarDataType: SonarDataType | SensorType, showUnits?: boolean) {
    if(sonarDataType === undefined) {
      console.error('SonarDataType is undefined!');
      return item;
    }
    
    if(SonarDataType.isSensorType(sonarDataType))
    {
        return new SensorPipe(
          this.userSettingsService,
          this.unitConversionService,
          this.translateService
        ).transform(item, SonarDataType.toSensorType(sonarDataType as any), showUnits);
    }
    else
    {
      return this.convertSonarDataType(item, sonarDataType, showUnits);
    }
  }

  private convertSonarDataType(item: number, sonarDataType: SonarDataType | SensorType, showUnits: boolean) {
    //Check if number has already been formatted
    if (isNaN(item)) {
      return item;
    }

    const separator = getLocaleNumberSymbol(this.translateService.currentLang ?? 'en-US', SonarDataType.getNumberSymbol(sonarDataType as any as SonarDataType));
    if (item.toString().includes(separator)) {
      return item;
    }

    switch (sonarDataType) {
      case SonarDataType.Depletion:
        return new DepletionCountPipe(this.translateService).transform(item, showUnits);
      case SonarDataType.PercentDepletion:
        return new PercentDepletionPipe(
              this.translateService
            ).transform(item, showUnits);
      default:
        return new DecimalPipe(this.translateService.currentLang || this.translateService.defaultLang).transform(
          item,
          SonarDataType.getDecimalPlaces(sonarDataType as SonarDataType)
        );
    }
  }
}

