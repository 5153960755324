import { Component, Input, OnInit } from '@angular/core';
import { KPIFormattedType } from '@sonar/core/kpis/kpi-formatted-type';
import { KPIFormattedService } from '@sonar/core/kpis/kpi-formatted.service';
import { AgePipe } from '@sonar/core/pipes/age.pipe';
import { FarmType } from '@sonar/shared/farm/farm-type';
import { SensorSummary } from '@sonar/shared/sensor-summary';

@Component({
  selector: 'house-card-kpis',
  templateUrl: './house-card-kpis.component.html',
  providers: [KPIFormattedService, AgePipe],
  styleUrls: ['./house-card-kpis.component.scss'],
})
export class HouseCardKpisComponent implements OnInit {
  @Input() sensorSummary: SensorSummary; 

  age: any;
  farmType: FarmType =  FarmType.None;

  livability: number;
  femaleLivability: any;
  maleLivability: any;

  femaleInventoryKpi: string;
  maleInventoryKpi: string;
  birdInventoryKpi: string;

  constructor(
    private readonly kpiFormattedService: KPIFormattedService,
    private readonly agePipe: AgePipe,
  ) { }
  
  ngOnInit(): void {
    this.age = this.sensorSummary.age;
    this.farmType = this.sensorSummary.farmType;
    
    this.initializeInventoryKpis();
    this.initializeLivabilityKpis();
  }

  initializeLivabilityKpis() {
    this.livability = this.sensorSummary.livability;
    this.maleLivability = this.sensorSummary.maleLivability;
    this.femaleLivability = this.sensorSummary.femaleLivability;

    this.femaleLivability = this.kpiFormattedService.createKPI({
      value: this.femaleLivability,
      formatType: KPIFormattedType.Numeric,
      startValueToFormat: 100,
    }).formattedValue;
    
    this.maleLivability = this.kpiFormattedService.createKPI({
      value: this.maleLivability,
      formatType: KPIFormattedType.Numeric,
      startValueToFormat: 100,
    }).formattedValue;
  }

  initializeInventoryKpis() {
    if (FarmType.isBimFarmType(this.farmType))
      this.setSexedInventoryKpis();
    else
      this.setTotalInventoryKpi();
  }

  setSexedInventoryKpis() {
    this.age = this.agePipe.transform(this.sensorSummary.age, this.farmType);
    
    this.femaleInventoryKpi = this.kpiFormattedService.formatDecimal(
      this.sensorSummary.femaleInventory,
      KPIFormattedType.Numeric
    );
    this.maleInventoryKpi = this.kpiFormattedService.formatDecimal(
      this.sensorSummary.maleInventory,
      KPIFormattedType.Numeric
    );
  }

  setTotalInventoryKpi() {
    this.birdInventoryKpi = this.kpiFormattedService.formatDecimal(
      this.sensorSummary.birdInventory,
      KPIFormattedType.Numeric
    );
  }

}

