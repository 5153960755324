import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { IonicAuthOptions } from '@ionic-enterprise/auth';
import {
  androidNativeConfig,
  androidNativeConfigDemo,
  androidNativeConfigProd,
  azureWebConfig,
  azureWebConfigDemo,
  azureWebConfigProd,
  iosNativeConfig,
  iosNativeConfigDemo,
  iosNativeConfigProd,
} from 'src/environments/environment';

import { AppSettingsService } from './app-settings.service';

@Injectable()
export class AuthConfigService {
  constructor(private readonly appSettingsService: AppSettingsService) {}

  getAuthConfig(): IonicAuthOptions {
    const environment = this.appSettingsService.config.env;

    switch (environment) {
      case 'dev':
        return this.getDevAuthConfig();
      case 'demo':
        return this.getDemoAuthConfig();
      default:
        return this.getProdAuthConfig();
    }
  }

  private getDevAuthConfig(): IonicAuthOptions {
    if (Capacitor.getPlatform() === 'ios') {
      return iosNativeConfig;
    } else if (Capacitor.getPlatform() === 'android') {
      return androidNativeConfig;
    }
    return azureWebConfig;
  }

  private getProdAuthConfig(): IonicAuthOptions {
    if (Capacitor.getPlatform() === 'ios') {
      return iosNativeConfigProd;
    } else if (Capacitor.getPlatform() === 'android') {
      return androidNativeConfigProd;
    }
    return azureWebConfigProd;
  }

  private getDemoAuthConfig(): IonicAuthOptions {
    if (Capacitor.getPlatform() === 'ios') {
      return iosNativeConfigDemo;
    } else if (Capacitor.getPlatform() === 'android') {
      return androidNativeConfigDemo;
    }

    const webConfig = azureWebConfigDemo;
    if (window.location.hostname.toLowerCase().includes('sonar.mtech-systems.com')) {
      webConfig.redirectUri = azureWebConfigProd.redirectUri;
      webConfig.logoutUrl = azureWebConfigProd.logoutUrl;
    }

    return webConfig;
  }
}
