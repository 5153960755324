import { UnitConversionService } from "@sonar/core/unit-conversion.service";
import { SonarData } from "./sonar-data";
import { SonarDataType } from "@sonar/shared/sensor-type";
import { NumberSymbol } from "@angular/common";


export class OutsideHumiditySensor extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;
  
  isSensor = true;
  sonarDataType: SonarDataType = SonarDataType.OutsideHumidity;
  
  statistic = 'outside-humidity';
  iconName = 'humidity';
  sensorStyleClass = 'ami-humidity';
  decimalPlaces = '1.0-0';
  maxSensorCount = 1;
  labelKey = 'KPIS.OUTSIDE_HUMIDITY';

  getSensorUnit = (u: UnitConversionService): string => '%';
  convertUnit = (value: number, u: UnitConversionService) => value;
  getPropertyNames = (prefix?: string, suffix?: string): string[] => this.generatePropertyNames(prefix, 'outsideHumidity', suffix);
}
