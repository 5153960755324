<div class="mt-card">
  <div *ngIf="title" class="mt-card__title">
    <div class="mt-card__title-content">
      <div *ngIf="icon" class="mt-card__icon">
        <i class="ami ami-{{icon}}"></i>
      </div>
      {{ title }}
    </div>
    <div *ngIf="subtitle" class="mt-card__subtitle"> {{ subtitle }}</div>
  </div>
  <div class="mt-card__content">
    <ng-content></ng-content>
  </div>
</div>
