<div class="wrapper">
  <div
    class="full-screen__header"
    [class.full-screen__header-landscape]="orientation === 'landscape'"
  >
    <div class="full-screen__header-toolbar">
      <div
        class="toolbar-action"
        (click)="onFullScreenLegendsOpen()"
      >
        <i class="ami ami-list ami-l"></i>
      </div>
    </div>
    <div
      *ngIf="cardChartMetadata?.title"
      class="full-screen__header-title body1"
      data-pendoid="card-chart-full-screen-title"
    >
      <span>
        {{ cardChartMetadata?.title | translate }}
      </span>
      <div
        *ngIf="cardChartMetadata?.subtitle"
        class="full-screen__header-subtitle caption"
        data-pendoid="card-chart-full-screen-subtitle"
      >
        {{ cardChartMetadata?.subtitle | translate }}
      </div>
    </div>
    <div class="full-screen__header-toolbar left">
      <div
        class="toolbar-action"
        data-pendoid="card-chart-full-screen-rotate-button"
        (click)="onRotate()"
      >
        <i class="ami ami-phone-rotate-{{ orientation }} ami-xl"></i>
      </div>
      <div
        class="toolbar-action"
        data-pendoid="card-chart-full-screen-close-button"
        (click)="onClose()"
      >
        <i class="ami ami-cancel ami-xl"></i>
      </div>
    </div>
  </div>
  <div class="full-screen__content">
    <interactive-chart
      *ngIf="showFullScreen"
      [cardChartArgs]="cardChartArgs"
      [cardChartMetadata]="cardChartMetadata"
      [chartSettings]="chartSettings"
      [loaded]="loaded"
      [orientation]="orientation"
      [showFullScreen]="showFullScreen"
      [showFullScreenLegends]="showFullScreenLegends"
      (chartNavigated)="onChartNavigated($event)"
      (fullScreenLegendsClosed)="onFullScreenLegendsClosed($event)"
    ></interactive-chart>
  </div>
</div>
