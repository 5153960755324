import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { SonarDataType } from '@sonar/shared/sensor-type';

@Pipe({
  name: 'percentDepletion',
})
@Injectable()
export class PercentDepletionPipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService
  ) {}

  transform(item: number, showUnits?: boolean) {
    const numberPipe = new DecimalPipe(
      this.translateService.currentLang || this.translateService.defaultLang
    );
    const value = numberPipe.transform(
      item,
      SonarDataType.getDecimalPlaces(SonarDataType.PercentDepletion)
    );
    if (showUnits) {
      return `${value} %`;
    }
    return value;
  }
}
