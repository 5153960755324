import { UnitConversionService } from "@sonar/core/unit-conversion.service";
import { SonarData } from "./sonar-data";
import { SonarDataType } from "@sonar/shared/sensor-type";
import { NumberSymbol } from "@angular/common";


export class HumiditySensor extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;
  isSensor = true;
  sonarDataType: SonarDataType = SonarDataType.Humidity;
  
  statistic = 'humidity';
  iconName = 'humidity';
  sensorStyleClass = 'ami-humidity';
  decimalPlaces = '1.0-0';
  maxSensorCount = 4;
  labelKey = 'KPIS.HUMIDITY';

  getSensorUnit = (u: UnitConversionService): string => '%';
  convertUnit = (value: number, u: UnitConversionService) => value;
  getPropertyNames = (prefix?: string, suffix?: string): string[] => this.generatePropertyNames(prefix, 'humidity', suffix);
}
