import { UnitConversionService } from "@sonar/core/unit-conversion.service";
import { SonarData } from "./sonar-data";
import { SonarDataType } from "@sonar/shared/sensor-type";
import { NumberSymbol } from "@angular/common";


export class MovementSensor extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;
  isSensor = true;
  sonarDataType: SonarDataType = SonarDataType.Movement;
  
  statistic = 'movement';
  iconName = 'movement';
  sensorStyleClass = 'ami-movement';
  decimalPlaces = '1.2-2';
  maxSensorCount = 1;
  labelKey = 'KPIS.MOVEMENT';

  getSensorUnit = (u: UnitConversionService): string => undefined;
  convertUnit = (value: number, u: UnitConversionService) => value;
  getPropertyNames = (prefix?: string, suffix?: string): string[] => this.generatePropertyNames(prefix, 'movement', suffix);
}
