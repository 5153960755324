import { UnitConversionService } from "@sonar/core/unit-conversion.service";
import { SonarData } from "./sonar-data";
import { SonarDataType } from "@sonar/shared/sensor-type";
import { NumberSymbol } from "@angular/common";

export class WaterConsumptionSensor extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;
  sonarDataType: SonarDataType = SonarDataType.WaterConsumption;
  
  isSensor = true;
  statistic = 'water';
  iconName = 'water';
  sensorStyleClass = 'ami-water';
  decimalPlaces = '1.0-0';
  maxSensorCount = 24;
  labelKey = 'KPIS.WATER';

  getPropertyNames = (prefix?: string, suffix?: string): string[] => this.generatePropertyNames(prefix, 'water', suffix);
  getSensorUnit = (unitConversionService: UnitConversionService): string => unitConversionService.getVolumeUnit();
  convertUnit(value: number, unitConversionService: UnitConversionService): number {
    if (value === 0) return value;
    return unitConversionService.convertVolume(value);
  }
}
