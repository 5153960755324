import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ClientDataType } from '../mt-card-table/client-data-type';

export interface ColumnArgsTable2 {
  cell: any;
  clientDataType: ClientDataType;
  columnDef: string;
  header: string;
  ignoreConvert?: boolean;
}

@Component({
  selector: 'mt-card-table2',
  templateUrl: 'mt-card-table2.component.html',
  styleUrls: ['./mt-card-table2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MtCardTable2Component implements OnChanges, OnInit, AfterViewInit {
  @ViewChild(MatSort) private sort: MatSort;

  @Input() columns: ColumnArgsTable2[];
  @Input() dataSource: any;
  @Input() loaded: boolean;
  @Input() title: string;

  @Output() iconClicked = new EventEmitter<any>();

  clientDataType = ClientDataType;
  displayedColumns: string[] = [];
  displayedLoaderColumns: string[] = ['col1', 'col2', 'col3'];
  loaderData = [
    { col1: '', col2: '', col3: '' },
    { col1: '', col2: '', col3: '' },
    { col1: '', col2: '', col3: '' },
    { col1: '', col2: '', col3: '' },
    { col1: '', col2: '', col3: '' },
    { col1: '', col2: '', col3: '' },
  ];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['columns'] && this.columns) {
      this.displayedColumns = this.columns.map((c) => c.columnDef);
    }
  }

  ngOnInit() {
    if (!this.dataSource?.length && !this.displayedColumns) {
      return;
    }
    this.loaded = true;
  }

  ngAfterViewInit() {
    if (!this.dataSource?.length) {
      return;
    }
    this.dataSource = new MatTableDataSource(this.dataSource);
    this.dataSource.sort = this.sort;
  }
}
