/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/naming-convention */

export enum FarmType {
  None = 0,
  BRIM = 1,
  BIM = 2,
  GIM = 3,
  SIM = 4,
  All = 5,
  LIM = 6,
  TIM = 7,
  DIM = 8,
  SpecialtyBird = 9,
}

export namespace FarmType {
  export function isBimFarmType(farmType: FarmType): boolean {
    return farmType === FarmType.BIM || farmType === FarmType.LIM;
  }

  export function isBrimFarmType(farmType: FarmType): boolean {
    return farmType !== FarmType.BIM && farmType !== FarmType.LIM;
  }

  export function isAgeInWeeks(farmType: FarmType): boolean {
    return farmType === FarmType.BIM || farmType === FarmType.LIM || farmType === FarmType.TIM;
  }
}
