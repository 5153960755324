/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Injectable, FactoryProvider, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppSettingsService } from '../core/app-settings.service';
import { DataService } from './data.service';
import { CacheService } from './cache.service';

export function dataServiceFactoryProvider(
  args: { endPoint: string; maxCacheAge?: number } | string,
  token = ''
): FactoryProvider {
  return {
    provide: token || DataService,
    deps: [HttpClient, AppSettingsService, CacheService],
    useFactory(
      httpClient: HttpClient,
      appSettingsService: AppSettingsService,
      cacheService: CacheService
    ) {
      if (typeof args === 'string') {
        return new DataService(
          { endPoint: args, maxCacheAge: 0 },
          httpClient,
          appSettingsService,
          cacheService
        );
      } else {
        return new DataService(
          args,
          httpClient,
          appSettingsService,
          cacheService
        );
      }
    },
  };
}

@Injectable()
export class DataServiceFactory {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly appSettingsService: AppSettingsService,
    private readonly cacheService: CacheService
  ) {}

  create(endPoint: string): DataService;
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  create(args: { endPoint: string; maxCacheAge?: number }): DataService;
  create(args: any): DataService {
    if (typeof args === 'string') {
      return new DataService(
        { endPoint: args, maxCacheAge: 0 },
        this.httpClient,
        this.appSettingsService,
        this.cacheService
      );
    } else {
      return new DataService(
        args,
        this.httpClient,
        this.appSettingsService,
        this.cacheService
      );
    }
  }
}
