/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @angular-eslint/component-selector */
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import * as Highcharts from 'highcharts';
import HC_Stock from 'highcharts/modules/stock';
import { TabsService } from '@sonar/core/tabs.service';
import { CardChartArgs } from '@sonar/shared/cards/mt-card-chart/card-chart-args';
import { CardChartMetadata } from '@sonar/shared/cards/mt-card-chart/card-chart-metadata';
import { ChartData } from '@sonar/shared/sonar-chart/chart-data';
import { ChartNavigatedArgs } from './chart-navigated-args';
import { InteractiveChartComponent } from './interactive-chart.component';
import { ChartSettings } from './chart-settings';

HC_Stock(Highcharts);
@Component({
  selector: 'interactive-full-screen-chart',
  styleUrls: ['./interactive-full-screen-chart.component.scss'],
  templateUrl: './interactive-full-screen-chart.component.html',
})
export class InteractiveFullScreenChartComponent implements OnInit, OnChanges {
  @HostBinding('style.display') displayFullScreen: 'none' | 'block';

  @ViewChild(InteractiveChartComponent)
  interactiveChart: InteractiveChartComponent;

  private isCapacitor = Capacitor.isNativePlatform();

  constructor(private readonly tabsService: TabsService) {}

  @Input() cardChartArgs: CardChartArgs;
  @Input() cardChartMetadata: CardChartMetadata;
  @Input() chartSettings: ChartSettings;
  @Input() loaded = false;
  @Input() showFullScreen: boolean;

  @Output() chartNavigated: EventEmitter<ChartNavigatedArgs>
    = new EventEmitter<ChartNavigatedArgs>();
  @Output() closed: EventEmitter<any>
    = new EventEmitter();
  @Output() opened: EventEmitter<any>
    = new EventEmitter();

  orientation: 'landscape' | 'portrait' = 'portrait';
  showFullScreenLegends = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.showFullScreen) {
      this.displayFullScreen = this.showFullScreen ? 'block' : 'none';
      if (this.isCapacitor) {
        if (this.showFullScreen) {
          this.tabsService.toggleTabs(false);
        } else {
          this.tabsService.toggleTabs(true);
        }
      }
    }
  }

  async ngOnInit() {
    if (this.isCapacitor) {
      await ScreenOrientation.addListener(
        'screenOrientationChange',
        (result) => {
          if (result.type.includes('landscape')) {
            this.orientation = 'landscape';
          } else if (result.type.includes('portrait')) {
            this.orientation = 'portrait';
          }
        }
      );
    }
  }

  onChartNavigated(args: ChartNavigatedArgs) {
    this.chartNavigated.emit(args);
  }

  async updateData(data: ChartData[]) {
    await this.interactiveChart.updateData(data);
  }

  onFullScreenLegendsOpen() {
    this.showFullScreenLegends = true;
  }

  onFullScreenLegendsClosed(args: any) {
    this.showFullScreenLegends = args;
  }

  onRotate() {
    if (!this.isCapacitor) {
      return;
    }
    this.showFullScreenLegends = false;
    this.toggleOrientation();
  }

  toggleOrientation() {
    const newOrientation = this.orientation === 'landscape'
      ? 'portrait'
      : 'landscape';
    ScreenOrientation.unlock().then(() =>
      ScreenOrientation.lock({ orientation: newOrientation })
    );
    this.orientation = newOrientation;
  }

  onClose() {
    this.orientation = 'portrait';
    if (this.isCapacitor) {
      ScreenOrientation.unlock().then(() =>
        ScreenOrientation.lock({ orientation: this.orientation })
      );
    }
    this.showFullScreen = false;
    this.showFullScreenLegends = false;
    this.closed.emit();
  }

  toggleLoading(toggle: boolean) {
    this.interactiveChart.toggleLoading(toggle);
  }
}
