import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kpiStatusClass',
})
@Injectable()
export class KpiStatusClassPipe implements PipeTransform {
  transform(value: number) {
    if (!value) {
      return;
    }
    if (value >= 0 && value < 40) {
      return 'red';
    }
    if (value >= 40 && value < 60) {
      return 'yellow';
    }
    if (value >= 60 && value <= 100) {
      return 'green';
    }
  }
}
