import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { TabsPage } from './tabs/tabs.page';

const routes: Routes = [
  {
    path: '',
    component: TabsPage,
    children: [
      {
        path: 'benchmarking',
        loadChildren: () =>
          import('../benchmarking/benchmarking.module').then(
            (m) => m.BenchmarkingModule
          ),
      },
      {
        path: 'alerts',
        loadChildren: () =>
          import('../alerts/alerts.module').then((m) => m.AlertsModule),
      },
      {
        path: 'farms',
        loadChildren: () =>
          import('../farms/farms.module').then((m) => m.FarmsModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('../settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: '',
        redirectTo: '/benchmarking',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShellRoutingModule {}
