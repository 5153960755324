<ion-header class="navigation">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="goBack()"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ "FILTERS" | translate }}</ion-title>
    <ion-buttons slot="end">
      <div end class="navigation__cancel-icon" (click)="goBack()">
        <i class="ami-cancel"></i>
      </div>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="container">
  <ion-list>
    <div class="filter__content" id="eventType">
      <ion-item
        #item
        id="eventType"
        class="filter__value"
        (click)="toggle('eventType')"
      >
        <span class="filter__value-title caption">{{
          "EVENT_TYPE" | translate
        }}</span>
        <ion-label>{{ eventTypeLabel }}</ion-label>
        <i class="ami-drop-down ami-xxl arrow"></i>
      </ion-item>
      <div class="filter__option">
        <ion-list>
          <ion-item-group>
            <ion-item>
              <ion-label>{{ "ALL" | translate }}</ion-label>
              <ion-checkbox
                slot="start"
                value="all"
                [checked]="eventTypeAll"
                (ionChange)="onFilterOptionSelected($event)"
              ></ion-checkbox>
            </ion-item>
            <ion-item>
              <ion-label>{{ "EVENTS.FEED_DELIVERY" | translate }}</ion-label>
              <ion-checkbox
                slot="start"
                value="feed"
                [checked]="eventTypeFeed"
                (ionChange)="onFilterOptionSelected($event)"
              ></ion-checkbox>
            </ion-item>
            <ion-item>
              <ion-label>{{ "EVENTS.PLACEMENT" | translate }}</ion-label>
              <ion-checkbox
                slot="start"
                value="placement"
                [checked]="eventTypePlacement"
                (ionChange)="onFilterOptionSelected($event)"
              ></ion-checkbox>
            </ion-item>
            <ion-item>
              <ion-label>{{ "EVENTS.PROCESSING" | translate }}</ion-label>
              <ion-checkbox
                slot="start"
                value="pickup"
                [checked]="eventTypePickup"
                (ionChange)="onFilterOptionSelected($event)"
              ></ion-checkbox>
            </ion-item>
          </ion-item-group>
        </ion-list>
      </div>
    </div>

    <div class="filter__content" id="status">
      <ion-item
        #item
        id="status"
        class="filter__value"
        (click)="toggle('status')"
      >
        <span class="filter__value-title caption">{{
          "STATUS" | translate
        }}</span>
        <ion-label>{{ statusLabel }}</ion-label>
        <i class="ami-drop-down ami-xxl arrow"></i>
      </ion-item>
      <div class="filter__option">
        <ion-list>
          <ion-item-group>
            <ion-item>
              <ion-label>{{ "ALL" | translate }}</ion-label>
              <ion-checkbox
                slot="start"
                value="all"
                [checked]="eventStatusAll"
                (ionChange)="onFilterOptionSelected($event)"
              ></ion-checkbox>
            </ion-item>
            <ion-item>
              <ion-label>{{ "STATUS.ACTUAL" | translate }}</ion-label>
              <ion-checkbox
                slot="start"
                value="actual"
                [checked]="eventStatusActual"
                (ionChange)="onFilterOptionSelected($event)"
              ></ion-checkbox>
            </ion-item>
            <ion-item>
              <ion-label>{{ "STATUS.PLANNED" | translate }}</ion-label>
              <ion-checkbox
                slot="start"
                value="planned"
                [checked]="eventStatusPlanned"
                (ionChange)="onFilterOptionSelected($event)"
              ></ion-checkbox>
            </ion-item>
          </ion-item-group>
        </ion-list>
      </div>
    </div>
  </ion-list>

  <div class="buttons">
    <ion-button
      [disabled]="!selectedOptions?.length"
      color="danger"
      (click)="reset()"
    >
      {{ "RESET" | translate }}
    </ion-button>
    <ion-button [disabled]="!hasChanges" (click)="onApplyFilters()">
      {{ "APPLY_FILTERS" | translate }}
    </ion-button>
  </div>
</ion-content>
