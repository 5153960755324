import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { FarmType } from '@sonar/shared/farm/farm-type';

@Pipe({
  name: 'isBrimFarmType',
})
@Injectable()
export class IsBrimFarmTypePipe implements PipeTransform {
  transform(value: FarmType): boolean {
    return FarmType.isBrimFarmType(value);
  }
}
