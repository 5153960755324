
<div class="house-card__kpi">
  <div class="house-card__kpi-age">
    <div
      data-pendoid="house-card-age-label"
      data-testid="house-card-age-label"
      class="house-card__kpi-age-label caption"
    >
      {{ "FARM_DETAIL_PAGE.AGE" | translate }}
    </div>
    <div
      data-pendoid="house-card-age-value"
      data-testid="house-card-age-value"
      class="house-card__kpi-age-value caption"
    >
      {{ age >= 0 ? age : "-" }}
    </div>
  </div>
  <div *ngIf="farmType | isBrimFarmType" class="house-card__kpi-bird">
    <div
      data-pendoid="house-card-bird-inventory-label"
      data-testid="house-card-bird-inventory-label"
      class="house-card__kpi-bird-label caption"
    >
      {{ "FARM_DETAIL_PAGE.BIRD_INVENTORY" | translate }}
    </div>
    <div
      data-pendoid="house-card-bird-inventory-value"
      data-testid="house-card-bird-inventory-value"
      class="house-card__kpi-bird-value caption"
    >
      {{ birdInventoryKpi || "-" }}
    </div>
  </div>
  <div *ngIf="farmType | isBimFarmType" class="house-card__kpi-bird">
    <div
      data-pendoid="house-card-sexed-bird-inventory-label"
      data-testid="house-card-sexed-bird-inventory-label"
      class="house-card__kpi-bird-label caption"
    >
      {{ "FARM_DETAIL_PAGE.BIRD_INVENTORY_SEX" | translate }}
    </div>
    <div
      data-pendoid="house-card-sexed-bird-inventory-value"
      data-testid="house-card-sexed-bird-inventory-value"
      class="house-card__kpi-bird-value caption"
    >
      {{ maleInventoryKpi || "-" }}/{{ femaleInventoryKpi || "-" }}
    </div>
  </div>
  <div
    *ngIf="farmType | isBrimFarmType"
    class="house-card__kpi-livability"
  >
    <div
      data-pendoid="house-card-livability-label"
      data-testid="house-card-livability-label"
      class="house-card__kpi-livability-label caption"
    >
      {{ "FARM_DETAIL_PAGE.LIVABILITY" | translate }}
    </div>
    <div class="house-card__kpi-livability-value caption">
      {{ livability > 0 ? livability : "-"
      }}{{ livability > 0 ? "%" : "" }}
    </div>
  </div>
  <div
    *ngIf="farmType | isBimFarmType"
    class="house-card__kpi-livability"
  >
    <div
      data-pendoid="house-card-sexed-livability-label"
      data-testid="house-card-sexed-livability-label"
      class="house-card__kpi-livability-label caption"
    >
      {{ "FARM_DETAIL_PAGE.LIVABILITY_SEX" | translate }}
    </div>
    <div
      data-pendoid="house-card-sexed-livability-value"
      data-testid="house-card-sexed-livability-value"
      class="house-card__kpi-livability-value caption"
    >
      {{ maleLivability > 0 ? maleLivability : "-"
      }}{{ maleLivability > 0 ? "%" : "" }}/{{
        femaleLivability > 0 ? femaleLivability : "-"
      }}{{ femaleLivability > 0 ? "%" : "" }}
    </div>
  </div>
</div>