import { AppSettingsService } from './app-settings.service';
import { AppVersionService } from './app-version.service';
import { AuthConfigService } from './auth-config.service';
import { AuthService } from './auth.service';
import { CacheService } from './cache.service';
import { DataServiceFactory } from './data-service.factory';
import { AlertFilterService } from './data/alert-filter.service';
import { AlertService } from './data/alert.service';
import { FarmService } from './data/farm.service';
import { HouseDataService } from './data/house-data.service';
import { FontSizeService } from './font-size.service';
import { KPIFormattedService } from './kpis/kpi-formatted.service';
import { LaunchDarklyService } from './launch-darkly.service';
import { LiveUpdateServiceFactory } from './live-update-service.factory';
import { LiveUpdateService } from './live-update.service';
import { MemoryStorageService } from './memory-storage.service';
import { TabsService } from './tabs.service';
import { UnitConversionService } from './unit-conversion.service';
import { UserSettingsService } from './user-settings/user-settings.service';

export * from './api-translate-loader';
export * from './auth.interceptor';
export * from './data.service';
export * from './utils';

export const CORE_PROVIDERS = [
  AppSettingsService,
  MemoryStorageService,
  CacheService,
  DataServiceFactory,
  UserSettingsService,
  FarmService,
  AlertService,
  AlertFilterService,
  UnitConversionService,
  KPIFormattedService,
  LaunchDarklyService,
  HouseDataService,
  FontSizeService,
  AuthConfigService,
  TabsService,
  LiveUpdateService,
  LiveUpdateServiceFactory,
  AppVersionService,
];
