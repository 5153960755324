import { UnitConversionService } from "@sonar/core/unit-conversion.service";
import { SonarData } from "./sonar-data";
import { SonarDataType } from "@sonar/shared/sensor-type";
import { NumberSymbol } from "@angular/common";


export class DailyGasConsumptionSensor extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;
  isSensor = true;
  sonarDataType: SonarDataType = SonarDataType.DailyGasConsumption;
  
  statistic = 'gas-consumption';
  iconName = 'gas-consumption';
  sensorStyleClass = 'ami-gas-consumption';
  decimalPlaces = '1.2-2';
  maxSensorCount = 5;
  labelKey = 'KPIS.GAS_CONSUMPTION';

  getPropertyNames = (prefix?: string, suffix?: string): string[] => this.generatePropertyNames(prefix, 'dailyGasConsumption', suffix);
  getSensorUnit = (unitConversionService: UnitConversionService): string => unitConversionService.getVolumeUnit();
  
  convertUnit(value: number, unitConversionService: UnitConversionService): number {
    if (value == 0) return value;
    return unitConversionService.convertVolume(value);
  }  
}
