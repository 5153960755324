/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class FontSizeService {
  private readonly fontSizeSource = new Subject<string>();

  fontSizeChanged$ = this.fontSizeSource.asObservable();

  changeFontSize(fontSize: string) {
    this.fontSizeSource.next(fontSize);
  }
}
