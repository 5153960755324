import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

import { DataServiceFactory } from './data-service.factory';
import { DataService } from './data.service';

@Injectable()
export class AppVersionService {
  private appVersionDataService: DataService;

  constructor(private readonly dataServiceFactory: DataServiceFactory) {
    this.appVersionDataService = dataServiceFactory.create('AppVersionInfo');
  }

  public async isSupportedAppVersion(): Promise<boolean> {
    if (Capacitor.isNativePlatform()) {
      const appInfo = await App.getInfo();
      const appVersionInfo = await this.appVersionDataService
        .getEntity<any>()
        .toPromise();

      const versionNumber = appInfo.version;
      console.log('app version: ', versionNumber);
      return (
        +versionNumber.split('.')[0] >=
          +appVersionInfo.minimumSupportedVersion.split('.')[0] &&
        +versionNumber.split('.')[1] >=
          +appVersionInfo.minimumSupportedVersion.split('.')[1] &&
        +versionNumber.split('.')[2] >=
          +appVersionInfo.minimumSupportedVersion.split('.')[2]
      );
    }
    return true;
  }
}
