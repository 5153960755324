/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit } from '@angular/core';
import { FlockEvent } from '@sonar/shared/flock-event';
import { FlockEventType } from '@sonar/shared/flock-event-type';
import { SensorType } from '@sonar/shared/sensor-type';

@Component({
  selector: 'event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent implements OnInit {
  isFeedEvent: boolean;
  isUpcomingFeedEvent: boolean;
  isUpcomingPlannedPickupEvent: boolean;
  isPickupEvent: boolean;
  isActualPlacementEvent: boolean;
  eventTypeEnum = FlockEventType;
  weightSensorType = SensorType.Weight;
  @Input() event: FlockEvent | any;
  @Input() showFarm = false;
  @Input() returnUrl: string;

  ngOnInit() {
    this.isFeedEvent = [
      FlockEventType.PlannedFeedDelivery,
      FlockEventType.FeedDelivery,
      FlockEventType.FeedHaulback,
      FlockEventType.FeedReturn,
      FlockEventType.FeedCredit,
      FlockEventType.FeedDebit,
    ].includes(this.event.eventType);

    this.isUpcomingFeedEvent = this.isFeedEvent && this.event.isUpcomingEvent;
    this.isUpcomingPlannedPickupEvent =
      this.event.eventType === FlockEventType.PlannedPickup &&
      this.event.isUpcomingEvent;

    this.isPickupEvent = this.event.eventType === FlockEventType.Processing;
    this.isActualPlacementEvent =
      this.event.eventType === FlockEventType.Placement;
  }
}
