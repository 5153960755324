import { UnitConversionService } from "@sonar/core/unit-conversion.service";
import { SonarDataType } from "@sonar/shared/sensor-type";
import { SonarData } from "./sonar-data";
import { NumberSymbol } from "@angular/common";

export class DepletionSonarData extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;
  
  isSensor: boolean = false;
  sonarDataType: SonarDataType = SonarDataType.Depletion;
  decimalPlaces: string = '1.0-0'
  maxSensorCount: number;
  labelKey: string;
  statistic: string;
  iconName: string;
  sensorStyleClass: string;
  
  getPropertyNames = (prefix?: string, suffix?: string): string[] => [];
  getSensorUnit = (u: UnitConversionService): string => '';
  convertUnit(value: number, unitConversionService: UnitConversionService): number {
    throw new Error("Method not implemented.");
  }
}