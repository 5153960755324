/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { HouseDataType } from '@sonar/core/user-settings/house-sensor-type';
import { SensorType, SonarDataType } from '@sonar/shared/sensor-type';
import { HouseCardSettingsModalComponent } from './settings-modal/house-card-settings-modal.component';
import { SensorSummary } from '@sonar/shared/sensor-summary';
import { HouseDataService } from '../../core/data/house-data.service';
import { HouseDataTypeSorterService } from '@sonar/core/data/house-data-type-sorter.service';
import { SonarHouseStatus } from '@sonar/shared/sonar-house-status';

@Component({
  selector: 'house-card',
  templateUrl: 'house-card.component.html',
  styleUrls: ['./house-card.component.scss'],
})
export class HouseCardComponent implements OnInit, OnChanges {
  @Input() houseNo: string;
  @Input() viewMode: 'slide' | 'grid' = 'slide';
  @Input() age;
  @Input() alertCount: number = 0;
  @Input() houseDetail: SensorSummary;
  @Input() sensorCount: number;
  @Input() showSexedBirdWeights: boolean = false;
  @Input() isSharedBin: boolean = false;
  @Output() sensorTypeClick = new EventEmitter<SensorType>();
  @Output() houseClick = new EventEmitter();

  filteredSensorTypes: HouseDataType[];
  houseSold = false;
  sensor = SonarDataType;
  sensorToDisplay: HouseDataType;
  sensorTypes: HouseDataType[];

  constructor(
    public translateService: TranslateService,
    private readonly modalController: ModalController,
    private readonly houseDataService: HouseDataService,
    private readonly houseDataTypeSorterService: HouseDataTypeSorterService,
  ) {}

  ngOnInit() {
    this.refreshHouseData();
    this.setFilteredSensorType();
    this.setHouseSold();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.sensorTypes) {
      this.refreshHouseData();
    }
    if (changes.houseDetail && this.sensorTypes) {
      this.setFilteredSensorType();
      this.setHouseSold();
    }
  }

  async onCardSettingsClick(event: Event) {
    event.stopPropagation();
    const modal = await this.modalController.create({
      component: HouseCardSettingsModalComponent,
      showBackdrop: true,
      backdropDismiss: false,
      componentProps: {
        sensorData: this.sensorTypes,
        sensorCount: this.sensorCount,
      },
    });
    modal.onDidDismiss().then((data) => {
      this.refreshHouseData();
    });
    await modal.present();
  }

  onFooterClick(event: Event) {
    event.stopPropagation();
  }

  configureSharedFeedBins(sensorTypes: any[], isSharedBin: boolean) {
    const showSharedTag = sensorTypes.length <= 5 && isSharedBin;
    const labelClass =
      sensorTypes.length > 5 && isSharedBin ? 'shared-color' : '';
    const binFeedSensorTypes = sensorTypes.filter(
      (s) => s.sensorType === SensorType.BinFeedAmount,
    );
    binFeedSensorTypes.forEach((s) => {
      s.showSharedTag = showSharedTag;
      s.labelClass = labelClass;
    });
  }

  setHouseSold() {
    this.houseSold = this.houseDetail?.houseStatus === SonarHouseStatus.Sold;
  }

  private setFilteredSensorType() {
    this.filteredSensorTypes =
      this.houseDataTypeSorterService.filterUserHouseDataOrDefault(
        this.sensorTypes,
      );

    this.configureSharedFeedBins(this.filteredSensorTypes, this.isSharedBin);
    this.sensorToDisplay = this.filteredSensorTypes[0];
  }

  private refreshHouseData() {
    this.sensorTypes = this.houseDataService.buildHouseData(this.houseDetail);
  }
}
