import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

import { DataServiceFactory } from '../data-service.factory';
import { DataService } from '../data.service';
import { MaxCacheAge } from '../max-cache-age';
import { AlertFilters } from './alert-filters';
import { AlertSetting } from '@sonar/shared/alerts/alert-setting';
import _ from 'lodash';

@Injectable()
export class AlertFilterService {
  public readonly alertFilters = new BehaviorSubject<AlertFilters>(null);
  alertFilterDataService: DataService;
  alertFilters$ = this.alertFilters.asObservable();

  constructor(private readonly dataServiceFactory: DataServiceFactory) {
    this.alertFilterDataService = this.dataServiceFactory.create({
      endPoint: 'AlertFilters',
      maxCacheAge: MaxCacheAge.Short,
    });
  }

  async saveFilters(alertFilters: AlertFilters) {
    await lastValueFrom(this.alertFilterDataService.saveEntity(alertFilters));
    this.alertFilters.next(alertFilters);
  }

  async refreshFilters() {
    let filters = await this.alertFilterDataService
      .getEntity<AlertFilters>()
      .toPromise();

    if (filters && Object.keys(filters).length === 0) {
      filters = null;
    }

    if (filters) {
      filters.alertTypeFilters = this.getAlertTypeFilters(filters);

      filters.alertTypeFilters = _.orderBy(
        filters.alertTypeFilters,
        ['label'],
        'asc'
      );
    }

    this.alertFilters.next(filters);
  }

  private getAlertTypeFilters(filters: AlertFilters) {
    const allFilters = AlertSetting.getAlertSettings();
    /*
      When new Alert type filter options are added, they need to be included even
      if the user has alert filters stored in user settings.
      We also need to make sure that changes to any AlertSettings are maintained
    */
    if (filters) {
      allFilters.forEach((filter) => {
        const matchingSavedFilter = filters.alertTypeFilters.find(
          (f) => f.alertSettingHeader === filter.alertSettingHeader
        );
        if (matchingSavedFilter) {
          this.setOptionsFromUserSettings(filter, matchingSavedFilter);
        }
      });
    }

    return allFilters;
  }

  private setOptionsFromUserSettings(
    filter: AlertSetting,
    matchingSavedFilter: AlertSetting
  ) {
    /*
      If there are filter options in UserSettings, keep their isSelected property but the rest of the settings
      should come from AlertSetting.getAlertSettings(). This ensures that code changes to AlertSettings are
      maintained without losing the user settings.
   */
    filter.options.forEach((option) => {
      const matchingSavedOption = matchingSavedFilter.options.find(
        (f) => f.alertType === option.alertType
      );
      if (matchingSavedOption) {
        option.isSelected = matchingSavedOption.isSelected;
        option.settingKey = matchingSavedOption.settingKey;
      }
    });
  }
}
