import { ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { IonTabBar } from '@ionic/angular';
import { TabsService } from '@sonar/core/tabs.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-tabs',
  templateUrl: './tabs.page.html',
})
export class TabsPage {
  @ViewChild(IonTabBar, { static: false, read: ElementRef }) private tabsBar: ElementRef;

   constructor(
      private readonly tabsService: TabsService,
      private readonly renderer: Renderer2,
      private readonly changeDetector: ChangeDetectorRef
   ) {}

   async ngOnInit() {
      this.tabsService.toggleTabs$.subscribe(showTabs => this.toggleTabs(showTabs));
   }

   tabsWillChange(event: any) {
      if (!event) {
         return;
      }
      this.changeDetector.detectChanges();
   }

   toggleTabs(show: boolean) {
      if (!this.tabsBar) {
         return;
      }
      this.renderer.setStyle(this.tabsBar.nativeElement, 'display', show ? 'flex' : 'none');
   }
}
