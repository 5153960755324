<ng-container [ngSwitch]="viewMode">
  <ng-container *ngSwitchCase="'slide'">
    <div class="house-card" [class.house-card-sold]="houseSold">
      <div *ngIf="houseSold" class="house-card__banner caption">
        {{ "SOLD" | translate }}
      </div>
      <div class="house-card__header">
        <div class="house-card__header-icon">
          <i class="ami-house"></i>
        </div>
        <div
          data-pendoid="house-card-title"
          data-testid="house-card-title"
          class="house-card__header-title"
        >
          {{ "FARM_DETAIL_PAGE.HOUSE" | translate: { value: houseNo } }}
        </div>
        <div class="house-card__header-arrow">
          <i
            class="ami ami-open-right ami-xl"
            data-pendoid="house-card-title-chevron"
            data-testid="house-card-title-chevron"
          ></i>
        </div>
      </div>
      <house-card-kpis [sensorSummary]="houseDetail"></house-card-kpis>
      <div
        [ngClass]="
          filteredSensorTypes.length <= 5
            ? 'sensors__content-6'
            : 'sensors__content-10'
        "
      >
        <ng-container *ngFor="let sensorType of filteredSensorTypes">
          <div
            attr.data-testid="{{ sensorType.label }}"
            class="sensor__item"
            data-pendoid="house-card-sensor-item"
            id="{{ sensorType.sensorType }}"
          >
            <div class="sensor__item-data">
              <div
                *ngIf="filteredSensorTypes.length <= 5"
                data-pendoid="house-card-sensor-item-icon"
                data-testid="house-card-sensor-item-icon"
                class="sensor__item-icon"
                [class.sensor__item-icon_alert]="sensorType.status"
              >
                <i class="{{ sensorType.sensorStyleClass }}"></i>
              </div>
              <div
                class="sensor__item-kpi"
                [ngClass]="sensorType.kpiClass"
                [class.sensor__item-kpi_alert]="sensorType.status"
              >
                <div class="sensor__item-kpi-value body2">
                  <div
                    *ngIf="sensorType.showSharedTag; else nonSharedContent"
                    class="sensor__item-kpi-label caption"
                    data-pendoid="house-card-sensor-item-label"
                    data-testid="house-card-sensor-item-label"
                    [ngClass]="sensorType.labelClass"
                  >
                    {{ "ALLOCATED_FEED_INVENTORY" | translate }}
                  </div>
                  <ng-template #nonSharedContent>
                    <div
                      class="sensor__item-kpi-label caption"
                      data-pendoid="house-card-sensor-item-label"
                      data-testid="house-card-sensor-item-label"
                      [ngClass]="sensorType.labelClass"
                    >
                      {{ sensorType.label | translate }}
                    </div></ng-template
                  >
                  <span
                    *ngIf="sensorType.showSharedTag"
                    class="caption shared-sensor"
                  >
                    {{ "SHARED_SENSOR" | translate }}
                  </span>
                  <ng-container *ngIf="sensorType.sensorType !== null">
                    <ng-container
                      *ngIf="sensorType.sensorType === sensor.Weight"
                    >
                      <ng-container *ngIf="showSexedBirdWeights">
                        <span
                          data-pendoid="house-card-sensor-item-female-weight"
                          data-testid="house-card-sensor-item-female-weight"
                          class="bold"
                          >F:</span
                        >
                        {{
                          sensorType.value
                            ? (sensorType.value
                              | sonarData: sensorType.sensorType : true)
                            : "- " + sensorType.unit
                        }}
                        <span
                          data-pendoid="house-card-sensor-item-male-weight"
                          data-testid="house-card-sensor-item-male-weight"
                          class="bold"
                          >M:</span
                        >
                        {{
                          sensorType.secondaryValue
                            ? (sensorType.secondaryValue
                              | sonarData: sensorType.sensorType : true)
                            : "- " + sensorType.unit
                        }}
                      </ng-container>
                      <ng-container
                        *ngIf="!showSexedBirdWeights"
                        data-pendoid="house-card-sensor-item-value"
                        data-testid="house-card-sensor-item-value"
                      >
                        {{
                          sensorType.value
                            ? (sensorType.value
                              | sonarData: sensorType.sensorType : true)
                            : "- " + sensorType.unit
                        }}
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        sensorType.sensorType === sensor.ProjectedWeightByFc
                      "
                    >
                      <ng-container
                        data-pendoid="house-card-projected-weight-value"
                        data-testid="house-card-projected-weight-value"
                      >
                        {{
                          sensorType.value
                            ? (sensorType.value
                              | sonarData: sensor.Weight : true)
                            : "-"
                        }}
                        <ng-container *ngIf="sensorType.value">
                          {{ "ON_DAY" | translate }}
                        </ng-container>
                        {{ sensorType.value ? sensorType.secondaryValue : "" }}
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        sensorType.sensorType !== sensor.Weight &&
                        sensorType.sensorType !== sensor.ProjectedWeightByFc
                      "
                    >
                      {{
                        sensorType.value ||
                        sensorType.sensorType === sensor.PercentDepletion
                          ? (sensorType.value
                            | sonarData: sensorType.sensorType : true)
                          : "- " + sensorType.unit
                      }}</ng-container
                    >
                  </ng-container>
                  <ng-container *ngIf="sensorType.sensorType === null">
                    {{ sensorType.value ? sensorType.value : "-" }}
                    {{ sensorType.unit }}
                  </ng-container>
                </div>
              </div>
            </div>
            <div *ngIf="filteredSensorTypes.length <= 5" class="icon">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>
          </div>
        </ng-container>
        <div
          *ngIf="
            filteredSensorTypes.length < 5 || filteredSensorTypes.length === 9
          "
          class="sensor__item center"
        >
          <div class="sensor__item-data">
            <div class="sensor__item-kpi">
              <div class="sensor__item-kpi-value body2">N/A</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer" (click)="onFooterClick($event)">
        <div
          class="footer__warning caption"
          [ngClass]="
            alertCount > 0 ? 'footer__warning_alert' : 'footer__warning_success'
          "
        >
          <ng-container
            data-pendoid="house-card-alerts-notification"
            data-testid="house-card-alerts-notification"
            *ngIf="alertCount > 1"
          >
            {{ "FARM_DETAIL_PAGE.ALERTS" | translate: { value: alertCount } }}
          </ng-container>
          <ng-container
            data-pendoid="house-card-alert-notification"
            data-testid="house-card-alert-notification"
            *ngIf="alertCount === 1"
          >
            {{ "FARM_DETAIL_PAGE.ALERT" | translate: { value: alertCount } }}
          </ng-container>
          <ng-container
            data-pendoid="house-card-no-alerts-notification"
            data-testid="house-card-no-alerts-notification"
            *ngIf="!alertCount"
          >
            {{ "FARM_DETAIL_PAGE.NO_ALERTS" | translate }}
          </ng-container>
        </div>
        <div
          class="footer__options"
          data-pendoid="house-card-gear-options-button"
          data-testid="house-card-gear-options-button"
          (click)="onCardSettingsClick($event)"
        >
          <i class="ami ami-gear ami-l"></i>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'grid'">
    <div
      class="house-card-grid"
      data-pendoid="house-grid-item"
      data-testid="house-grid-item"
      [class.house-card-sold]="houseSold"
      (click)="houseClick.emit()"
    >
      <div *ngIf="houseSold" class="house-card__banner caption">
        {{ "SOLD" | translate }}
      </div>
      <div class="house-card__header">
        <div
          class="house-card__kpi-age caption"
          data-pendoid="house-grid-item-age"
          data-testid="house-grid-item-age"
        >
          {{ "FARM_DETAIL_PAGE.AGE" | translate }} {{ age >= 0 ? age : "-" }}
        </div>
      </div>
      <div class="house-card__body">
        <div class="house-card__body-icon">
          <div class="house-card__body-icon__wrapper">
            <i
              class="ami ami-{{ sensorToDisplay?.iconName }} ami-xl"
              data-pendoid="house-grid-item-icon"
              data-testid="house-grid-item-icon"
            ></i>
          </div>
        </div>
        <div
          class="house-card__body-text"
          data-pendoid="house-grid-item-value"
          data-testid="house-grid-item-value"
        >
          <span>
            {{
              sensorToDisplay?.value
                ? (sensorToDisplay?.value
                  | sonarData: sensorToDisplay?.sensorType : true)
                : "- " + sensorToDisplay?.unit
            }}
          </span>
        </div>
      </div>
      <div class="house-card__subheader">
        <div
          class="house-card__subheader-title body1"
          data-pendoid="house-grid-item-title"
          data-testid="house-grid-item-title"
        >
          {{ "FARM_DETAIL_PAGE.HOUSE" | translate: { value: houseNo } }}
        </div>
        <div
          class="house-card__subheader-warning"
          [ngClass]="alertCount > 0 ? 'alert' : 'success'"
        >
          <span
            class="house-card__subheader-warning-icon caption"
            data-pendoid="house-grid-item-alert-count"
            data-testid="house-grid-item-alert-count"
          >
            {{ alertCount ? alertCount : "0" }}
          </span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
