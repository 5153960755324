<div tappable class="mt-card card-alert" (click)="onCardSelected(alert)">
  <div class="card-alert__header">
    <div data-pendoid="alert-card-title" class="header__title subtitle">
      {{ (title | translate) + (alert?.sensorIndex ? "-" : "") }}
      {{ alert?.sensorIndex }}
      <div data-pendoid="alert-card-subtitle" class="header__subtitle caption" *ngIf="isSensorAlert && !isDepletionAlert">
        {{ "HOUSE" | translate }} {{ alert?.houseNo }}
      </div>
      <div data-pendoid="alert-card-depletion-subtitle" class="header__subtitle" *ngIf="!isSensorAlert && isDepletionAlert">
        {{ "KPIS.DEPLETION" | translate }}
      </div>
    </div>
    <div data-pendoid="alert-card-header-icon" class="header__icon">
      <i class="ami ami-{{ icon }}"></i>
    </div>
  </div>
  <div class="card-alert__content">
    <div class="content__details">
      <div data-pendoid="alert-card-kpi-arrow"  class="content__detail caption">
        <ion-icon *ngIf="
          alert?.minThreshold !== undefined &&
          alert?.minThreshold !== null &&
          alert?.lastValue < alert?.minThreshold
          " name="arrow-down-outline"></ion-icon>
        <ion-icon *ngIf="
          alert?.maxThreshold !== undefined &&
          alert?.maxThreshold !== null &&
          alert?.lastValue > alert?.maxThreshold
          " name="arrow-up-outline"></ion-icon>
        {{
          isSensorAlert
            ? value
              ? (value | sensor: alert?.sensorType:true)
              : "-" + sensorUnit
            : isDepletionAlert
            ? (depletionAlertKey | translate)
            : ""
        }}
      </div>
      <div data-pendoid="alert-card-message"  class="content__detail caption">
        {{ message | translate }}
      </div>
    </div>
    <div class="content__icon" *ngIf="isSensorAlert">
      <i data-pendoid="alert-card-chevron-button"  class="ami ami-chevron-right"></i>
    </div>
  </div>
</div>
