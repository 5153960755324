<div class="mt-card card-chart card-chart_navigator card-chart_stock">
  <div class="card-chart__header" data-pendoid="card-chart-header-title">
    <span>{{ cardChartMetadata?.title | translate }}</span>
    <div
      class="card-chart__content-fullscreen"
      data-pendoid="card-chart-fullscreen-button"
      (click)="onFullScreenClicked()"
    >
      <i class="ami ami-expand ami-s"></i>
    </div>
  </div>
  <div class="card-chart__content">
    <div
      *ngIf="kpis"
      class="card-chart__content-kpis"
      data-pendoid="card-chart-kpis"
    >
      <div *ngFor="let kpi of formattedKpis" class="chart-kpi {{ kpi.color }}">
        <ng-container *ngIf="kpi">
          <div class="chart-kpi-label">{{ kpi.label }}</div>
          <div class="chart-kpi-value">
            <ng-container *ngIf="kpi?.showArrow && kpi?.status">
              <i
                *ngIf="kpi.status === kpiThresholdEnum.Above"
                class="ami ami-slim-up"
              ></i>
              <i
                *ngIf="kpi.status === kpiThresholdEnum.Below"
                class="ami ami-slim-down"
              ></i>
            </ng-container>
            {{ kpi.value }}
          </div>
        </ng-container>
      </div>
    </div>
    <div class="card-chart__content-chart" data-pendoid="card-chart-graph">
      <interactive-chart
        [cardChartArgs]="cardChartArgs"
        [cardChartMetadata]="cardChartMetadata"
        [chartSettings]="chartSettings"
        [enableInteractions]="false"
        [loaded]="loaded"
        [orientation]="'portrait'"
        [showFullScreen]="showFullScreen"
        (chartNavigated)="onChartNavigated($event)"
      ></interactive-chart>
    </div>
  </div>
</div>
