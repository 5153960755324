/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UnitConversionService } from '@sonar/core/unit-conversion.service';
import { FlockEvent } from '@sonar/shared/flock-event';
import { FlockEventType } from '@sonar/shared/flock-event-type';
import { SensorType } from '@sonar/shared/sensor-type';

@Component({
  selector: 'event-modal',
  templateUrl: './event-modal.component.html',
  styleUrls: ['./event-modal.component.scss'],
})
export class EventModalComponent implements OnInit {
  constructor(private readonly modalController: ModalController) {}

  isFeedEvent: boolean;
  isUpcomingFeedEvent: boolean;
  isUpcomingPlannedPickupEvent: boolean;
  isPickupEvent: boolean;
  eventTypeEnum = FlockEventType;
  weightSensorType = SensorType.Weight;
  @Input() event: FlockEvent | any;
  @Input() showFarm = false;

  ngOnInit() {
    this.isFeedEvent = [
      FlockEventType.PlannedFeedDelivery,
      FlockEventType.FeedDelivery,
      FlockEventType.FeedHaulback,
      FlockEventType.FeedReturn,
      FlockEventType.FeedCredit,
      FlockEventType.FeedDebit,
    ].includes(this.event.eventType);

    this.isUpcomingFeedEvent = this.isFeedEvent && this.event.isUpcomingEvent;
    this.isUpcomingPlannedPickupEvent =
      this.event.eventType === FlockEventType.PlannedPickup &&
      this.event.isUpcomingEvent;

    this.isPickupEvent = this.event.eventType === FlockEventType.Processing;
  }

  goBack() {
    this.modalController.dismiss();
  }
}
