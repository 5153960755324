/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @angular-eslint/component-selector */
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { IonCheckbox, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'event-filter-modal',
  templateUrl: './event-filter-modal.component.html',
  styleUrls: ['./event-filter-modal.component.scss'],
})
export class EventFilterModalComponent implements OnInit, AfterViewInit {
  @ViewChildren(IonCheckbox) checkboxes: QueryList<IonCheckbox>;
  @ViewChildren('item', { read: ElementRef }) items: QueryList<ElementRef>;

  constructor(
    private readonly modalController: ModalController,
    private readonly renderer: Renderer2,
    private readonly translateService: TranslateService
  ) {}

  @Input() eventFilters: { filterBy: string; type: string }[] = [];

  get hasChanges(): boolean {
    if (this.eventFilters?.length !== this.selectedOptions?.length) {
      return true;
    }

    return !!(
      this.eventFilters.find(
        (f) =>
          !this.selectedOptions.find(
            (o) => o.filterBy === f.filterBy && o.type === f.type
          )
      ) ||
      this.selectedOptions.find(
        (o) =>
          !this.eventFilters.find(
            (f) => f.filterBy === o.filterBy && f.type === o.type
          )
      )
    );
  }

  selectedOptions: { filterBy: string; type: string }[] = [];
  defaultEventType = { filterBy: 'all', type: 'eventType' };
  defaultStatus = { filterBy: 'all', type: 'status' };
  eventTypeAll: boolean;
  eventTypeFeed: boolean;
  eventTypePlacement: boolean;
  eventTypePickup: boolean;
  eventStatusAll: boolean;
  eventStatusActual: boolean;
  eventStatusPlanned: boolean;
  eventTypeLabel: string;
  statusLabel: string;
  filterLabels: { filterBy: string; label: string }[];

  ngOnInit() {
    this.buildFilterLabels();
    if (this.eventFilters && this.eventFilters.length > 0) {
      this.eventFilters.forEach((e) => {
        if (e.type === 'eventType') {
          switch (e.filterBy) {
            case 'all':
              this.eventTypeAll = true;
              break;
            case 'feed':
              this.eventTypeFeed = true;
              break;
            case 'placement':
              this.eventTypePlacement = true;
              break;
            case 'pickup':
              this.eventTypePickup = true;
              break;
          }
        } else if (e.type === 'status') {
          switch (e.filterBy) {
            case 'all':
              this.eventStatusAll = true;
              break;
            case 'actual':
              this.eventStatusActual = true;
              break;
            case 'planned':
              this.eventStatusPlanned = true;
              break;
          }
        }
      });
    } else {
      this.eventFilters.push(this.defaultEventType, this.defaultStatus);
      this.eventTypeAll = true;
      this.eventStatusAll = true;
    }

    //without map selectedOptions is a pointer that will change eventFilters when it is changed
    this.selectedOptions = this.eventFilters
      .map(f => f);
    this.setLabels();
  }

  buildFilterLabels() {
    this.filterLabels = [
      {
        filterBy: 'all',
        label: this.translateService.instant('ALL'),
      },
      {
        filterBy: 'actual',
        label: this.translateService.instant('STATUS.ACTUAL'),
      },
      {
        filterBy: 'planned',
        label: this.translateService.instant('STATUS.PLANNED'),
      },
      {
        filterBy: 'feed',
        label: this.translateService.instant('EVENTS.FEED_DELIVERY'),
      },
      {
        filterBy: 'placement',
        label: this.translateService.instant('EVENTS.PLACEMENT'),
      },
      {
        filterBy: 'pickup',
        label: this.translateService.instant('EVENTS.PROCESSING'),
      },
    ];
  }

  ngAfterViewInit(): void {
    const hasEventTypeFilter = !!this.eventFilters?.find(
      (f) => f.type === 'eventType'
    );
    const hasEventStatusFilter = !!this.eventFilters?.find(
      (f) => f.type === 'status'
    );

    if (hasEventTypeFilter) {
      this.toggle('eventType');
    }
    if (hasEventStatusFilter) {
      this.toggle('status');
    }
  }

  goBack() {
    this.modalController.dismiss();
  }

  onApplyFilters() {
    this.modalController.dismiss(this.selectedOptions);
  }

  reset() {
    this.checkboxes.forEach((c) => {
      if (!c.checked) {
        return;
      }
      c.checked = false;
    });
    this.selectedOptions = [];
  }

  toggle(type: string) {
    this.items.forEach((item) => {
      if (item.nativeElement.id === type) {
        const optionElements = item.nativeElement.nextElementSibling;
        if (optionElements.classList.contains('visible')) {
          this.renderer.removeClass(optionElements, 'visible');
        } else {
          this.renderer.addClass(optionElements, 'visible');
        }
      }
    });
  }

  onFilterOptionSelected(event: any) {
    const checkboxElement = event?.target;
    const checkboxChecked = event?.detail.checked;
    if (!checkboxElement && !checkboxChecked) {
      return;
    }
    const filterTypeElement = checkboxElement.closest('div.filter__content');
    if (!filterTypeElement) {
      return;
    }

    if (checkboxChecked) {
      this.selectedOptions.push({
        filterBy: checkboxElement.value,
        type: filterTypeElement.id,
      });

      if (checkboxElement.value !== 'all') {
        this.selectedOptions = this.selectedOptions.filter(
          (o) => o.type !== filterTypeElement.id || o.filterBy !== 'all'
        );
      } else {
        this.selectedOptions = this.selectedOptions.filter(
          (o) => o.type !== filterTypeElement.id || o.filterBy === 'all'
        );
      }
    } else if (checkboxChecked === false) {
      this.selectedOptions = this.selectedOptions.filter(
        (o) => o.filterBy !== checkboxElement.value
      );
    }

    this.setLabels();
  }

  setLabels() {
    this.eventTypeLabel = this.getFilterLabel('eventType');
    this.statusLabel = this.getFilterLabel('status');

    const allLabel = this.filterLabels.find((f) => f.filterBy === 'all').label;

    this.eventTypeAll = !!this.selectedOptions.find(e => e.type === 'eventType' && e.filterBy === 'all');
    this.eventStatusAll = !!this.selectedOptions.find(e => e.type === 'status' && e.filterBy === 'all');

    if (this.eventTypeAll) {
      this.eventTypeFeed = false;
      this.eventTypePickup = false;
      this.eventTypePlacement = false;
    }
    if (this.eventStatusAll) {
      this.eventStatusActual = false;
      this.eventStatusPlanned = false;
    }
  }

  getFilterLabel(filterType: string): string {
    const filteredOptions = this.selectedOptions.filter(
      (f) => f.type === filterType
    );
    if (filteredOptions.length === 1) {
      const filterBy = filteredOptions[0].filterBy;
      return this.filterLabels.find((f) => f.filterBy === filterBy).label;
    } else if (filteredOptions.length === 0) {
      return this.filterLabels.find((f) => f.filterBy === 'all').label;
    } else {
      return this.translateService.instant('SOME');
    }
  }
}
