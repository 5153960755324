import { NumberSymbol } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { UnitConversionService } from '@sonar/core/unit-conversion.service';
import { SonarDataType } from '@sonar/shared/sensor-type';
import * as _ from 'lodash';

export abstract class SonarData {
  abstract numberSymbol: NumberSymbol;
  abstract isSensor: boolean;
  abstract sonarDataType: SonarDataType;
  abstract decimalPlaces: string;
  abstract maxSensorCount: number;
  abstract labelKey: string;
  abstract statistic: string;
  abstract iconName: string;
  abstract sensorStyleClass: string;

  static parse = (sensorName: string): SonarDataType =>
    SonarDataType[sensorName] as unknown as SonarDataType;

  static parseStatistic(statistic: string): SonarDataType {
    switch (statistic) {
      case 'temp':
        return SonarDataType.Temperature;
      case 'humidity':
        return SonarDataType.Humidity;
      case 'water':
        return SonarDataType.WaterConsumption;
      case 'feed':
        return SonarDataType.BinFeedAmount;
      case 'weight':
        return SonarDataType.Weight;
      case 'ammonia':
        return SonarDataType.Ammonia;
      case 'air-flow':
        return SonarDataType.AirFlow;
      case 'carbon-dioxide':
        return SonarDataType.CarbonDioxide;
      case 'runtime-consumption':
        return SonarDataType.RuntimeConsumption;
      case 'light':
        return SonarDataType.Light;
      case 'movement':
        return SonarDataType.Movement;
      case 'distress':
        return SonarDataType.Distress;
      case 'gas-consumption':
        return SonarDataType.DailyGasConsumption;
      case 'power-consumption':
        return SonarDataType.DailyPowerConsumption;
      case 'outside-temperature':
        return SonarDataType.OutsideTemperature;
      case 'eggs-produced':
        return SonarDataType.EggsProduced;
      case 'static-pressure':
        return SonarDataType.StaticPressure;
      case 'animal-welfare-index':
        return SonarDataType.AnimalWelfareIndex;
      case 'outside-humidity':
        return SonarDataType.OutsideHumidity;
    }
  }

  getValuesForSonarDataType(
    objectsWithSonarDataProperties: any[],
    sonarDataType: SonarDataType,
    suffixes: string[] = []
  ): number[] {
    const dataValues = [];
    if (!_.isArray(objectsWithSonarDataProperties)) {
      return dataValues;
    }

    const sonarDataValuePropertyNames = this.getPropertyNames();
    for (const suffix of suffixes) {
      sonarDataValuePropertyNames.push(...this.getPropertyNames('', suffix));
    }

    for (const sensorValuePropertyName of sonarDataValuePropertyNames) {
      objectsWithSonarDataProperties.forEach(
        (objectWithSonarDataProperties) => {
          if (
            !_.isNil(objectWithSonarDataProperties[sensorValuePropertyName])
          ) {
            dataValues.push(
              objectWithSonarDataProperties[sensorValuePropertyName]
            );
          }
        }
      );
    }

    return dataValues;
  }

  generatePropertyNames(
    prefix?: string,
    sonarDataName?: string,
    suffix?: string
  ) {
    const maxSensorCount = this.maxSensorCount;

    if (maxSensorCount === 0 || maxSensorCount === 1) {
      return [`${prefix ? prefix : ''}${sonarDataName}${suffix ? suffix : ''}`];
    }

    return Array.from(
      Array(maxSensorCount).keys(),
      (i) =>
        `${prefix ? prefix : ''}${sonarDataName}${i + 1}${suffix ? suffix : ''}`
    );
  }

  getNumericDecimalPlaces = (): number => +this.decimalPlaces.substring(4);
  toString = (): string => SonarDataType[this.sonarDataType];

  toLabelString = async (translateService: TranslateService): Promise<string> =>
    await translateService.get(this.labelKey).toPromise();

  abstract getPropertyNames(prefix?: string, suffix?: string): string[];
  abstract getSensorUnit(unitConversionService: UnitConversionService): string;
  abstract convertUnit(
    value: number,
    unitConversionService: UnitConversionService
  ): number;
}
