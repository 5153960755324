/* eslint-disable @typescript-eslint/dot-notation */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { SonarSpeciesType } from '@sonar/shared/sonar-species-type';
import * as deepmerge from 'deepmerge';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TrackJS } from 'trackjs';

import { AppSettingsService } from './app-settings.service';
import { UserSettingsService } from './user-settings/user-settings.service';

export class ApiTranslateLoader implements TranslateLoader {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly appSettingsService: AppSettingsService,
    private readonly userSettingsService: UserSettingsService
  ) {}

  public getTranslation(lang: string) {
    if (!lang) {
      lang = 'en-US';
    }

    let apiLang;
    switch (lang) {
      case 'pt':
        apiLang = 'pt-BR';
        break;
      case 'pt-pt':
        apiLang = 'pt-PT';
        break;
      default:
        apiLang = lang;
        break;
    }

    // UserSettings is loaded before the default language is set in app.module, so this should be populated
    // as long as the user is logged in.
    const speciesType =
      this.userSettingsService.settings?.value?.sonarSpeciesType ||
      SonarSpeciesType.Chicken;

    const api = this.appSettingsService.config.api;
    return forkJoin([
      this.httpClient
        .get(
          `${api}/Translations?culture=${apiLang}&speciesType=${speciesType}`
        )
        .pipe(
          catchError((err: any) => {
            // When the auth interceptor fails to refresh token, it returns a string instead of an HttpErrorResponse
            if (typeof err !== 'string') {
              TrackJS.track(err);
            }
            return this.httpClient.get(`assets/i18n/${lang}.json`);
          })
        ),
    ]).pipe(
      map((response) =>
        deepmerge.all(response.map((keys) => _.pickBy(keys, (v) => v)))
      )
    );
  }
}
