<div *ngIf="loaded" class="mt-card card-table">
  <div
    *ngIf="title"
    data-pendoid="mt-table-title"
    data-testid="mt-table-title"
    class="mt-card__title"
  >
    {{ title }}
  </div>

  <div *ngIf="kpis" class="card-table__kpis">
    <div
      *ngFor="let kpi of kpis"
      data-pendoid="mt-table-kpi"
      data-testid="mt-table-kpi"
      class="card-table__kpi"
    >
      <ng-container>
        <div
          class="card-table__kpi-label caption"
          data-pendoid="mt-table-kpi-label"
          data-testid="mt-table-kpi-label"
        >
          {{ kpi.label }}
        </div>
        <div
          class="card-table__kpi-value {{ kpi.value | kpiStatusClass }} caption"
          data-pendoid="mt-table-kpi-value"
          data-testid="mt-table-kpi-value"
        >
          {{ kpi.value }}
        </div>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="cardTableColumnArgs">
    <div class="card-table__header">
      <div
        [attr.data-pendoid]="'mt-table-kpi' + column.caption + '-header-cell'"
        [attr.data-testid]="'mt-table-kpi-' + column.caption + '-header-cell'"
        class="card-table__header-cell caption"
        [class.cell-number]="column.clientDataType === ClientDataTypeEnum.Number"
        *ngFor="let column of cardTableColumnArgs"
      >
        {{ column.caption | translate }}
      </div>
    </div>
    <div class="card-table__content">
      <div
        #row
        class="card-table__content-row"
        *ngFor="let data of dataSource"
        data-pendoid="mt-table-row"
        data-testid="mt-table-row"
        (click)="selectRow(row, data)"
      >
        <div
          class="card-table__content-cell caption"
          [class.cell-number]="
            column.clientDataType === ClientDataTypeEnum.Number &&
            !isString(data[column.propertyName])
          "
          [class.cell-string]="isString(data[column.propertyName])"
          *ngFor="let column of cardTableColumnArgs"
        >
          <ng-container *ngIf="column.sensorType === undefined" data-testid="{{ (column.propertyName)}}">
            {{ data[column.propertyName] }}
          </ng-container>
          <ng-container *ngIf="column.sensorType !== undefined" data-testid="{{ (column.propertyName)}}">
            {{ data[column.propertyName] | sensor: column.sensorType:true }}
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="!loaded" class="mt-card card-table">
  <div class="mt-card__title">
    <ion-skeleton-text animated class="skeleton-loader"></ion-skeleton-text>
  </div>

  <ng-container>
    <div class="card-table__header">
      <div
        class="card-table__header-cell caption"
        *ngFor="let column of [].constructor(4)"
      >
        <ion-skeleton-text animated class="skeleton-loader"></ion-skeleton-text>
      </div>
    </div>
    <div class="card-table__content">
      <div
        #row
        *ngFor="let data of [].constructor(4)"
        class="card-table__content-row"
      >
        <div
          *ngFor="let column of [].constructor(4)"
          class="card-table__content-cell caption"
        >
          <ion-skeleton-text
            animated
            class="skeleton-loader"
          ></ion-skeleton-text>
        </div>
      </div>
    </div>
  </ng-container>
</div>
