import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'calendar-event-dialog',
  templateUrl: 'calendar-event-dialog.component.html',
  styleUrls: ['./calendar-event-dialog.component.scss']
})
export class CalendarEventDialogComponent {
  constructor (private readonly dialog: MatDialogRef<CalendarEventDialogComponent>){}

  selected: Date | null;
  time: any = '10:30';

  onSelectedChange(date: Date) {
    this.dialog.close(date);
  }
}
