import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';

import { BehaviorSubject, Observable } from 'rxjs';
import { Farm } from '@sonar/shared/farm/farm';
import { DataServiceFactory } from '../data-service.factory';
import { DataService } from '../data.service';
import { MaxCacheAge } from '../max-cache-age';
import { UserSettingsService } from '../user-settings/user-settings.service';
import { CacheService } from '../cache.service';

@Injectable()
export class FarmService {
  readonly farms = new BehaviorSubject<Farm[]>([]);
  readonly farmDataService: DataService;
  isLoading: boolean;
  farms$: Observable<Farm[]> = this.farms.asObservable();

  constructor(
    private readonly dataServiceFactory: DataServiceFactory,
    private readonly userSettingsService: UserSettingsService,
    private readonly cacheService: CacheService
  ) {
    this.farmDataService = this.dataServiceFactory.create({
      endPoint: 'Farms',
      maxCacheAge: MaxCacheAge.Medium,
    });
  }

  async getFarms(): Promise<Farm[]> {
    let farms = this.farms.getValue();
    if (!farms?.length) {
      await this.refreshFarms();
      farms = this.farms.getValue();
    }

    return farms;
  }

  async refreshFarms() {
    const farms = await this.farmDataService.getEntities<Farm>().toPromise();
    const userSettings = await this.userSettingsService.getUserSettings();
    if (userSettings && userSettings.farmSort) {
      const property = userSettings.farmSort.split('-')[0];
      const order = userSettings.farmSort.split('-')[1];
      farms.sort(this.sortFarms(property, order));
    }
    this.farms.next(farms);
  }

  clear() {
    this.cacheService.removePartial('Farms');
    this.farms.next([]);
  }

  async getFarm(farmId: number): Promise<Farm> {
    let currentFarms = this.farms.getValue();
    if (!currentFarms || !currentFarms.length) {
      await this.refreshFarms();
      currentFarms = this.farms.getValue();
    }
    return currentFarms.find((f) => f.farmId === farmId);
  }

  private sortFarms(property: any, order: any) {
    if (order === 'desc') {
      return (a, b) =>
        a[property] > b[property] ? -1 : a[property] < b[property] ? 1 : 0;
    }
    if (order === 'asc') {
      return (a, b) =>
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    }
  }
}
