<ng-container *ngIf="!showNewDesignLogin">
  <ion-content>
    <div class="login-wrapper">
      <div class="logo"></div>
      <div class="login"></div>
      <!--Can retest and implement when the following errors are resolved: https://github.com/wrobins/cordova-plugin-msal/issues/83, https://github.com/wrobins/cordova-plugin-msal/issues/74 -->
      <!-- <button ion-button color="primary" block (click)="doLogin()">
        <span>{{ 'LOGIN' | translate }}</span>
      </button> -->
      <form>
        <ion-item>
          <ion-label position="stacked">{{ 'EMAIL' | translate }}</ion-label>
          <ion-input
            #email
            autocomplete="on"
            name="email"
            placeholder="example@mtech-systems.com"
            type="email"
            [(ngModel)]="emailInput"
            (ionBlur)="onBlurEmail()"
            (ionFocus)="onFocusEmail()"
            (ionInput)="onInputEmail()"
          ></ion-input>
        </ion-item>
        <ion-button
          color="primary"
          expand="block"
          size="default"
          [disabled]="isLoggingIn || isAuthenticated || !isSupportedAppVersion"
          (click)="updatedLogin()"
        >
          <span *ngIf="!isLoggingIn && !isAuthenticated"
            >{{ 'LOGIN_BUTTON' | translate }}</span
          >
          <ion-spinner *ngIf="isLoggingIn || isAuthenticated"></ion-spinner>
        </ion-button>
        <div class="forgot-password">
          <ion-label
            class="body2"
            data-pendoid="login-forgot-password-link"
            (click)="updatedForgotPassword()"
          >
            {{ 'FORGOT_PASSWORD' | translate }}
          </ion-label>
          <div class="remember">
            <mat-checkbox [(ngModel)]="rememberMe"
              >{{ 'REMEMBER_ME' | translate }}</mat-checkbox
            >
          </div>
        </div>
      </form>
    </div>
  </ion-content>
  <ion-footer data-pendoid="login-footer">
    <div class="copy">© MTech Systems USA, LLC A Munters Company</div>
  </ion-footer>  
</ng-container>

<ng-container *ngIf="showNewDesignLogin">
  <ion-content class="new-login">
    <div class="login__container">
      <div class="background background-slide-{{ slideIndex }}">
        <div class="header">
          <div *ngIf="slideIndex !== 0" class="header__logo"></div>
          <div class="header__main">
            <div class="header__main-text"></div>
          </div>
        </div>
        <div class="credentials">
          <div class="credentials__logo"></div>
          <div class="credentials__header">
            <span class="h3">{{ 'SIGN_IN' | translate }}</span>
            <span class="body1 text-normal">{{ 'SIGN_IN_MESSAGE' | translate }}</span>
          </div>
          <div class="credentials__body">
            <mat-form-field appearance="outline" class="login-input">
              <mat-label>{{ 'EMAIL' | translate }}</mat-label>
              <input
                #email
                matInput
                placeholder="example@mtech-systems.com"
                type="email"
                [(ngModel)]="emailInput"
              />
            </mat-form-field>
            <ion-button
              class="login-button"
              color="primary"
              expand="block"
              [disabled]="isLoggingIn || isAuthenticated || !isSupportedAppVersion"
              (click)="updatedLogin()"
            >
              <span *ngIf="!isLoggingIn && !isAuthenticated">
                {{ 'SIGN_IN' | translate }}
              </span>
              <ion-spinner *ngIf="isLoggingIn || isAuthenticated"></ion-spinner>
            </ion-button>
            <span
              class="caption text-normal login-forgot"
              data-pendoid="login-forgot-password-link"
              (click)="updatedForgotPassword()"
            >
              {{ 'FORGOT_PASSWORD' | translate }}
            </span>
            <div class="login-remember">
              <mat-checkbox [(ngModel)]="rememberMe">
                {{ 'REMEMBER_ME' | translate }}
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="graphic">
          <div class="graphic__img"></div>
        </div>
        <div class="footer">
          <span class="caption">© MTech Systems USA, LLC A Munters Company</span>
        </div>
      </div>
      <div #swiper class="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </ion-content>
</ng-container>
