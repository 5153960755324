/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @angular-eslint/component-selector */
import { DecimalPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WeightPipe } from '@sonar/core/pipes/weight.pipe';
import { UnitConversionService } from '@sonar/core/unit-conversion.service';
import { FlockEvent } from '@sonar/shared/flock-event';
import { FlockEventType } from '@sonar/shared/flock-event-type';
import { SensorType } from '@sonar/shared/sensor-type';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'event-timeline',
  templateUrl: './event-timeline.component.html',
  styleUrls: ['./event-timeline.component.scss'],
  providers: [DecimalPipe, WeightPipe],
})
export class EventTimelineComponent implements OnChanges {
  constructor(
    private readonly translateService: TranslateService,
    private readonly decimalPipe: DecimalPipe,
    public readonly unitConversionService: UnitConversionService,
    private readonly weightPipe: WeightPipe
  ) {}

  @Input() pastEvents: FlockEvent[];
  @Input() upcomingEvents: FlockEvent[];
  @Input() filters: { filterBy: string; type: string }[];
  @Input() loaded: boolean;
  @Input() hasMoreEvents: boolean;

  @Output() eventSelected = new EventEmitter<any>();
  @Output() loadMoreEventsClicked = new EventEmitter();
  eventTypeEnum = FlockEventType;
  eventTypeName: string;
  upcomingEventsCustom: any[];
  pastEventsCustom: any[] = [];
  upcomingEventsByDate: any[];
  pastEventsByDate: any[];
  filteredUpcomingEventsByDate: any[];
  filteredPastEventsByDate: any[];
  eventTypeFilter: ('all' | 'feed' | 'placement' | 'pickup')[] = ['all'];
  eventStatusFilter: ('all' | 'actual' | 'planned')[] = ['all'];

  eventsReady: boolean;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['pastEvents'] && this.pastEvents) {
      this.processPastEvents(this.pastEvents);
    }
    if (changes['upcomingEvents'] && this.upcomingEvents) {
      this.processUpcomingEvents(this.upcomingEvents);
    }
    if (changes['filters'] && this.filters) {
      this.processFilters();
    }

    if (
      changes['pastEvents'] ||
      changes['upcomingEvents'] ||
      changes['filters']
    ) {
      setTimeout(() => this.filterEvents());
    }
  }

  async onSelectEvent(event: FlockEvent) {
    this.eventSelected.emit(event);
  }

  loadMoreEvents() {
    this.loadMoreEventsClicked.emit();
  }

  scrollToDate(date: Date) {
    console.log(date);
    const dateKey = moment(date).format('ddd D MMMM');
    const pastDate = _.sortBy(this.pastEventsByDate, (e) =>
      new Date(e.date).getTime()
    ).find((e) => new Date(e.date).getTime() >= date.getTime());
    const upcomingDate = _.sortBy(this.upcomingEventsByDate, (e) =>
      new Date(e.date).getTime()
    ).find((e) => new Date(e.date).getTime() >= date.getTime());
    let elementId;
    if (pastDate) {
      elementId = pastDate.id;
    } else if (upcomingDate) {
      elementId = upcomingDate.id;
    }

    if (elementId) {
      const element = document.getElementById(elementId);
      element.scrollIntoView();
    }
  }

  private processPastEvents(pastEvents: FlockEvent[]) {
    if (pastEvents.length > 0) {
      this.pastEvents = _.orderBy(
        pastEvents,
        ['timestamp', 'refNo', 'eventType'],
        ['desc', 'asc', 'asc']
      );
      this.pastEventsCustom = [];
    }
    this.pastEvents.forEach((e, index) => {
      this.pastEventsCustom.push({
        ...e,
        id: `past-event-${index}`,
        date: new Date(moment(e.timestamp).toDate().setHours(0, 0, 0, 0)),
        dateLabel: moment(e.formattedTimestamp).format('ddd D MMMM'),
        kpi: this.getEventKpi(e),
        isActualFeedEvent: this.isActualFeedEvent(e.eventType),
        isActualPlacementEvent: e.eventType === FlockEventType.Placement,
      });
    });
    if (!this.pastEventsCustom?.length) {
      return;
    }
    let i = 1;
    this.pastEventsByDate = _.chain(this.pastEventsCustom)
      .groupBy('date')
      .map((value, key) => ({
        id: `past-date-${i++}`,
        date: key,
        dateLabel: moment(key).format('ddd D MMMM'),
        events: value,
        color: 'yellow',
      }))
      .value();
    this.pastEventsByDate = this.pastEventsByDate.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  }

  private processUpcomingEvents(upcomingEvents: FlockEvent[]) {
    if (upcomingEvents.length > 0) {
      this.upcomingEvents = upcomingEvents.sort((a, b) => {
        if (a.timestamp < b.timestamp) {
          return -1;
        } else if (a.timestamp === b.timestamp) {
          if (a.refNo && b.refNo) {
            if (a.refNo < b.refNo) {
              return -1;
            } else if (a.refNo === b.refNo) {
              if (a.eventType < b.eventType) {
                return -1;
              } else if (a.eventType === b.eventType) {
                return 0;
              }
            }
          } else if (!!a.houseNo && !!b.houseNo) {
            if (a.houseNo < b.houseNo) {
              return -1;
            }

            return 0;
          }
        }
        return 1;
      });
      this.upcomingEventsCustom = [];
    }
    this.upcomingEvents.forEach((e, index) => {
      this.upcomingEventsCustom.push({
        ...e,
        id: `past-event-${index}`,
        date: new Date(moment(e.timestamp).toDate().setHours(0, 0, 0, 0)),
        dateLabel: moment(e.timestamp).format('ddd D MMMM'),
        kpi: this.getEventKpi(e),
      });
    });
    if (!this.upcomingEventsCustom?.length) {
      return;
    }
    let i = 1;
    this.upcomingEventsByDate = _.chain(this.upcomingEventsCustom)
      .groupBy('date')
      .map((value, key) => ({
        id: `upcoming-date-${i++}`,
        date: key,
        dateLabel: moment(key).format('ddd D MMMM'),
        events: value,
        color: 'blue',
      }))
      .value();
    this.upcomingEventsByDate = this.upcomingEventsByDate.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  }

  private processFilters() {
    if (this.filters && this.filters.length > 0) {
      const eventTypeFilters = this.filters.filter(
        (f) => f.type === 'eventType'
      );
      const statusFilters = this.filters.filter((f) => f.type === 'status');

      this.eventTypeFilter = eventTypeFilters.map(
        (e) => (e.filterBy as 'all' | 'feed' | 'placement' | 'pickup') ?? 'all'
      );
      this.eventStatusFilter = statusFilters.map(
        (e) => (e.filterBy as 'all' | 'actual' | 'planned') ?? 'all'
      );
      this.eventTypeFilter =
        this.eventTypeFilter.length > 0 ? this.eventTypeFilter : ['all'];
      this.eventStatusFilter =
        this.eventStatusFilter.length > 0 ? this.eventStatusFilter : ['all'];
    } else {
      this.eventTypeFilter = ['all'];
      this.eventStatusFilter = ['all'];
    }
  }

  private filterEvents() {
    this.filteredUpcomingEventsByDate = [];
    this.filteredPastEventsByDate = [];
    if (this.filters && this.filters.length > 0) {
      if (this.upcomingEventsByDate) {
        this.upcomingEventsByDate.forEach((d) => {
          const events = d.events.filter(
            (e) =>
              (this.eventTypeFilter.find((f) => f === 'all') ||
                (this.eventTypeFilter.find((f) => f === 'feed') &&
                  FlockEventType.isFeedEvent(e.eventType)) ||
                (this.eventTypeFilter.find((f) => f === 'pickup') &&
                  FlockEventType.isPickupEvent(e.eventType)) ||
                (this.eventTypeFilter.find((f) => f === 'placement') &&
                  FlockEventType.isPlacementEvent(e.eventType))) &&
              (this.eventStatusFilter.find((f) => f === 'all') ||
                this.eventStatusFilter.find((f) => f === 'planned'))
          );
          if (events && events.length > 0) {
            this.filteredUpcomingEventsByDate.push({
              id: d.id,
              date: d.date,
              dateLabel: d.dateLabel,
              events,
              color: d.color,
            });
          }
        });
      }
      if (this.pastEventsByDate) {
        this.pastEventsByDate.forEach((d) => {
          const events = d.events.filter(
            (e) =>
              (this.eventTypeFilter.find((f) => f === 'all') ||
                (this.eventTypeFilter.find((f) => f === 'feed') &&
                  FlockEventType.isFeedEvent(e.eventType)) ||
                (this.eventTypeFilter.find((f) => f === 'pickup') &&
                  FlockEventType.isPickupEvent(e.eventType)) ||
                (this.eventTypeFilter.find((f) => f === 'placement') &&
                  FlockEventType.isPlacementEvent(e.eventType))) &&
              (this.eventStatusFilter.find((f) => f === 'all') ||
                this.eventStatusFilter.find((f) => f === 'actual'))
          );
          if (events && events.length > 0) {
            this.filteredPastEventsByDate.push({
              id: d.id,
              date: d.date,
              dateLabel: d.dateLabel,
              events,
              color: d.color,
            });
          }
        });
      }
    } else {
      this.filteredUpcomingEventsByDate = this.upcomingEventsByDate;
      this.filteredPastEventsByDate = this.pastEventsByDate;
    }
  }

  private isActualFeedEvent(eventType: FlockEventType): boolean {
    return (
      eventType === FlockEventType.FeedDelivery ||
      eventType === FlockEventType.FeedHaulback ||
      eventType === FlockEventType.FeedReturn ||
      eventType === FlockEventType.FeedCredit ||
      eventType === FlockEventType.FeedDebit
    );
  }

  private getEventKpi(event: FlockEvent) {
    switch (event?.eventType) {
      case FlockEventType.PlannedPlacement:
      case FlockEventType.Placement:
        return `${this.decimalPipe.transform(
          event?.kpi,
          '1.0-0'
        )} ${this.translateService.instant('BIRDS')}`;
      case FlockEventType.PlannedFeedDelivery:
      case FlockEventType.FeedDelivery:
      case FlockEventType.FeedCredit:
      case FlockEventType.FeedDebit:
      case FlockEventType.FeedHaulback:
      case FlockEventType.FeedReturn:
        return `${this.decimalPipe.transform(
          this.unitConversionService.convertWeight(event?.kpi),
          '1.0-0'
        )} ${this.unitConversionService.getFeedBinAmountUnit()}`;
      case FlockEventType.Processing:
      case FlockEventType.PlannedPickup:
        return `${this.weightPipe.transform(
          event?.kpi,
          SensorType.Weight,
          '1.2-2'
        )} ${this.unitConversionService.getFeedBinAmountUnit()}`;
    }
  }
}
