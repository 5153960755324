import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { MeasurementSystem } from '../shared/measurement-systems';
import { UserSettingsService } from './user-settings/user-settings.service';

@Injectable()
export class UnitConversionService {
  private measurementSystem: MeasurementSystem;

  constructor(private readonly userSettingsService: UserSettingsService) {
    this.userSettingsService.$settings
      .pipe(filter((settings) => !!settings))
      .subscribe((settings) => {
        this.measurementSystem = settings.measurementSystem;
      });
  }

  convertPressure(pressure: number): number {
    if (
      this.measurementSystem === MeasurementSystem.Imperial &&
      pressure !== null
    ) {
      return pressure / 248.84;
    }

    return pressure;
  }

  convertTemp(celsiusTemp: number): number {
    if (
      this.measurementSystem === MeasurementSystem.Imperial &&
      celsiusTemp !== null
    ) {
      return celsiusTemp * 1.8 + 32;
    }
    return celsiusTemp;
  }

  convertAirFlow(cmh: number): number {
    if (this.measurementSystem === MeasurementSystem.Imperial && cmh !== null) {
      return cmh * 0.58858;
    }
    return cmh;
  }

  convertWeight(kgWeight: number): number {
    if (
      this.measurementSystem === MeasurementSystem.Imperial &&
      kgWeight !== null
    ) {
      return kgWeight * 2.20462;
    }
    return kgWeight;
  }

  convertVolume(literVolume: number): number {
    if (
      this.measurementSystem === MeasurementSystem.Imperial &&
      literVolume !== null
    ) {
      return literVolume * 0.264172;
    }
    return literVolume;
  }

  convertLight(lux: number): number {
    if (this.measurementSystem === MeasurementSystem.Imperial && lux !== null) {
      return lux / 10.764;
    }
    return lux;
  }

  getTemperatureUnit(): '°F' | '°C' {
    if (this.measurementSystem === MeasurementSystem.Imperial) {
      return '°F';
    }
    return '°C';
  }

  getFeedBinAmountUnit(): 'kg' | 'lbs' {
    if (this.measurementSystem === MeasurementSystem.Imperial) {
      return 'lbs';
    }
    return 'kg';
  }

  getAmmoniaUnit(): 'ppm' {
    return 'ppm';
  }

  getBirdWeightUnit(): 'kg' | 'lbs' {
    if (this.measurementSystem === MeasurementSystem.Imperial) {
      return 'lbs';
    }
    return 'kg';
  }

  getVolumeUnit(): 'L' | 'gal' {
    if (this.measurementSystem === MeasurementSystem.Imperial) {
      return 'gal';
    }
    return 'L';
  }

  getAirFlowUnit(): 'CFM' | 'CMH' {
    if (this.measurementSystem === MeasurementSystem.Imperial) {
      return 'CFM';
    }
    return 'CMH';
  }

  getCarbonDioxideUnit(): 'ppm' {
    return 'ppm';
  }

  getLightUnit(): 'lux' | 'fc' {
    if (this.measurementSystem === MeasurementSystem.Imperial) {
      return 'fc';
    }
    return 'lux';
  }

  getPowerUnit(): 'kW' {
    return 'kW';
  }

  getEggUnit() {
    return '';
  }

  getStaticPressureUnit() {
    if (this.measurementSystem === MeasurementSystem.Imperial) {
      return 'in wc';
    }

    return 'Pa';
  }

  getAnimalWelfareIndexUnit() {
    return '';
  }
}
