import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { TrackJS } from 'trackjs';

import { AppModule } from './app/app.module';

enableProdMode();

TrackJS.install({
  token: '4b3ce54649cd4c59898b670d853eeda7',
  application: 'sonar',
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
