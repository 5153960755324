/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/naming-convention */
export enum HimTransCode {
  NotSet = 0,
  Internal = 1,
  External = 2,
  Credit = 3,
  Debit = 4,
  DOA = 5,
  EntityChange = 6,
  InventoryChange = 7,
  Return = 8,
}

export namespace HimTransCode {
  export function getHimTransCodeLabel(transCode: HimTransCode) {
    switch (transCode) {
      case HimTransCode.Internal:
        return 'INTERNAL';
      case HimTransCode.External:
        return 'EXTERNAL';
      case HimTransCode.Credit:
        return 'CREDIT';
      case HimTransCode.Debit:
        return 'DEBIT';
      case HimTransCode.DOA:
        return 'DOA';
      case HimTransCode.EntityChange:
        return 'ENTITY_CHANGE';
      case HimTransCode.InventoryChange:
        return 'INVENTORY_CHANGE';
      case HimTransCode.Return:
        return 'RETURN';
      default:
        return '';
    }
  }
}
