/* eslint-disable no-var */
import { Injectable, NgZone } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import {
  BrowserVault,
  DeviceSecurityType,
  IdentityVaultConfig,
  Vault,
  VaultError,
  VaultType,
} from '@ionic-enterprise/identity-vault';
import { BehaviorSubject } from 'rxjs';
import { TrackJS } from 'trackjs';

import { VaultFactoryService } from './vault-factory.service';

export type UnlockMode = 'Device' | 'SessionPIN' | 'NeverLock' | 'ForceLogin';

@Injectable({
  providedIn: 'root',
})
export class VaultService {
  vault: BrowserVault | Vault;

  private canUnlockSubject: BehaviorSubject<boolean>;
  get canUnlock$() {
    return this.canUnlockSubject.asObservable();
  }

  constructor(
    private readonly vaultFactory: VaultFactoryService,
    private readonly zone: NgZone
  ) {
    this.init();
  }

  async init() {
    this.canUnlockSubject = new BehaviorSubject(false);

    let vaultKey = 'sonar';
    if (Capacitor.isNativePlatform()) {
      const appInfo = await App.getInfo();
      vaultKey = appInfo.id;
    }
    const config: IdentityVaultConfig = {
      key: vaultKey,
      type: VaultType.SecureStorage,
      lockAfterBackgrounded: 0,
      shouldClearVaultAfterTooManyFailedAttempts: true,
      customPasscodeInvalidUnlockAttempts: 2,
      unlockVaultOnLoad: false,
    };
    this.vault = this.vaultFactory.create(config);
    this.vault.onLock(() => {
      this.zone.run(() => {
        this.canUnlockSubject.next(true);
      });
    });

    this.vault.onUnlock(() =>
      this.zone.run(() => this.canUnlockSubject.next(false))
    );

    this.vault.onError((err: VaultError) => {
      console.log(err);
      TrackJS.track(err.message);
    });

    this.canUnlock().then((x) => this.canUnlockSubject.next(x));
  }

  setUnlockMode(_: UnlockMode): Promise<void> {
    return this.vault.updateConfig({
      ...this.vault.config,
      type: VaultType.SecureStorage,
      deviceSecurityType: DeviceSecurityType.Both,
    });
  }

  private async canUnlock(): Promise<boolean> {
    if ((await this.vault.doesVaultExist()) && (await this.vault.isLocked())) {
      return true;
    }
    return false;
  }
}
