/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/naming-convention */
export enum SonarUserRole {
  Scheduler = 1,
  Installer = 2,
  TA = 3,
  Grower = 4,
  Admin = 5,
  Support = 6,
  IndependentGrower = 8,
  CustomerAdmin = 9,
  ExternalSupport = 10
}

export namespace SonarUserRole {
  export function toLabelKey(sonarUserRole: SonarUserRole) {
    switch (sonarUserRole) {
      case SonarUserRole.TA:
        return 'SONAR_USER_ROLE.TA';
      case SonarUserRole.Grower:
        return 'SONAR_USER_ROLE.GROWER';
      case SonarUserRole.Admin:
        return 'ADMIN';
      case SonarUserRole.Support:
        return 'SONAR_USER_ROLE.SUPPORT';
      case SonarUserRole.IndependentGrower:
        return 'SONAR_USER_ROLE.INDEPENDENT_GROWER';
      case SonarUserRole.CustomerAdmin:
        return 'SONAR_USER_ROLE.CUSTOMER_ADMIN';
    }
  }
}
