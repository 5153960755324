/* eslint-disable @angular-eslint/component-selector */
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Renderer2,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';

import * as _ from 'lodash';
import * as moment from 'moment';
import { Alert } from './alert';

@Component({
  selector: 'alert-timeline',
  templateUrl: './alert-timeline.component.html',
  styleUrls: ['./alert-timeline.component.scss']
})
export class AlertTimelineComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('timelineScroll') timelineScroll: ElementRef;
  @Input() dataSource: Alert[];
  @Input() type: 'date' | 'time' = 'time';
  @Input() loaded: boolean;

  @Output() alertSelected: EventEmitter<Alert> = new EventEmitter();
  @Output() scrolled = new EventEmitter<number>();

  days: { label: string; date: Date; hours: Alert[] }[] = [];
  initialized = false;
  listener: any;

  constructor(private readonly renderer: Renderer2) {}

  ngOnInit() {
    this.initialized = true;
    this.createAlertTimeline();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataSource && this.initialized) {
      this.createAlertTimeline();
    }
  }

  ngOnDestroy() {
    if (this.listener) {
      this.listener = null;
    }
  }

  createAlertTimeline() {
    if (!this.dataSource) {
      return;
    }
    this.days = this.getLegends(this.dataSource);
    this.days.forEach((d) => {
      d.hours.forEach(
        (h) => (h.formattedValue = this.getLegendFormatted(h.start))
      );
      d.hours = _.orderBy(d.hours, ['formattedValue'], ['asc']);
    });
    this.days = _.orderBy(this.days, ['date'], ['desc']);
    if (!this.timelineScroll) {
      return;
    }
    this.listener = this.renderer.listen(
      this.timelineScroll.nativeElement,
      'scroll',
      _.throttle(this.onTimelineScroll, 300).bind(this)
    );
  }

  getLegendFormatted(legend) {
    if (this.type !== 'time') {
      return legend;
    }
    if (legend) {
      return moment(legend).format('hh:mm A');
    }
    return moment().format('hh:mm A');
  }

  getLegends(alerts: Alert[]): { label: string; date: Date; hours: Alert[] }[] {
    const days = [];
    const today = new Date().getDate();
    const yesterday = new Date().getDate() - 1;
    alerts.forEach((f) => {
      const date = f.end
        ? f.end instanceof Date
          ? f.end
          : new Date(f.end)
        : new Date(f.start);
      let day;
      if (date.getDate() === today) {
        day = {
          label: 'TODAY',
          date,
          hours: this.filterAlerts(alerts, date),
        };
      }
      if (date && date.getDate() === yesterday) {
        day = {
          label: 'YESTERDAY',
          date,
          hours: this.filterAlerts(alerts, date),
        };
      }
      if (date.getDate() !== today && date.getDate() !== yesterday) {
        day = {
          label: moment(date).format('YYYY-MM-DD'),
          date,
          hours: this.filterAlerts(alerts, date),
        };
      }
      if (!days.some((d) => d.date.getDate() === day.date.getDate())) {
        days.push(day);
      }
    });
    return days;
  }

  filterAlerts(dataSource: Alert[], day: Date) {
    if (!dataSource.length) {
      return [];
    }
    return dataSource.filter(
      (f) =>
        day.getDate() ===
        (f.end ? new Date(f.end) : new Date(f.start)).getDate()
    );
  }

  onAlertSelected(args: Alert) {
    this.alertSelected.emit(args);
  }

  onTimelineScroll() {
    const scrollTop = this.timelineScroll.nativeElement.scrollTop;
    this.scrolled.emit(scrollTop);
  }
}
