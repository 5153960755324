<ion-header class="navigation">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="goBack()"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ "EVENT_DETAILS" | translate }}</ion-title>
    <ion-buttons slot="end">
      <div class="subtitle body2">
        {{ event?.formattedTimestamp | date : "longDate" }}
      </div>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="container">
  <event-details [event]="event"></event-details>
</ion-content>
