/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-var */
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import * as _ from 'lodash';
import { defer, NEVER, Observable, of, race, throwError } from 'rxjs';
import { catchError, concatMap, filter, retryWhen, tap } from 'rxjs/operators';
import { TrackJS } from 'trackjs';

import { AppSettingsService } from './app-settings.service';
import { CacheService } from './cache.service';

// @Injectable()
export class DataService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private readonly args: { endPoint: string; maxCacheAge?: number },
    private readonly httpClient: HttpClient,
    private readonly appSettingsService: AppSettingsService,
    private readonly cacheService: CacheService
  ) {}

  getEntities<T>(parameters?: any, route: string = null): Observable<T[]> {
    const options = this.getReqOptions(parameters);

    const cachedValue = this.cacheService.get<T[]>(
      this.getCacheKey({ urlSearchParams: options.params, route })
    );
    let cachedValue$: Observable<T[]>;
    if (_.isNil(cachedValue) || (_.isArray(cachedValue) && cachedValue.length === 0) || this.args.maxCacheAge === 0) {
      cachedValue$ = NEVER;
    } else {
      cachedValue$ = of(cachedValue);
    }

    const apiValue = this.httpClient.get<T[]>(this.getUrl(route), options).pipe(
      retryWhen((errors) => this.handleRetries(errors)),
      catchError((err) => of<T[]>([])),
      tap((data) => {
        if (!this.args.maxCacheAge) {
          return;
        }
        this.cacheService.set(
          this.getCacheKey({ urlSearchParams: options.params, route }),
          data ?? {},
          { maxAge: this.args.maxCacheAge, tag: this.args.endPoint }
        );
      })
    );

    return defer(() => race(cachedValue$, apiValue));
  }

  getEntity<T>(
    parameters?: any,
    route: string = null,
    throwExceptions = false
  ): Observable<T> {
    const options = this.getReqOptions(parameters);

    const cachedValue = defer(() => {
      if (!this.args.maxCacheAge) {
        return NEVER;
      }
      const value = this.cacheService.get<T>(
        this.getCacheKey({ urlSearchParams: options.params, route })
      );
      if (value) {
        return of(value);
      }
      return NEVER;
    });
    const apiValue = this.httpClient.get<T>(this.getUrl(route), options).pipe(
      retryWhen((errors) => this.handleRetries(errors)),
      catchError((err) => (throwExceptions ? this.handleError(err) : of(null))),
      tap((data) => {
        if (!this.args.maxCacheAge) {
          return;
        }
        this.cacheService.set(
          this.getCacheKey({ urlSearchParams: options.params, route }),
          data ?? {},
          { maxAge: this.args.maxCacheAge, tag: this.args.endPoint }
        );
      })
    );

    return defer(() => race(cachedValue, apiValue));
  }

  getValue<T>(route: string, parameters?: any): Observable<T> {
    let apiValue: Observable<T>;

    var urlSearchParams = new HttpParams();
    for (const key in parameters) {
      if (parameters[key] instanceof Array) {
        parameters[key].forEach(
          (p: string) => (urlSearchParams = urlSearchParams.append(key, p))
        );
      } else {
        urlSearchParams = urlSearchParams.set(key, parameters[key]);
      }
    }

    this.cacheService.cacheItemInvalidated$
      .pipe(filter((c) => !!c))
      .subscribe(
        (__) => (apiValue = this.getValueHttp(route, urlSearchParams))
      );

    apiValue = this.getValueHttp(route, urlSearchParams);
    const cachedValue = defer(() => {
      if (this.args.maxCacheAge) {
        const value = this.cacheService.get<T>(
          this.getCacheKey({ route, urlSearchParams })
        );
        if (value) {
          return of(value);
        }
      }
      return apiValue;
    });
    return defer(() => race(cachedValue, apiValue));
  }

  saveEntity(entity: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.httpClient
      .post(this.getUrl(), JSON.stringify(entity), { headers })
      .pipe(
        retryWhen((errors) => this.handleRetries(errors)),
        catchError((err) => this.handleError(err, entity)),
        tap(() => {
          if (entity.id) {
            this.cacheService.remove(this.getCacheKey({ id: entity.id }));
          }
          this.cacheService.removeTag(this.args.endPoint);
        })
      );
  }

  saveValue<T>(route: string, value: any): Observable<T> {
    return this.httpClient
      .post<T>(this.getUrl() + '/' + route, JSON.stringify(value), {
        headers: this.headers,
      })
      .pipe(
        retryWhen((errors) => this.handleRetries(errors)),
        catchError((err) => this.handleError(err, value)),
        tap(() => this.cacheService.removeTag(this.args.endPoint))
      );
  }

  async deleteEntity(id: number) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    this.httpClient.delete(this.getUrl() + '/' + id, { headers }).subscribe(
      (res) => {
        // console.log(res);
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          TrackJS.track(err.error);
        }
        return of(null);
      }
    );
  }

  private extractData(res: Response) {
    if (!res || res.status === 204) {
      return null;
    }
    return res.json();
  }

  private getValueHttp<T>(
    route: string,
    urlSearchParams: HttpParams
  ): Observable<T> {
    const url = this.getUrl(route);
    return this.httpClient.get<T>(url, { params: urlSearchParams }).pipe(
      retryWhen((errors) => this.handleRetries(errors)),
      tap((data) => {
        if (this.args.maxCacheAge) {
          this.cacheService.set(
            this.getCacheKey({
              route,
              urlSearchParams,
            }),
            data,
            { maxAge: this.args.maxCacheAge, tag: this.args.endPoint }
          );
        }
      }),
      catchError((err) => of(null))
    );
  }

  private getUrl(route: string = null) {
    if (!route) {
      return `${this.appSettingsService.config.api}/${this.args.endPoint}`;
    }

    return `${this.appSettingsService.config.api}/${this.args.endPoint}/${route}`;
  }

  private getReqOptions(params: any) {
    const reqOpts = {
      params: new HttpParams(),
    };
    for (const k in params) {
      if (Array.isArray(params[k])) {
        for (const i in params[k]) {
          if (params[k][i]) {
            reqOpts.params = reqOpts.params.append(k, params[k][i]);
          }
        }
      } else {
        reqOpts.params = reqOpts.params.append(k, params[k]);
      }
    }
    return reqOpts;
  }

  private handleError(error: Response | any, data?: any | string) {
    if (error instanceof Response) {
      return throwError({
        message: error.text(),
      });
    }
    const body = error.json ? error.json() : error;
    if (body.message) {
      body.data = data;
      return throwError(body);
    } else {
      return throwError(body.error);
    }
  }

  private handleRetries(errors: Observable<any>): Observable<any> {
    return errors.pipe(
      concatMap((error, count) => {
        if (count <= 3 && (error.status === 503 || error.status === 504)) {
          return of(error.status);
        }

        return throwError(error);
      })
    );
  }

  private getCacheKey(keyArgs: {
    id?: string;
    urlSearchParams?: HttpParams;
    route?: string;
  }) {
    return (
      this.args.endPoint +
      (keyArgs.route || '') +
      (keyArgs.id || '') +
      (keyArgs.urlSearchParams ? keyArgs.urlSearchParams.toString() : '')
    );
  }
}
