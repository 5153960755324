/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Deploy } from 'cordova-plugin-ionic';
import { BehaviorSubject } from 'rxjs';
import { AppSettingsService } from './app-settings.service';

@Injectable()
export class LiveUpdateService {
  private readonly updateAvailable: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  protected constructor(
    private readonly alertController: AlertController,
    private readonly translateService: TranslateService,
    private readonly settingsService: AppSettingsService
  ) {}

  updateAvailable$ = this.updateAvailable.asObservable();
  updateAlertTranslations: string[];
  channel: 'Master' | 'Production';

  protected async init() {
    const environment = this.settingsService.config.env;
    this.channel = environment === 'dev' ? 'Master' : 'Production';
    await Deploy.configure({
      updateMethod: 'none',
      channel: this.channel,
    });

    this.updateAlertTranslations = await this.translateService
      .get(['UPDATE_ALERT_HEADER', 'UPDATE_ALERT_MESSAGE', 'YES', 'NO'])
      .toPromise();
    this.updateAvailable.subscribe((updateAvailable) => {
      if (updateAvailable) {
        this.promptUserToInstall();
      }
    });
  }

  async checkForUpdates() {
    const update = await Deploy.checkForUpdate();
    if (update.available) {
      await Deploy.downloadUpdate();
      await Deploy.extractUpdate();
    }
    this.updateAvailable.next(update.available);
  }

  promptUserToInstall() {
    this.alertController
      .create({
        header: this.updateAlertTranslations['UPDATE_ALERT_HEADER'],
        message: this.updateAlertTranslations['UPDATE_ALERT_MESSAGE'],
        buttons: [
          {
            text: this.updateAlertTranslations['NO'],
            role: 'cancel',
            handler: async () => {
              await Deploy.configure({
                updateMethod: 'background',
                channel: this.channel,
              });
            },
          },
          {
            text: this.updateAlertTranslations['YES'],
            handler: async () => {
              await Deploy.reloadApp();
            },
          },
        ],
      })
      .then((obj) => obj.present());
  }
}
