/* eslint-disable id-blacklist */
import { Injectable } from '@angular/core';
import { getLocaleCurrencySymbol } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';

import { KPIFormattedType } from './kpi-formatted-type';
import { KPIDisplay } from './kpi-display';

@Injectable()
export class KPIFormattedService {
  private readonly supportedLangs: string[] = ['en-US', 'pt', 'es'];

  constructor(private readonly translateService: TranslateService) {}

  createKPI(args: {
    label?: string;
    value: any;
    formatType: KPIFormattedType;
    startValueToFormat?: number;
  }): KPIDisplay {
    if (!args.startValueToFormat) {
      args.startValueToFormat = 0;
    }
    const kpiDisplay: KPIDisplay = {
      label: args.label ? args.label : '',
      rawValue: args.value,
    };

    if (
      args.formatType === KPIFormattedType.Date &&
      (args.value === null || args.value === undefined)
    ) {
      kpiDisplay.formattedValue = 'N/A';
      return kpiDisplay;
    }

    if (args.formatType !== KPIFormattedType.Date && !args.value) {
      args.value = 0;
    }

    switch (args.formatType) {
      case KPIFormattedType.Numeric:
        kpiDisplay.formattedValue = this.formatNumber(
          args.value,
          args.startValueToFormat
        );
        break;
      case KPIFormattedType.Currency:
        kpiDisplay.formattedValue = this.formatCurrency(
          args.value,
          args.startValueToFormat
        );
        break;
      case KPIFormattedType.Date:
        kpiDisplay.formattedValue = moment(args.value, [
          moment.ISO_8601,
        ]).format('l');
        break;
      case KPIFormattedType.Weight:
        kpiDisplay.formattedValue = this.formatNumber(
          args.value,
          args.startValueToFormat,
          2
        );
        break;
      case KPIFormattedType.Percentage:
        kpiDisplay.formattedValue = this.formatPercentage(args.value);
        break;
    }
    return kpiDisplay;
  }

  formatDecimal(
    value: number,
    formatType: KPIFormattedType,
    minDecimals?: number,
    maxDecimals?: number
  ) {
    const locale = this.getBrowserLanguage() || 'en-US';
    return Number(value).toLocaleString(
      locale,
      this.getNumericFormatOptions(formatType, minDecimals, maxDecimals)
    );
  }

  private formatNumber(
    number: number,
    startNumberToFormat: number,
    decimals?: number
  ) {
    if (number >= 100000000 && startNumberToFormat <= 100000000) {
      return `${this.formatDecimal(
        number / 1000000,
        KPIFormattedType.Numeric,
        0,
        1
      )}M`;
    }
    if (number >= 1000000 && startNumberToFormat <= 1000000) {
      return `${this.formatDecimal(
        number / 1000000,
        KPIFormattedType.Numeric
      )}M`;
    }
    if (number >= 100000 && startNumberToFormat <= 100000) {
      return `${this.formatDecimal(
        number / 1000,
        KPIFormattedType.Numeric,
        0,
        2
      )}k`;
    }
    if (number >= 10000 && startNumberToFormat <= 10000) {
      return `${this.formatDecimal(
        number / 1000,
        KPIFormattedType.Numeric,
        0,
        2
      )}k`;
    }
    if (number >= 1000 && startNumberToFormat <= 1000) {
      return `${this.formatDecimal(
        number / 1000,
        KPIFormattedType.Numeric,
        0,
        2
      )}k`;
    }
    const minDecimals = decimals || 0;
    const maxDecimals = decimals || 2;

    return this.formatDecimal(
      number,
      KPIFormattedType.Numeric,
      minDecimals,
      maxDecimals
    );
  }

  private formatCurrency(number: number, startNumberToFormat: number) {
    const locale = this.getBrowserLanguage() || 'en-US';
    const currentSymbol = getLocaleCurrencySymbol(locale);
    if (number === 0) {
      return `${currentSymbol}${this.formatDecimal(
        number,
        KPIFormattedType.Currency
      )}`;
    }
    if (number >= 1000000 && startNumberToFormat <= 1000000) {
      return `${currentSymbol}${this.formatDecimal(
        number / 1000000,
        KPIFormattedType.Currency,
        0,
        1
      )}M`;
    }
    if (number >= 10000 && startNumberToFormat <= 10000) {
      return `${currentSymbol}${this.formatDecimal(
        number / 1000,
        KPIFormattedType.Currency,
        0,
        2
      )}k`;
    }
    if (number >= 1000 && startNumberToFormat <= 1000) {
      return `${currentSymbol}${this.formatDecimal(
        number / 1000,
        KPIFormattedType.Currency,
        0,
        2
      )}k`;
    }
    return `${currentSymbol}${this.formatDecimal(
      number,
      KPIFormattedType.Currency
    )}`;
  }

  private formatPercentage(number: number) {
    return `${(number * 100).toFixed(2)}%`;
  }

  private getNumericFormatOptions(
    kpiFormattedType: KPIFormattedType,
    minDecimals?: number,
    maxDecimals?: number
  ): any {
    if (minDecimals >= 0 && maxDecimals >= 0) {
      return {
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: maxDecimals,
      };
    }
    switch (kpiFormattedType) {
      case KPIFormattedType.Currency:
        return {
          minimumFractionDigits: minDecimals ? minDecimals : 2,
          maximumFractionDigits: maxDecimals ? maxDecimals : 2,
        };
      case KPIFormattedType.Numeric:
        return {
          minimumFractionDigits: minDecimals ? minDecimals : 0,
          maximumFractionDigits: maxDecimals ? maxDecimals : 2,
        };
    }
  }

  private getBrowserLanguage(): string {
    const browserLang = this.translateService.getBrowserLang();

    if (this.supportedLangs.indexOf(browserLang) < 0) {
      return 'en-US';
    }

    return browserLang;
  }
}
