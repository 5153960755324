import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable()
export class MemoryStorageService implements StorageService {
  private data: { [key: string]: any } = {};

  public getKeys() {
    return Object.keys(this.data);
  }

  public get(key: string, defaultValue?: any) {
    return this.data[key] ? JSON.parse(this.data[key]) : defaultValue;
  }

  public set(key: string, value: any) {
    this.data[key] = JSON.stringify(value);
    return true;
  }

  public remove(key: string) {
    delete this.data[key];
  }

  public clear() {
    this.data = {};
  }
}
