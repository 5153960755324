/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-underscore-dangle */
import { Injectable, Injector, NgZone } from '@angular/core';
import { IonicAuth } from '@ionic-enterprise/auth';
import { TranslateService } from '@ngx-translate/core';
import { B2CProviderType } from '@sonar/login/b2c-provider-type';
import { SonarUserRole } from '@sonar/shared/sonar-user-role';
import * as moment from 'moment';
import OneSignal from 'onesignal-cordova-plugin';
import { TrackJS } from 'trackjs';

import { AppSettingsService } from './app-settings.service';
import { AuthConfigService } from './auth-config.service';
import { LaunchDarklyService } from './launch-darkly.service';
import { UserSettingsService } from './user-settings/user-settings.service';
import { VaultService } from './vault.service';
import { B2CProviderInfo } from '../login/b2c-provider-info';
import { Capacitor } from '@capacitor/core';

declare var pendo: any;

@Injectable({
  providedIn: 'root',
})
export class AuthService extends IonicAuth {
  private readonly selectPrompt;
  private readonly loginPrompt;

  constructor(
    private appSettingsService: AppSettingsService,
    private ngZone: NgZone,
    private userSettingsService: UserSettingsService,
    private launchDarklyService: LaunchDarklyService,
    private injector: Injector,
    authConfigService: AuthConfigService,
    vaultService: VaultService
  ) {
    // Determine whether to run on mobile or the web
    const selectedConfig = authConfigService.getAuthConfig();
    selectedConfig.tokenStorageProvider = vaultService.vault;
    super(selectedConfig);

    this.selectPrompt = 'select_account';
    this.loginPrompt = 'login';
  }

  async initializeUser() {
    const user = await Promise.all([
      this.userSettingsService.getUserInfo(),
      this.userSettingsService.getUserSettings(),
    ]);
    const userInfo = user[0];
    const settings = user[1];

    if (Capacitor.isNativePlatform()) {
      await this.registerUserWithOneSignal();
    }
    const translateService = this.injector.get(TranslateService);
    translateService.getTranslation(settings.language);
    translateService.use(settings.language);
    translateService.reloadLang(settings.language);
    moment.locale(settings.language);
    TrackJS.configure({
      userId: userInfo.email,
      onError: () => true,
    });
    await this.launchDarklyService.changeUser(userInfo);
    this.ngZone.runOutsideAngular(() => {
      pendo.initialize({
        visitor: {
          id: userInfo.azureId, // Required if user is logged in
          email: userInfo.email, // Recommended if using Pendo Feedback, or NPS Email
          // eslint-disable-next-line @typescript-eslint/naming-convention
          full_name: userInfo.fullName, // Recommended if using Pendo Feedback
          role: userInfo.userRole, // Optional
          language: settings.language,
        },

        account: {
          id: userInfo.companyName,
        },
      });
    });
  }

  async getUserInfo() {
    const idToken = await this.getIdToken();
    if (!idToken) {
      return;
    }

    let email = idToken.email;
    if (idToken.emails instanceof Array) {
      email = idToken.emails[0];
    }
    return {
      id: idToken.sub,
      email,
      firstName: idToken.given_name,
      lastName: idToken.family_name,
      picture: 'assets/user-placeholder.jpg',
    };
  }

  async registerUserWithOneSignal() {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    const isLoggedIn = await this.isAuthenticated();
    if (!isLoggedIn) {
      return;
    }
    await this.registerUserWithOneSignalImpl();
  }

  setAdditionalParameters(emailAddress: string, providerInfo: B2CProviderInfo) {
    let promptArg: string;

    if (providerInfo.providerType === B2CProviderType.AzureAD) {
      promptArg = this.selectPrompt;
    } else {
      promptArg = this.loginPrompt;
    }

    this.additionalLoginParameters({
      login_hint: emailAddress,
      domain_hint: providerInfo.domain,
      prompt: promptArg,
    });
  }

  private async registerUserWithOneSignalImpl() {
    const user = await this.getUserInfo();

    const userInfo = await this.userSettingsService.getUserInfo();
    const env = this.appSettingsService.config.env;
    OneSignal.setExternalUserId(user.id);
    OneSignal.sendTags({ env, user_id: userInfo.userId });
  }
}
