/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/member-ordering */
import {
  Component,
  ViewChildren,
  QueryList,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IonSelect, ModalController } from '@ionic/angular';
import * as _ from 'lodash';
import { UserSettingsService } from '@sonar/core/user-settings/user-settings.service';
import { SensorType } from '@sonar/shared/sensor-type';

@Component({
  selector: 'house-card-settings',
  templateUrl: 'house-card-settings-modal.component.html',
  styleUrls: ['./house-card-settings-modal.component.scss']
})
export class HouseCardSettingsModalComponent implements OnChanges {
  @ViewChildren(IonSelect) sensorSelected: QueryList<IonSelect>;
  private readonly defaultSensorFilters: any[] = [
    { screenIndex: 1, sensorType: SensorType.Temperature },
    { screenIndex: 2, sensorType: SensorType.WaterConsumption },
    { screenIndex: 3, sensorType: SensorType.Weight },
    { screenIndex: 4, sensorType: SensorType.BinFeedAmount },
    { screenIndex: 5, sensorType: SensorType.Humidity },
  ];

  constructor(
    private readonly userSettingsService: UserSettingsService,
    private readonly modalController: ModalController
  ) {}

  @Input() sensorCount: any;
  @Input() sensorData: any;

  sensorsFilters: any[];
  sensorsFiltered = [];
  showError = false;
  indexes: number[];
  viewCountSensor: any;

  ionViewWillEnter() {
    if (this.sensorData.length < this.sensorCount) {
      this.sensorCount = this.sensorData.length;
    }

    this.sensorsFilters = _.cloneDeep(this.sensorData);
    if (this.sensorsFilters.every((s) => s.screenIndex === -1)) {
      this.sensorsFilters = this.defaultSensorFilters;
    }

    this.indexes = Array.from(Array(10).keys());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.sensorCount || !changes.sensorData) {
      return;
    }

    if (this.sensorData.length < this.sensorCount) {
      this.sensorCount = this.sensorData.length;
    }
    this.sensorsFilters = _.cloneDeep(this.sensorData);
  }

  async closeModal() {
    if (this.showError) {
      return;
    }

    this.sensorsFiltered =
      this.sensorsFiltered.length > 0
        ? this.sensorsFiltered
        : this.sensorsFilters.map((s) => s.sensorType);
    this.modalController.dismiss(this.sensorsFiltered);
    await this.userSettingsService.save(
      'houseCardSensorsOrder',
      this.sensorsFiltered
    );
  }

  onChangeSensorType(args: any, index: number) {
    this.sensorsFilters[index].sensorType = args.detail.value;
    this.sensorsFiltered = this.sensorSelected
      .toArray()
      .filter((s) => typeof s.value === 'number')
      .map((s) => s.value);
    this.showError =
      _.uniq(this.sensorsFiltered).length !== this.sensorsFiltered.length;
    return this.sensorsFiltered;
  }
}
