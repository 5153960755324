import { UnitConversionService } from "@sonar/core/unit-conversion.service";
import { SonarData } from "./sonar-data";
import { SonarDataType } from "@sonar/shared/sensor-type";
import { NumberSymbol } from "@angular/common";


export class StaticPressureSensor extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;
  sonarDataType: SonarDataType = SonarDataType.StaticPressure;
  isSensor = true;
  
  statistic = 'static-pressure';
  iconName = 'static-pressure';
  sensorStyleClass = 'ami-static-pressure';
  decimalPlaces = '1.0-3';
  maxSensorCount = 1;
  labelKey = 'KPIS.STATIC_PRESSURE';
  
  convertUnit = (value: number, u: UnitConversionService) => value;
  getSensorUnit = (unitConversionService: UnitConversionService): string => unitConversionService.getStaticPressureUnit();
  getPropertyNames = (prefix?: string, suffix?: string): string[] => this.generatePropertyNames(prefix, 'staticPressure', suffix);
}
