import { Injectable } from '@angular/core';
import { TranslationUtilService } from '../translation-util.service';
import { SensorType, SonarDataType } from '@sonar/shared/sensor-type';
import { HouseDataType } from '../user-settings/house-sensor-type';

@Injectable({
  providedIn: 'root',
})
export class HouseDataTypeSorterService {
  public readonly defaultSensorTypes: SonarDataType[] = [
    SensorType.Temperature,
    SensorType.WaterConsumption,
    SensorType.Weight,
    SensorType.BinFeedAmount,
    SensorType.Humidity,
  ] as any;

  constructor(private translationUtil: TranslationUtilService) {}

  public sortAlphabetically = (unsortedHouseData: HouseDataType[]) =>
    unsortedHouseData.sort((a, b) =>
      this.translationUtil.compareAlphabetical(a.label, b.label)
    );

  public filterUserHouseDataOrDefault(
    unsortedHouseData: HouseDataType[]
  ): HouseDataType[] {
    const prioritizedHouseData = this.getPrioritizedHouseData(unsortedHouseData);

    return this.sortByScreenIndex(prioritizedHouseData);
  }

  // Returns house data sorted by screen index with unset indices at the end
  // Ex: 1, 2, 3, 4, 5, -1, -1, -1
  private sortByScreenIndex = (unsortedHouseData: HouseDataType[]) =>
    unsortedHouseData.sort((a, b) => {
      if (a.screenIndex === -1 && b.screenIndex === -1) {
        return 0;
      }
      else if (a.screenIndex === -1) {
        return 1;
      }
      else if (b.screenIndex === -1) {
        return -1;
      }
      else {
        return a.screenIndex - b.screenIndex;
      }
    });

  private getPrioritizedHouseData = (
    unsortedHouseData: HouseDataType[]
  ): HouseDataType[] =>
    this.shouldUseDefaultHouseData(unsortedHouseData)
      ? this.getDefaultHouseData(unsortedHouseData)
      : this.getUserHouseData(unsortedHouseData);

  private getUserHouseData = (
    unsortedHouseData: HouseDataType[]
  ): HouseDataType[] => unsortedHouseData.filter((s) => s.screenIndex !== -1);

  private getDefaultHouseData(
    unsortedHouseData: HouseDataType[]
  ): HouseDataType[] {
    let defaultHouseData = unsortedHouseData.filter((s) =>
      this.isDefaultSensor(s)
    );

    defaultHouseData = defaultHouseData.sort((a, b) =>
      this.translationUtil.compareAlphabetical(a.label, b.label)
    );
    defaultHouseData.forEach((s, i) => (s.screenIndex = i + 1));

    return defaultHouseData;
  }

  private isDefaultSensor(sensor): boolean {
    const matchingDefaultSensor = this.defaultSensorTypes.find(
      (defaultSensorType) => defaultSensorType === sensor.sensorType
    );
    return matchingDefaultSensor !== undefined;
  }

  private shouldUseDefaultHouseData = (sensorList: HouseDataType[]) =>
    !sensorList.find((s) => s.screenIndex !== -1);
}
