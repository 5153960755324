import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    QueryList,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { MtSwiperSlideComponent } from './mt-swiper-slide.component';

@Component({
    selector: 'mt-swiper',
    templateUrl: './mt-swiper.component.html',
    styleUrls: ['./mt-swiper.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MtSwiperComponent implements AfterViewInit, OnDestroy {
    @ContentChildren(MtSwiperSlideComponent) swiperSlides: QueryList<MtSwiperSlideComponent>;
    @ViewChild('swiperContainer') swiperContainer: ElementRef;

    @Input() enablePagination = false;
    @Input() initialSlide = 0;

    @Output() slideClicked = new EventEmitter<any>();

    swiper: SwiperCore;

    ngAfterViewInit() {
        this.swiper = new SwiperCore(this.swiperContainer.nativeElement, {
            centeredSlides: true,
            initialSlide: this.initialSlide,
            modules: [Navigation, Pagination],
            observeParents: true,
            observer: true,
            roundLengths: true,
            slidesPerView: 'auto',
            spaceBetween: 8,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                type: 'bullets',
            }
        });
        this.swiper.on('click', () => {
            this.slideClicked.emit({ clickedIndex: this.swiper.clickedIndex, clickedElement: event.target });
        });
    }

    ngOnDestroy() {
        this.destroySwiper();
    }

    destroySwiper() {
        if (!this.swiper) {
            return;
        }
        this.initialSlide = 0;
        this.swiper.destroy();
        this.swiper = null;
    }

    resetSwiper() {
        if (this.initialSlide === 0) {
            this.swiper.slideTo(0);
            return;
        }
    }
}
  