import { UnitConversionService } from "@sonar/core/unit-conversion.service";
import { SonarData } from "./sonar-data";
import { SonarDataType } from "@sonar/shared/sensor-type";
import { NumberSymbol } from "@angular/common";


export class DistressSensor extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;
  isSensor = true;
  sonarDataType: SonarDataType = SonarDataType.Distress;
  
  statistic = 'distress';
  iconName = 'distress';
  sensorStyleClass = 'ami-distress';
  decimalPlaces = '1.2-2';
  maxSensorCount = 1;
  labelKey = 'KPIS.DISTRESS';

  getSensorUnit = (u: UnitConversionService): string => undefined;
  convertUnit = (value: number, u: UnitConversionService) => value;
  getPropertyNames = (prefix?: string, suffix?: string): string[] => this.generatePropertyNames(prefix, 'distress', suffix);
}
