/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/no-namespace */

import { SensorType } from '../sensor-type';
import { AlertSeverity } from './alert-severity';
import { AlertStatus } from './alert-status';

/* eslint-disable @typescript-eslint/naming-convention */

export enum AlertSettingHeader {
  AirFlow = 0,
  Ammonia = 1,
  CarbonDioxide = 2,
  Depletion = 3,
  Device = 4,
  Feed = 5,
  Humidity = 6,
  Temperature = 7,
  Water = 8,
  Weight = 9,
  Events = 10,
}
