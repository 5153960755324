import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationUtilService {
  constructor(private translateService: TranslateService) {}

  compareAlphabetical(labelA: string, labelB: string) {
    const translatedLabelA = this.translateService.instant(labelA);
    const translatedLabelB = this.translateService.instant(labelB);
    return translatedLabelA.localeCompare(translatedLabelB);
  }
}