<ion-header class="navigation">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button data-pendoid="house-card-setting-modal-close-button" (click)="closeModal()">
        <i class="ami ami-arrow-left ami-xl"></i>
      </ion-button>
    </ion-buttons>
    <ion-title data-pendoid="house-card-setting-modal-title">
      {{ "FARM_DETAIL_PAGE.SETTINGS.TITLE" | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-item-divider *ngIf="showError" data-pendoid="house-card-setting-modal-error-message" class="divider_error">{{
    "SENSOR_TYPES_ORDER_SETTINGS.ERROR_MESSAGE" | translate
  }}</ion-item-divider>

  <ng-container *ngFor="let index of indexes">
    <ion-item>
      <ion-label>{{
        "FARM_DETAIL_PAGE.SETTINGS.SENSOR_VALUE"
          | translate: { value: index + 1 }
      }}</ion-label>
      <ion-select
        *ngIf="sensorsFilters[index]"
        data-pendoid="house-card-setting-modal-selectable-sensor-index"
        interface="action-sheet"
        [value]="sensorsFilters[index].sensorType"
        (ionChange)="onChangeSensorType($event, index)"
      >
        <ion-select-option data-pendoid="house-card-setting-modal-na-sensor-option">N/A</ion-select-option>
        <ng-container *ngFor="let sensor of sensorData">
          <ion-select-option *ngIf="sensor" [attr.data-pendoid]="'house-card-setting-modal-' + sensor.label + '-sensor-option'" [value]="sensor.sensorType">
            {{ sensor.label | translate }}
          </ion-select-option>
        </ng-container>
      </ion-select>
      <ion-select
        *ngIf="!sensorsFilters[index]"
        data-pendoid="house-card-setting-modal-selectable-sensor-index"
        interface="action-sheet"
        (ionChange)="onChangeSensorType($event, index)"
      >
        <ion-option [selected]="!sensorsFilters[index]">N/A</ion-option>
        <ng-container *ngFor="let sensor of sensorData">
          <ion-option *ngIf="sensor" [attr.data-pendoid]="'house-card-setting-modal-' + sensor.label + '-sensor-option'" [value]="sensor.sensorType">
            {{ sensor.label | translate }}
          </ion-option>
        </ng-container>
      </ion-select>
    </ion-item>
  </ng-container>
</ion-content>
