/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/no-namespace */

import { SensorType } from '../sensor-type';
import { AlertSeverity } from './alert-severity';
import { AlertStatus } from './alert-status';

/* eslint-disable @typescript-eslint/naming-convention */
export enum AlertType {
  Threshold = 0,
  FeedWillBeEmpty = 1,
  RateOfChange = 2,
  DeclineInPeakFlowWaterRate = 3,
  TotalWaterConsumptionDecrease = 4,
  WeightLoss = 5,
  WeightGainDecrease = 6,
  DeviceOffline = 7,
  FeedConsumptionDecline = 8,
  NoDepletion = 9,
  DepletionOverMaxTarget = 10,
  FeedConsumptionHourlyThreshold = 11,
  FeedConsumptionDailyThreshold = 12,
  UpcomingPlacement = 13,
  UpcomingFeedDelivery = 14,
  UpcomingProcessing = 15,
  PlacementTransaction = 16,
  FeedTransaction = 17,
  ProcessingTransaction = 18,
}

export namespace AlertType {
  export function getAlertMessage(
    sensorType: SensorType,
    alertType: AlertType,
    severity: AlertSeverity,
    minThreshold: number,
    maxThreshold: number,
    isNew: boolean = false
  ): string {
    switch (alertType) {
      case AlertType.DeviceOffline:
        return 'ALERT_SENSORS_OFFLINE';
      case AlertType.FeedConsumptionDailyThreshold:
        return 'ALERT_FEED_DAILY_THRESHOLD';
      case AlertType.FeedConsumptionDecline:
        return 'ALERT_FEED_CONSUMPTION_DECLINE';
      case AlertType.FeedConsumptionHourlyThreshold:
        return 'ALERT_FEED_HOURLY_THRESHOLD';
      case AlertType.FeedWillBeEmpty:
        return 'ALERT_FEED_WILL_BE_EMPTY';
      case AlertType.NoDepletion:
        return 'ALERT_NO_DEPLETION';
      case AlertType.DepletionOverMaxTarget:
        return 'ALERT_HIGH_DEPLETION';
      case AlertType.DeclineInPeakFlowWaterRate:
        return 'ALERT_WATER_FLOW_DECLINE';
      case AlertType.TotalWaterConsumptionDecrease:
        return 'ALERT_WATER_CONSUMPTION_DECREASE';
      case AlertType.WeightGainDecrease:
        return 'ALERT_WEIGHT_GAIN_DECREASE';
      case AlertType.WeightLoss:
        return 'ALERT_WEIGHT_LOSS';
      case AlertType.UpcomingPlacement:
        if (isNew) {
          return 'ALERT_NEW_UPCOMING_PLACEMENT';
        }
        return 'ALERT_UPDATED_UPCOMING_PLACEMENT';
      case AlertType.UpcomingFeedDelivery:
        if (isNew) {
          return 'ALERT_NEW_UPCOMING_FEED_DELIVERY';
        }
        return 'ALERT_UPDATED_UPCOMING_FEED_DELIVERY';
      case AlertType.UpcomingProcessing:
        if (isNew) {
          return 'ALERT_NEW_UPCOMING_PROCESSING';
        }
        return 'ALERT_UPDATED_UPCOMING_PROCESSING';
      case AlertType.PlacementTransaction:
        if (isNew) {
          return 'ALERT_NEW_PLACEMENT_TRANSACTION';
        }
        return 'ALERT_UPDATED_PLACEMENT_TRANSACTION';
      case AlertType.FeedTransaction:
        if (isNew) {
          return 'ALERT_NEW_FEED_TRANSACTION';
        }
        return 'ALERT_UPDATED_FEED_TRANSACTION';
      case AlertType.ProcessingTransaction:
        if (isNew) {
          return 'ALERT_NEW_PROCESSING_TRANSACTION';
        }
        return 'ALERT_UPDATED_PROCESSING_TRANSACTION';
      case AlertType.Threshold:
        return getThresholdMessage(
          sensorType,
          severity,
          minThreshold,
          maxThreshold
        );
      case AlertType.RateOfChange:
        return 'ALERT_TEMP_RATE_OF_CHANGE';
      default:
        return 'ACTIVE_ALERT';
    }
  }

  export function isEventAlert(alertType: AlertType): boolean {
    switch(alertType) {
      case AlertType.UpcomingPlacement:
      case AlertType.UpcomingFeedDelivery:
      case AlertType.UpcomingProcessing:
      case AlertType.PlacementTransaction:
      case AlertType.FeedTransaction:
      case AlertType.ProcessingTransaction:
        return true;
    }

    return false;
  }

  function getThresholdMessage(
    sensorType: SensorType,
    severity: AlertSeverity,
    minThreshold: number,
    maxThreshold: number
  ): string {
    let sensorString: string;
    let minMaxString = '';
    if (minThreshold) {
      minMaxString = 'MIN';
    } else if (maxThreshold) {
      minMaxString = 'MAX';
    }
    const criticalString =
      severity === AlertSeverity.Critical ? '_CRITICAL' : '';

    switch (sensorType) {
      case SensorType.AirFlow:
        sensorString = 'AIRFLOW';
        break;
      case SensorType.Ammonia:
        sensorString = 'AMMONIA';
        break;
      case SensorType.CarbonDioxide:
        sensorString = 'C02';
        break;
      case SensorType.BinFeedAmount:
        sensorString = 'FEED';
        break;
      case SensorType.Humidity:
        sensorString = 'HUMIDITY';
        break;
      case SensorType.Temperature:
        sensorString = 'TEMP';
        break;
      case SensorType.WaterConsumption:
        sensorString = 'WATER';
        break;
      case SensorType.Weight:
        sensorString = 'WEIGHT';
        break;
      default:
        return 'ACTIVE_ALERT';
    }

    return `ALERT_${sensorString}${criticalString}_THRESHOLD_${minMaxString}`;
  }
}
