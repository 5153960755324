/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-var */
import { Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { Device } from '@ionic-enterprise/identity-vault';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '@sonar/core';
import { AppSettingsService } from '@sonar/core/app-settings.service';
import { AppVersionService } from '@sonar/core/app-version.service';
import { AuthService } from '@sonar/core/auth.service';
import { LaunchDarklyService } from '@sonar/core/launch-darkly.service';
import { MemoryStorageService } from '@sonar/core/memory-storage.service';
import { UserSettingsService } from '@sonar/core/user-settings/user-settings.service';
import { VaultService } from '@sonar/core/vault.service';
import { AppInsights } from 'applicationinsights-js';
import { Subject, lastValueFrom } from 'rxjs';
import { TrackJS } from 'trackjs';

import azureSettings from '../../assets/AzureSettings.json';
import { DataServiceFactory } from '../core/data-service.factory';
import { MaxCacheAge } from '../core/max-cache-age';
import { B2CProviderType } from './b2c-provider-type';
import { B2CProviderInfo } from './b2c-provider-info';

import Swiper, { Autoplay, Pagination, SwiperOptions } from 'swiper';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage {
  @ViewChild('swiper') swiperElement: ElementRef;

  private swiper: Swiper;
  private unsubscribe$: Subject<void> = new Subject();
  private rememberMeKey = 'rememberMe';
  private emailKey = 'email';

  // Our translated text strings
  protected showNewDesignLogin = false;
  protected showNewDesignLoginFlag = 'show-new-sonar-app-login-screen';
  protected slideIndex = 0;
  protected swiperConfig: SwiperOptions = {
    modules: [Pagination, Autoplay],
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    autoplay: {
      delay: 5000,
    },
  };

  // The account fields for the login form.
  // If you're using the username field with or without email, make
  // sure to add it to the type
  credentials: { userName: string; password: string } = {
    userName: '',
    password: '',
  };

  isLoggingIn = false;
  loginString: 'EMAIL';
  canUnlock = false;
  isAuthenticated = false;
  emailInput = '';
  rememberMe = false;

  emailInputFocus: boolean;
  isSupportedAppVersion: boolean;

  readonly b2cProviderTypeDataService: DataService;

  constructor(
    private readonly authService: AuthService,
    private readonly vaultService: VaultService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly ngZone: NgZone,
    private readonly appSettingsService: AppSettingsService,
    private readonly userSettingService: UserSettingsService,
    private readonly memoryStorageService: MemoryStorageService,
    private readonly platform: Platform,
    private readonly toastController: ToastController,
    private readonly launchDarklyService: LaunchDarklyService,
    private readonly storage: Storage,
    private readonly dataServiceFactory: DataServiceFactory,
    private readonly alertController: AlertController,
    private readonly appVersionService: AppVersionService
  ) {
    this.b2cProviderTypeDataService = dataServiceFactory.create({
      endPoint: 'B2CProvider',
      maxCacheAge: MaxCacheAge.Long,
    });
    this.vaultService.canUnlock$.subscribe((x) => (this.canUnlock = x));
    this.showNewDesignLogin = this.launchDarklyService.boolVariation(
      this.showNewDesignLoginFlag
    );
  }

  async ionViewWillEnter() {
    this.isSupportedAppVersion =
      await this.appVersionService.isSupportedAppVersion();

    if (!this.isSupportedAppVersion) {
      this.alertController
        .create({
          header: 'Unsupported App Version',
          message:
            'This version of the app is no longer supported. Please check the app store for the latest update.',
          buttons: [],
        })
        .then((obj) => obj.present());
      return;
    }

    const isExpired = await this.authService.isAccessTokenExpired();
    try {
      this.isAuthenticated =
        !isExpired && (await this.authService.isAuthenticated());

      if (this.isAuthenticated) {
        await this.authService.initializeUser();

        this.navigateToBenchmarking();
      }
    } catch {
      this.isAuthenticated = false;
    }

    this.rememberMe = await this.storage.get(this.rememberMeKey);
    if (this.rememberMe) {
      this.emailInput = await this.storage.get(this.emailKey);
    }
  }

  ionViewDidEnter() {
    AppInsights.stopTrackEvent('LoginPage - Page Load Time');
    this.isLoggingIn = false;
    this.configSwiperLogin();
    setTimeout(() => {
      if (!this.swiper) {
        return;
      }
      this.swiper.autoplay.start();
    });
  }

  ionViewWillLeave() {
    AppInsights.stopTrackPage('LoginPage', window.location.href);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async doLogin() {
    this.isLoggingIn = true;
    try {
      if (await this.authService.isAuthenticated()) {
        // if it unlocks, enter app
        this.navigateToBenchmarking();
      } else {
        await this.authService.login();
        await this.processSuccessfulLogin();
      }
    } catch (err) {
      await this.processFailedLogin(err);
    }
  }

  navigateToBenchmarking() {
    this.ngZone.run(async () => {
      await this.router.navigate(['benchmarking']);
      this.isLoggingIn = false;
    });
  }

  redirectNavigate(url: string) {
    console.log(url);
  }

  forgotPassword() {
    this.resetPassword();
  }

  async updatedForgotPassword() {
    if (!this.validateEmail()) {
      const toast = await this.toastController.create({
        duration: 3000,
        message: await this.translateService
          .get('INVALID_EMAIL_PASSWORD_RESET')
          .toPromise(),
        position: 'top',
        color: 'danger',
      });
      await toast.present();
      return;
    }

    const providerType = await lastValueFrom(
      this.b2cProviderTypeDataService.getEntity<B2CProviderType>({
        userEmail: this.emailInput,
      })
    );
    if (providerType === B2CProviderType.AzureAD) {
      const toast = await this.toastController.create({
        duration: 3000,
        message: await this.translateService
          .get('INVALID_PROVIDER_PASSWORD_RESET')
          .toPromise(),
        position: 'top',
        color: 'danger',
      });
      await toast.present();
      return;
    }

    this.resetPassword();
  }

  validateEmail(): boolean {
    if (!this.emailInput) {
      return false;
    }

    const expression = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return expression.test(this.emailInput);
  }

  resetPassword() {
    const env = this.appSettingsService.config.env;
    let returnUri =
      'sonarweb.mtech-systems.com&scope=openid&response_type=id_token&prompt=login';
    switch (env) {
      case 'dev':
        returnUri =
          'devsonarweb.mtech-systems.com&scope=openid&response_type=id_token&prompt=login';
        break;
      case 'demo':
        returnUri =
          'demosonarweb.mtech-systems.com&scope=openid&response_type=id_token&prompt=login';
        break;
    }

    const resetPassword = `${azureSettings.B2CEndpoint.replace(
      'token',
      'authorize'
    ).replace(
      'B2C_1A_SONAR_EXTERNAL_SIGNIN',
      'B2C_1_password-reset'
    )}&client_id=${
      azureSettings.B2CClientId
    }&nonce=defaultNonce&redirect_uri=https%3A%2F%2F${returnUri}`;

    Browser.open({
      url: resetPassword,
      windowName: '_system',
    });
  }

  async updatedLogin() {
    if (!this.validateEmail()) {
      const toast = await this.toastController.create({
        duration: 3000,
        message: await this.translateService
          .get('INVALID_EMAIL_LOGIN')
          .toPromise(),
        position: 'top',
        color: 'danger',
      });
      await toast.present();
      return;
    }

    this.isLoggingIn = true;
    try {
      if (await this.authService.isAuthenticated()) {
        // if it unlocks, enter app
        this.navigateToBenchmarking();
      } else {
        await this.storage.set(this.rememberMeKey, this.rememberMe);
        await this.storage.set(this.emailKey, this.emailInput);

        const providerInfo = await lastValueFrom(
          this.b2cProviderTypeDataService.getEntity<B2CProviderInfo>(
            { userEmail: this.emailInput },
            null,
            true
          )
        );
        this.authService.setAdditionalParameters(this.emailInput, providerInfo);

        await this.authService.login();
        await this.processSuccessfulLogin();
      }
    } catch (err) {
      await this.processFailedLogin(err);
    }
  }

  onFocusEmail() {
    this.emailInputFocus = true;
  }

  onBlurEmail() {
    this.emailInputFocus = false;
  }

  onInputEmail() {
    this.isAuthenticated = false;
    this.isLoggingIn = false;
  }

  private async processSuccessfulLogin() {
    await this.authService.initializeUser();
    const hasPasscode = await Device.isSystemPasscodeSet();
    this.vaultService.setUnlockMode(hasPasscode ? 'Device' : 'NeverLock');
    this.navigateToBenchmarking();
  }

  private async processFailedLogin(err: any) {
    if (err.status === 401) {
      const toast = await this.toastController.create({
        message: err.error.message,
        position: 'top',
        duration: 3000,
        color: 'danger',
      });

      await toast.present();
      this.isLoggingIn = false;
      return;
    }
    console.log(err);
    TrackJS.track(err.error);
    this.memoryStorageService.clear();
    await this.authService.logout();
    this.isLoggingIn = false;
  }

  private configSwiperLogin() {
    if (!this.showNewDesignLogin) {
      return;
    }
    this.swiper = new Swiper('.swiper', this.swiperConfig);
    this.swiper.on(
      'slideChange',
      () => (this.slideIndex = this.swiper.realIndex)
    );
    this.swiper.on('resize', () => {
      if (!this.swiperElement?.nativeElement) {
        return;
      }
      this.swiper.update();
      this.swiper.autoplay.start();
    });
  }
}
