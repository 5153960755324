import { UnitConversionService } from "@sonar/core/unit-conversion.service";
import { SonarData } from "./sonar-data";
import { SonarDataType } from "@sonar/shared/sensor-type";
import { NumberSymbol } from "@angular/common";


export class AmmoniaSensor extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;
  isSensor = true;
  sonarDataType: SonarDataType = SonarDataType.Ammonia;
  
  statistic = 'ammonia';
  iconName = 'ammonia';
  sensorStyleClass = 'ami-ammonia';
  decimalPlaces = '1.2-2';
  maxSensorCount = 4;
  labelKey = 'KPIS.AMMONIA';
  
  getSensorUnit = (u: UnitConversionService): string => '-';
  convertUnit = (value: number, u: UnitConversionService) => value;

  getPropertyNames = (prefix?: string, suffix?: string): string[] => this.generatePropertyNames(prefix, 'ammonia', suffix);
}
