<div class="timeline">
  <div #timelineScroll class="timeline__scroll">
    <div class="timeline__line"></div>
    <div *ngIf="loaded" class="timeline__content">
      <ng-container *ngFor="let day of days">
        <div class="timeline__legend_day caption">
          <div class="timeline__legend-circle"></div>
          <span
            data-pendoid="alert-timeline-day-label"
            data-testid="alert-timeline-day-label"
            class="timeline__legend-label"
          >
            {{ day.label | translate }}
          </span>
        </div>
        <div *ngFor="let alert of day.hours" class="timeline__item">
          <div class="timeline__legend body2">
            <span
              data-pendoid="alert-timeline-time-label"
              data-testid="alert-timeline-time-label"
              class="timeline__legend-label"
            >
              {{ alert.formattedValue }}
              <span
                *ngIf="!alert.end"
                data-pendoid="alert-timeline-ongoing-notification"
                data-testid="alert-timeline-ongoing-notification"
                class="timeline__legend-current"
              >
                {{ "ON_GOING" | translate }}
              </span>
            </span>
            <div
              data-pendoid="alert-timeline-circle-legend"
              data-testid="alert-timeline-circle-legend"
              class="timeline__legend-circle"
            ></div>
          </div>
          <card-alert
            data-pendoid="alert-timeline-alert-card"
            data-testid="alert-timeline-alert-card"
            class="timeline__card"
            [alert]="alert"
            (cardSelected)="onAlertSelected($event)"
          ></card-alert>
        </div>
      </ng-container>
    </div>

    <div *ngIf="!loaded" class="timeline__content-empty">
      <ng-container *ngFor="let day of [].constructor(3)">
        <div class="timeline__legend_day">
          <div class="timeline__legend-circle"></div>
          <span class="timeline__legend-label">
            <ion-skeleton-text
              animated
              class="skeleton-loader"
            ></ion-skeleton-text>
          </span>
        </div>
        <div class="timeline__item">
          <div class="timeline__legend body2">
            <span class="timeline__legend-label">
              <ion-skeleton-text
                animated
                class="skeleton-loader"
              ></ion-skeleton-text>
              <span class="timeline__legend-current">
                <ion-skeleton-text
                  animated
                  class="skeleton-loader"
                ></ion-skeleton-text>
              </span>
            </span>
            <div class="timeline__legend-circle"></div>
          </div>
          <mt-card class="timeline__card">
            <ion-skeleton-text
              animated
              class="skeleton-loader"
            ></ion-skeleton-text>
            <ion-skeleton-text
              animated
              class="skeleton-loader"
            ></ion-skeleton-text>
            <ion-skeleton-text
              animated
              class="skeleton-loader"
            ></ion-skeleton-text>
            <ion-skeleton-text
              animated
              class="skeleton-loader"
            ></ion-skeleton-text>
          </mt-card>
        </div>
      </ng-container>
    </div>
  </div>
</div>
