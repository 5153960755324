import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit,
} from '@angular/core';
import { NumericInputArrowEvent } from './numeric-input-arrow.event';
import { NumericInputEntryEvent } from './numeric-input-entry.event';

import * as _ from 'lodash';

@Component({
  selector: 'mt-numeric-input',
  templateUrl: './mt-numeric-input.component.html',
  styleUrls: ['./mt-numeric-input.component.scss'],
})
export class MtNumericInputComponent {
  @ViewChild('input') input: ElementRef;

  @Input() placeholder: any;
  @Input() selectedValue: any;
  @Input() useWeeksAge = false;
  @Input() minValue: any = null;
  @Input() maxValue: any = null;

  @Output() arrowTapped = new EventEmitter<NumericInputArrowEvent>();
  @Output() numberEntered = new EventEmitter<NumericInputEntryEvent>();

  private _currentValue: any;

  get currentValue() {
    if (_.isNil(this._currentValue)) {
      return this.selectedValue;
    }

    return this._currentValue;
  }

  get isMin() {
    return !_.isNil(this.minValue) && this.currentValue <= this.minValue;
  }

  get isMax() {
    return !_.isNil(this.maxValue) && this.currentValue >= this.maxValue;
  }

  reset(value: any) {
    this.selectedValue = value;
    this._currentValue = value;
    this.input.nativeElement.value = value;
  }

  leftArrow() {
    const element = this.input.nativeElement;
    if (!element || this.isMin) {
      return;
    }

    const previousValue = element.value;
    if (this.useWeeksAge) {
      if (+(element.value - Math.floor(element.value)).toFixed(1) === 0) {
        element.value = (+element.value - 0.4).toFixed(1);
      } else {
        element.value = (+element.value - 0.1).toFixed(1);
      }
    } else {
      element.stepDown();
    }

    this._currentValue = element.value;
    this.arrowTapped.emit({
      direction: 'Left',
      currentValue: this.currentValue,
      previousValue,
    });
  }

  rightArrow() {
    const element = this.input.nativeElement;
    if (!element || this.isMax) {
      return;
    }

    const previousValue = element.value;
    if (this.useWeeksAge) {
      if (+(element.value - Math.floor(element.value)).toFixed(1) === 0.6) {
        element.value = (+element.value + 0.4).toFixed(1);
      } else {
        element.value = (+element.value + 0.1).toFixed(1);
      }
    } else {
      element.stepUp();
    }

    this._currentValue = element.value;
    this.arrowTapped.emit({
      direction: 'Right',
      currentValue: this.currentValue,
      previousValue,
    });
  }

  onKeyUp(event: any) {
    if (event.key !== 'Enter') {
      return;
    }

    const isChangingToMin =
      !_.isNil(this.minValue) &&
      +this.input.nativeElement.value < this.minValue;
    if (isChangingToMin) {
      this.selectedValue = this.currentValue;
      this.input.nativeElement.value = this.currentValue;
      return;
    }

    const previousValue = this.currentValue;
    this._currentValue = this.input.nativeElement.value;
    this.numberEntered.emit({
      currentValue: this.currentValue,
      previousValue,
    });
  }
}
