import { UnitConversionService } from "@sonar/core/unit-conversion.service";
import { SonarData } from "./sonar-data";
import { SonarDataType } from "@sonar/shared/sensor-type";
import { NumberSymbol } from "@angular/common";


export class BinFeedAmountSensor extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;
  isSensor = true;
  sonarDataType: SonarDataType = SonarDataType.BinFeedAmount;

  statistic = 'feed';
  iconName = 'feed';
  sensorStyleClass = 'ami-feed';
  decimalPlaces = '1.0-0';
  maxSensorCount = 4;
  labelKey = 'KPIS.FEED';

  getSensorUnit = (unitConversionService: UnitConversionService): string => unitConversionService.getFeedBinAmountUnit();
  getPropertyNames = (prefix?: string, suffix?: string): string[] => this.generatePropertyNames(prefix, 'binFeedAmount', suffix);

  convertUnit(value: number, unitConversionService: UnitConversionService): number {
    if (value === 0) return value;
    return unitConversionService.convertWeight(value);
  }
}
