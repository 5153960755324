import { Component, NgZone, OnDestroy, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { Config, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppInsights } from 'applicationinsights-js';
import * as _ from 'lodash';
import * as moment from 'moment';
import OneSignal from 'onesignal-cordova-plugin';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AppSettingsService } from './core/app-settings.service';
import { AppVersionService } from './core/app-version.service';
import { AuthService } from './core/auth.service';
import { CacheService } from './core/cache.service';
import { AlertService } from './core/data/alert.service';
import { FarmService } from './core/data/farm.service';
import { FontSizeService } from './core/font-size.service';
import { LiveUpdateServiceFactory } from './core/live-update-service.factory';
import { LiveUpdateService } from './core/live-update.service';
import { TabsService } from './core/tabs.service';
import { UserInfo } from './core/user-settings/user-info';
import { UserSetting } from './core/user-settings/user-settings';
import { UserSettingsService } from './core/user-settings/user-settings.service';
import { HouseListPage } from './farms/house-list/house-list.page';
import { LoginPage } from './login/login.page';

// eslint-disable-next-line no-var
declare var pendo: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
  private userInfo: UserInfo;
  private settings: UserSetting;
  private liveUpdateService: LiveUpdateService;
  private readonly unsubscribe$ = new Subject<void>();
  private readonly supportedLangs: string[] = [
    'en-US',
    'pt',
    'es',
    'da',
    'en-AU',
    'it',
    'pt-BR',
    'pt-PT',
    'sv',
  ];

  constructor(
    private readonly alertService: AlertService,
    private readonly config: Config,
    private readonly appSettingsService: AppSettingsService,
    private readonly authService: AuthService,
    private readonly farmService: FarmService,
    private readonly fontSizeService: FontSizeService,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly userSettingsService: UserSettingsService,
    private readonly titleService: Title,
    private readonly platform: Platform,
    private readonly renderer: Renderer2,
    private readonly deeplinks: Deeplinks,
    private readonly tabsService: TabsService,
    private readonly ngZone: NgZone,
    private readonly cacheService: CacheService,
    private readonly liveUpdateServiceFactory: LiveUpdateServiceFactory,
    private readonly appVersionService: AppVersionService
  ) {
    // platform.ready().then(() => this.initOneSignal());
    this.initializeApp();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private async initializeApp() {
    // await this.platform.ready();
    AppInsights.startTrackEvent('AppOpen');
    if (
      !Capacitor.isNativePlatform() &&
      window.location.hostname.includes('localhost') &&
      window.location.hash.includes('state')
    ) {
      await this.authService.handleLoginCallback(window.location.href);
      await this.authService.initializeUser();
      await this.router.navigate(['benchmarking']);
    }

    this.titleService.setTitle('Sonar App');
    await this.initTranslate();
    this.initOneSignal();
    this.initFontSize();

    await this.initData();

    // Okay, so the platform is ready and our plugins are available.
    // Here you can do any higher level native things you might need.
    // authService.isLoggedIn().then(async (loggedIn) => {

    this.initDeeplinks();

    await this.setupUserAnalytics();
    AppInsights.stopTrackEvent('AppOpen');

    this.fontSizeService.fontSizeChanged$.subscribe((fontSize) => {
      this.changeFontSize(fontSize);
    });

    await this.hideSplashScreen();

    const isSupportedAppVersion =
      await this.appVersionService.isSupportedAppVersion();
    if (!isSupportedAppVersion) {
      return;
    }

    this.liveUpdateService =
      await this.liveUpdateServiceFactory.createService();
    await this.liveUpdateService.checkForUpdates();
  }

  private async hideSplashScreen() {
    if (Capacitor.isNativePlatform()) {
      setTimeout(async () => {
        await SplashScreen.hide();
      }, 500);
    }
  }

  private async initData() {
    let interval = 900000;
    if (this.appSettingsService.config.env === 'dev') {
      interval = 30000;
    }
    // Temporary fix: Need to load alerts before getting to house list
    // Did not want to put it in house list because it will get called too many times
    if (await this.authService.isAuthenticated()) {
      await Promise.all([
        this.alertService.getAlerts(),
        this.farmService.getFarms(),
      ]);
    }

    combineLatest([
      this.userSettingsService.$userInfo,
      this.userSettingsService.$settings,
    ])
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(
          ([userInfo, settings]) =>
            (!_.isNil(userInfo) || !_.isNil(settings)) &&
            (!_.isEqual(this.userInfo, userInfo) ||
              !_.isEqual(this.settings, settings))
        )
      )
      .subscribe(async ([userInfo, settings]) => {
        if (
          !!userInfo &&
          !!this.userInfo &&
          userInfo.sonarCompaniesId !== this.userInfo?.sonarCompaniesId
        ) {
          this.cacheService.clear();
          this.farmService.clear();
          this.alertService.clear();

          await this.farmService.refreshFarms();
        } else if (
          !!userInfo &&
          !!this.userInfo &&
          userInfo.userRole !== this.userInfo?.userRole
        ) {
          this.farmService.refreshFarms();
        }

        this.userInfo = userInfo;
        this.settings = settings;
        await this.alertService.refreshAlerts();
      });

    setInterval(async () => {
      const canRefresh = await this.authService.isAuthenticated();
      if (canRefresh) {
        await Promise.all([
          this.farmService.getFarms(),
          this.alertService.getAlerts(),
        ]);
      }
    }, interval);
  }

  private initFontSize() {
    this.userSettingsService.$settings.subscribe(async (setting) => {
      if (setting && setting.labelFontSize) {
        return;
      }
      this.changeFontSize('default');
    });
  }

  private initOneSignal() {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    OneSignal.setAppId('afe70370-5d6d-4b51-8726-7e7ad832c515');

    //This can be further customized
    OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
      console.log('User accepted notifications: ' + accepted);
    });
    OneSignal.setNotificationOpenedHandler(async (n) => {
      let alertId = null;
      if (n.notification.additionalData ? ['alertId'] : false) {
        alertId = n.notification.additionalData['alertId'];
      }
      let alertRoute = '/alerts';
      if (alertId) {
        alertRoute = `/alerts/${alertId}/detail`;
      }

      await this.alertService.refreshAlerts();
      await this.router.navigate([alertRoute]);
    });
  }

  private async initTranslate() {
    // Set the default language for translation strings, and the current language.
    try {
      const userSettings = await this.userSettingsService.getUserSettings();
      if (userSettings) {
        this.translateService.setDefaultLang(userSettings.language || 'en-US');
        moment.locale(userSettings.language || 'en-US');
      } else {
        this.setDefaultLang();
      }
    } catch {
      this.setDefaultLang();
    }

    if (this.platform.is('ios')) {
      this.translateService.get(['BACK_BUTTON_TEXT']).subscribe(() => {
        //https://ionicframework.com/docs/developing/config#per-component-config
        const backButton = document.querySelector('backButtonText');
        if (!!backButton) {
          backButton.textContent = '';
        }
      });
    }
  }

  private setDefaultLang() {
    this.translateService.setDefaultLang('en-US');
    moment.locale('en-US');
  }

  private initDeeplinks() {
    if (Capacitor.isNativePlatform()) {
      this.deeplinks
        .route({
          '/farms/:farmNo': HouseListPage,
          '/login': LoginPage,
        })
        .subscribe(
          async (match) => {
            console.log('Successfully matched route');
            // match.$route - the route we matched, which is the matched entry from the arguments to route()
            // match.$args - the args passed in the link
            // match.$link - the full link data
            if (match.$link.path === '/farms/:farmNo') {
            }
          },
          (nomatch) => {
            // nomatch.$link - the full link data
            console.error('Unhandled deeplink', nomatch);
          }
        );
    }
  }

  private changeFontSize(fontSize: string) {
    const classes = document.body.classList;
    if (classes.length) {
      Array.from(classes).forEach((c) =>
        this.renderer.removeClass(document.body, c)
      );
    }
    switch (fontSize) {
      case 'default':
        break;
      case 'medium':
        this.renderer.addClass(document.body, 'kpiLabel_medium');
        break;
      case 'large':
        this.renderer.addClass(document.body, 'kpiLabel_large');
        break;
    }
  }

  private async setupUserAnalytics() {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(async (event: NavigationEnd) => {
        if (!event.url.includes('login')) {
          this.tabsService.toggleTabs(true);
        }
        const user = await this.userSettingsService.getUserInfo();
        const settings = await this.userSettingsService.getUserSettings();
        if (!user || !settings) {
          return;
        }
        this.ngZone.runOutsideAngular(() => {
          pendo.initialize({
            visitor: {
              id: user.azureId, // Required if user is logged in
              email: user.email, // Recommended if using Pendo Feedback, or NPS Email
              // eslint-disable-next-line @typescript-eslint/naming-convention
              full_name: user.fullName, // Recommended if using Pendo Feedback
              role: user.userRole, // Optional
              language: settings.language,
            },

            account: {
              id: user.companyName,
            },
          });
        });
      });
  }
}
