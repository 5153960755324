import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { SonarUserRole } from '@sonar/shared/sonar-user-role';
import * as _ from 'lodash';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

import { DataServiceFactory } from '../data-service.factory';
import { DataService } from '../data.service';
import { MaxCacheAge } from '../max-cache-age';
import { UserInfo } from './user-info';
import { UserSetting } from './user-settings';

@Injectable()
export class UserSettingsService {
  readonly settings = new BehaviorSubject<UserSetting>(null);
  readonly userInfo = new BehaviorSubject<UserInfo>(null);
  readonly userSettingsDataService: DataService;
  readonly userInfoDataService: DataService;
  $settings = this.settings.asObservable();
  $userInfo = this.userInfo.asObservable();

  constructor(
    private readonly dataServiceFactory: DataServiceFactory,
    private readonly storage: Storage,
  ) {
    this.userSettingsDataService = dataServiceFactory.create({
      endPoint: 'UserSettings',
    });
    this.userInfoDataService = this.dataServiceFactory.create({
      endPoint: 'UserInfo',
    });
  }

  clearUser() {
    this.settings.next(null);
    this.userInfo.next(null);
  }

  async loadUser() {
    await Promise.all([this.loadUserSettings(), this.loadUserInfo()]);
  }

  async getUserInfo(): Promise<UserInfo> {
    const userInfo = this.userInfo.getValue();

    if (_.isNil(userInfo) || !userInfo?.userId) {
      await this.loadUserInfo();
      return this.userInfo.getValue();
    }

    return userInfo;
  }

  async getUserSettings(): Promise<UserSetting> {
    const settings = this.settings.getValue();
    if (_.isNil(settings)) {
      await this.loadUserSettings();
      return this.settings.getValue();
    }

    return settings;
  }

  async updateUserSetting(newSettings: any) {
    let currentSettings = this.settings.value;
    if (currentSettings) {
      currentSettings.language = newSettings.language;
      currentSettings.measurementSystem = newSettings.measurementSystem;
      currentSettings.sonarSpeciesType = newSettings.sonarSpeciesType;
    } else {
      currentSettings = newSettings;
    }
    this.settings.next(currentSettings);
    await lastValueFrom(
      this.userSettingsDataService.saveEntity(currentSettings),
    );
  }

  async updateUserRole(userRole: SonarUserRole) {
    const userInfo = await this.userInfoDataService
      .saveValue<UserInfo>('UpdateUserRole', userRole)
      .toPromise();
    if (userInfo) {
      this.userInfo.next(userInfo);
    }
  }

  async save(userSettingKey: string, userSettings: any) {
    await this.userSettingsDataService
      .saveValue('UserSettingsAppSave', {
        key: userSettingKey,
        value: userSettings,
        type: 0,
      })
      .toPromise();

    await this.loadUserSettings();
  }

  async updateCompany(companyId: number) {
    const userInfo = await this.userInfoDataService
      .saveValue<UserInfo>('UpdateCompany', companyId)
      .toPromise();
    if (userInfo) {
      this.userInfo.next(userInfo);
    }

    await this.storage.set('companyId', companyId);
  }

  private async loadUserSettings(): Promise<void> {
    const setting = await this.userSettingsDataService
      .getEntity<UserSetting>()
      .toPromise();
    if (setting) {
      this.settings.next({
        language: setting.language,
        measurementSystem: setting.measurementSystem,
        houseCardSensorsOrder: setting.houseCardSensorsOrder,
        labelFontSize: setting.labelFontSize,
        readAlerts: setting.readAlerts ?? [],
        eventFilters: setting.eventFilters,
        benchmarkingType: setting.benchmarkingType,
        farmSort: setting.farmSort,
        houseView: setting.houseView,
        sonarSpeciesType: setting.sonarSpeciesType,
        sensorHistoryListOrder: setting.sensorHistoryListOrder,
      });
    }
  }

  private async loadUserInfo(): Promise<void> {
    const userInfo = await this.userInfoDataService
      .getEntity<UserInfo>()
      .toPromise();
    if (userInfo) {
      this.userInfo.next(userInfo);
      await this.storage.set('companyId', userInfo.sonarCompaniesId);
    }
  }
}
