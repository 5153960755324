import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class TabsService {
   private toggleTabsSource: Subject<boolean> = new Subject();

   constructor() {}

   toggleTabs$ = this.toggleTabsSource.asObservable();

   toggleTabs(show: boolean) {
      this.toggleTabsSource.next(show);
   }
}
