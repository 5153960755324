/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AppInsights } from 'applicationinsights-js';
import * as _ from 'lodash';
import { default as defaultEnvironmentArgs } from './default-environment-args.json';

interface Config {
  api: string;
  env: 'dev' | 'prod' | 'demo';
  instrumentationKey: string;
}

@Injectable()
export class AppSettingsService {
  private _config: Config;

  constructor(private readonly storage: Storage) {
  }

  async bootstrap() {
    await this.storage.create();
    this._config = await this.buildConfig();
    AppInsights.downloadAndSetup({
      instrumentationKey: this._config.instrumentationKey,
    });
  }

  get config() {
    return this._config;
  }

  async updateEnvironmentOnAppReload(environment: 'dev' | 'prod' | 'demo') {
    await this.storage.set('environment', environment);
  }

  private async buildConfig(): Promise<Config> {
    const env = await this.storage.get('environment') as 'dev' | 'prod' | 'demo';
    if (_.isNull(env)) {
      return this.buildConfigFromHostName();
    }

    let api: string;
    let instrumentationKey: string;
    switch (env) {
      case 'dev':
        api = 'https://apidev.mtech-systems.com/Sonar/App/api';
        instrumentationKey = 'd84d7128-aeec-4596-973a-bd3e63ad5f22';
        break;
      case 'prod':
        api = 'https://api.mtech-systems.com/Sonar/App/api';
        instrumentationKey = '0524f4a9-f685-4380-aa4e-23f4b54a0045';
        break;
      case 'demo':
        api = 'https://apidev.mtech-systems.com/Sonar/App/Demo/api';
        instrumentationKey = '0524f4a9-f685-4380-aa4e-23f4b54a0045';
        break;
      default:
        return this.buildConfigFromHostName();
    }

    return {
      api,
      env,
      instrumentationKey,
    };
  }

  private buildConfigFromHostName(): Config {
    let env: 'dev' | 'prod' | 'demo' = defaultEnvironmentArgs.Environment as ('dev' | 'prod' | 'demo');
    let api = defaultEnvironmentArgs.API;
    let instrumentationKey: string;
    switch (window.location.hostname) {
      case 'sonarsales.mtech-systems.com':
        env = 'dev';
        api = 'https://api.mtech-systems.com/Sonar/App/api';
        instrumentationKey = '0524f4a9-f685-4380-aa4e-23f4b54a0045';
        break;
      case 'sonardemo.mtech-systems.com':
        env = 'demo';
        api = 'https://apidev.mtech-systems.com/Sonar/App/Demo/api';
        instrumentationKey = '0524f4a9-f685-4380-aa4e-23f4b54a0045';
        break;
      case 'sonardev.mtech-systems.com':
        env = 'dev';
        api = 'https://apidev.mtech-systems.com/Sonar/App/api';
        instrumentationKey = 'd84d7128-aeec-4596-973a-bd3e63ad5f22';
        break;
      case 'localhost':
        instrumentationKey = 'd84d7128-aeec-4596-973a-bd3e63ad5f22';
        break;
    }

    return {
      api,
      env,
      instrumentationKey,
    };
  }
}
