import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ShellRoutingModule } from './shell-routing.module';
import { TabsPage } from './tabs/tabs.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    RouterModule,
    ShellRoutingModule,
  ],
  declarations: [TabsPage],
  exports: [TabsPage],
})
export class ShellModule {}
