<ion-header class="navigation" *ngIf="returnUrl">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        data-pendoid="event-detail-back"
        [defaultHref]="returnUrl"
      ></ion-back-button>
    </ion-buttons>
    <ion-title>{{ "EVENT_DETAILS" | translate }}</ion-title>
    <ion-buttons slot="end">
      <div class="subtitle body2">
        {{ event?.formattedTimestamp | date : "longDate" }}
      </div>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="container">
  <ion-list *ngIf="event">
    <ion-item>
      <ion-label position="floating">
        {{ "EVENT_TYPE" | translate }}
      </ion-label>
      <ion-input
        [disabled]="!event.eventName"
        [value]="event.eventName | translate"
        readonly
      ></ion-input>
    </ion-item>
    <ion-item *ngIf="showFarm">
      <ion-label position="floating">
        {{ "FARM_NAME" | translate }}
      </ion-label>
      <ion-input
        [disabled]="!event.farmName"
        [value]="event.farmName"
        readonly
      ></ion-input>
    </ion-item>
    <ion-item *ngIf="isFeedEvent && !isUpcomingFeedEvent">
      <ion-label position="floating">
        {{ "TRANS_CODE" | translate }}
      </ion-label>
      <ion-input
        [disabled]="!event.feedTransCodeLabel"
        [value]="event.feedTransCodeLabel | translate"
        readonly
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="floating">
        {{ "HOUSE" | translate }}
      </ion-label>
      <ion-input
        [disabled]="!event?.houseNo"
        [value]="event?.houseNo"
        readonly
      ></ion-input>
    </ion-item>
    <ion-item *ngIf="isFeedEvent">
      <ion-label position="floating">
        {{ "BIN_NAME" | translate }}
      </ion-label>
      <ion-input
        [disabled]="!event?.binName"
        [value]="event?.binName"
        readonly
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="floating">
        {{ "TRANSACTION_TIME" | translate }}
      </ion-label>
      <ion-input
        [disabled]="!event?.formattedTimestamp"
        [value]="event?.formattedTimestamp | date : 'shortTime'"
        readonly
      ></ion-input>
    </ion-item>
    <ng-container *ngIf="isPickupEvent">
      <ion-item>
        <ion-label position="floating">
          {{ "REF_NO" | translate }}
        </ion-label>
        <ion-input
          [disabled]="!event?.refNo"
          [value]="event?.refNo"
          readonly
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">
          {{ "TRAILER_NO" | translate }}
        </ion-label>
        <ion-input
          [disabled]="!event?.trailerNo"
          [value]="event?.trailerNo"
          readonly
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">
          {{ "HEAD_COUNT" | translate }}
        </ion-label>
        <ion-input
          [disabled]="!event?.headCount"
          [value]="
            (event?.headCount | number : '1.0-0') + ' ' + ('BIRDS' | translate)
          "
          readonly
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">
          {{ "NET_WEIGHT" | translate }}
        </ion-label>
        <ion-input
          [disabled]="!event?.totalWeight"
          [value]="
            event?.totalWeight | weight : weightSensorType : '1.2-2' : true
          "
          readonly
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">
          {{ "GROSS_WEIGHT" | translate }}
        </ion-label>
        <ion-input
          [disabled]="!event?.grossWeight"
          [value]="
            event?.grossWeight | weight : weightSensorType : '1.2-2' : true
          "
          readonly
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">
          {{ "TARE_WEIGHT" | translate }}
        </ion-label>
        <ion-input
          [disabled]="!event?.tareWeight"
          [value]="
            event?.tareWeight | weight : weightSensorType : '1.2-2' : true
          "
          readonly
        ></ion-input>
      </ion-item>
    </ng-container>
    <ng-container *ngIf="isUpcomingPlannedPickupEvent">
      <ion-item>
        <ion-label position="floating">
          {{ "HEAD_COUNT" | translate }}
        </ion-label>
        <ion-input
          [disabled]="!event?.headCount"
          [value]="event?.headCount"
          readonly
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">
          {{ "PROJECTED_AVERAGE_WEIGHT" | translate }}
        </ion-label>
        <ion-input
          [disabled]="!event?.projectedAverageWeight"
          [value]="
            event?.projectedAverageWeight
              | weight : weightSensorType : '1.2-2' : true
          "
          readonly
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">
          {{ "CATCH_DATE_TIME" | translate }}
        </ion-label>
        <ion-input
          [disabled]="!event?.formattedCatchDate"
          [value]="event?.formattedCatchDate | date : 'short'"
          readonly
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">
          {{ "DATE_FEEDERS_RAISED" | translate }}
        </ion-label>
        <ion-input
          [disabled]="!event?.formattedDateFeedersRaised"
          [value]="event?.formattedDateFeedersRaised | date : 'short'"
          readonly
        ></ion-input> </ion-item
    ></ng-container>
    <ion-item *ngIf="isFeedEvent">
      <ion-label position="floating">
        {{ "LOAD" | translate }}
      </ion-label>
      <ion-input
        [disabled]="!event?.refNo"
        [value]="event?.refNo"
        readonly
      ></ion-input>
    </ion-item>
    <ion-item
      *ngIf="
        !isUpcomingPlannedPickupEvent &&
        !isPickupEvent &&
        !isActualPlacementEvent &&
        (!isFeedEvent || isUpcomingFeedEvent)
      "
    >
      <ion-label position="floating">
        {{ event?.eventName | translate }}
      </ion-label>
      <ion-input
        [disabled]="!event?.kpi"
        [value]="event?.kpi"
        readonly
      ></ion-input>
    </ion-item>
    <ion-item *ngIf="isFeedEvent && !isUpcomingFeedEvent">
      <ion-label position="floating">
        {{ "BIN_CARD.AMOUNT" | translate }}
      </ion-label>
      <ion-input
        [disabled]="!event?.kpi"
        [value]="event?.kpi"
        readonly
      ></ion-input>
    </ion-item>
    <ion-item *ngIf="isFeedEvent">
      <ion-label position="floating">
        {{ "EVENTS.FEED_TYPE" | translate }}
      </ion-label>
      <ion-input
        [disabled]="!event?.feedType"
        [value]="event?.feedType"
        readonly
      ></ion-input>
    </ion-item>
    <ion-item *ngIf="isFeedEvent">
      <ion-label position="floating">
        {{ "DRIVER_NAME" | translate }}
      </ion-label>
      <ion-input
        [disabled]="!event?.driverName"
        [value]="event?.driverName"
        readonly
      ></ion-input>
    </ion-item>
    <ion-item *ngIf="isUpcomingFeedEvent">
      <ion-label position="floating">
        {{ "LOADED" | translate }}
      </ion-label>
      <ion-input
        [value]="event?.loadedFlag.toString().toUpperCase() | translate"
        readonly
      ></ion-input>
    </ion-item>
    <ion-item *ngIf="isUpcomingFeedEvent">
      <ion-label position="floating">
        {{ "BEGIN_LOAD_TIME" | translate }}
      </ion-label>
      <ion-input
        [disabled]="!event?.beginLoadTime"
        [value]="event?.beginLoadTime | date : 'shortTime'"
        readonly
      ></ion-input>
    </ion-item>
    <ion-item *ngIf="isUpcomingFeedEvent">
      <ion-label position="floating">
        {{ "END_LOAD_TIME" | translate }}
      </ion-label>
      <ion-input
        [disabled]="!event?.endLoadTime"
        [value]="event?.endLoadTime | date : 'shortTime'"
        readonly
      ></ion-input>
    </ion-item>
    <ion-item *ngIf="isUpcomingFeedEvent">
      <ion-label position="floating">
        {{ "NOTES" | translate }}
      </ion-label>
      <ion-textarea
        [disabled]="!event?.notes"
        [value]="event?.notes"
        readonly
      ></ion-textarea>
    </ion-item>

    <ng-container *ngIf="isActualPlacementEvent">
      <ion-item>
        <ion-label position="floating">
          {{ "HATCHERY_NAME" | translate }}
        </ion-label>
        <ion-input
          [disabled]="!event?.hatcheryName"
          [value]="event?.hatcheryName"
          readonly
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">
          {{ "HEAD_PLACED" | translate }}
        </ion-label>
        <ion-input
          [disabled]="!event?.kpi"
          [value]="event?.kpi"
          readonly
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">
          {{ "DRIVER_NAME" | translate }}
        </ion-label>
        <ion-input
          [disabled]="!event?.driverName"
          [value]="event?.driverName"
          readonly
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">
          {{ "ARRIVAL_TIME" | translate }}
        </ion-label>
        <ion-input
          [disabled]="!event?.formattedArrivalTime"
          [value]="event?.formattedArrivalTime | date : 'shortTime'"
          readonly
        ></ion-input>
      </ion-item>
    </ng-container>
  </ion-list>

  <div *ngIf="event?.comments" class="comments">
    <div class="comments__header">
      <span class="body2">{{ "COMMENTS" | translate }}</span>
      <span class="caption time">{{
        event?.formattedTimestamp | date : "shortTime"
      }}</span>
    </div>
    <span class="comments__content caption">
      {{ event?.comments }}
    </span>
  </div>
</ion-content>
