/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device } from '@ionic-enterprise/identity-vault';
import { sonarAppVersion } from '@sonar/app.module';
import { TrackJS } from 'trackjs';

import { SonarUserRole } from '../shared/sonar-user-role';
import { User } from '../shared/user';
import { AppSettingsService } from './app-settings.service';
import { LaunchDarklyService } from './launch-darkly.service';
import { UserInfo } from './user-settings/user-info';
import { UserSettingsService } from './user-settings/user-settings.service';
import { lastValueFrom } from 'rxjs';

// eslint-disable-next-line no-var

@Injectable()
export class BootstrapperService {
  constructor(
    private readonly appSettingsService: AppSettingsService,
    private readonly launchDarklyService: LaunchDarklyService,
    private readonly userSettingsService: UserSettingsService
  ) {}

  async bootstrap() {
    let appVersion = `${sonarAppVersion}-web`;
    if (Capacitor.isNativePlatform()) {
      const appInfo = await App.getInfo();
      appVersion = appInfo.version;
    }
    TrackJS.addMetadata('appVersion', appVersion);
    await this.appSettingsService.bootstrap();
    const config = this.appSettingsService.config;
    TrackJS.addMetadata('environment', config.env);
    TrackJS.addMetadata('api', config.api);
    if (Capacitor.getPlatform() === 'ios') {
      TrackJS.addMetadata('cordovaPlatform', 'ios');
    } else if (Capacitor.getPlatform() === 'android') {
      TrackJS.addMetadata('cordovaPlatform', 'android');
    } else {
      TrackJS.addMetadata('cordovaPlatform', 'browser');
    }

    if (Capacitor.isNativePlatform()) {
      TrackJS.addMetadata(
        'isSystemPasscodeSet',
        (await Device.isSystemPasscodeSet()) ? 'true' : 'false'
      );
      TrackJS.addMetadata(
        'isBiometricsSupported',
        (await Device.isBiometricsSupported()) ? 'true' : 'false'
      );
      TrackJS.addMetadata(
        'isBiometricsEnabled',
        (await Device.isBiometricsEnabled()) ? 'true' : 'false'
      );
    }
    const trackJsUser = 'anonymous';
    TrackJS.configure({
      userId: trackJsUser,
      onError: () => true,
    });

    await this.userSettingsService.loadUser();
    const userInfo = await this.userSettingsService.getUserInfo();

    await this.launchDarklyService.bootstrap(config.env, userInfo);
  }

  private getUserImpl(user: UserInfo): User {
    if (!user) {
      return null;
    }

    const userRole = user.userRole;
    return {
      name: user.userName,
      companyId: user.sonarCompaniesId,
      id: user.userId,
      role: user.userRole,
      isAdmin:
        userRole === SonarUserRole.Admin || userRole === SonarUserRole.Support,
      isGrower:
        userRole === SonarUserRole.Grower ||
        userRole === SonarUserRole.IndependentGrower,
      integrationOption: user.integrationOption,
    };
  }
}
