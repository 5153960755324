<ion-header class="navigation">
  <ion-toolbar color="secondary">
    <ion-buttons slot="start">
      <ion-back-button
        data-pendoid="house-list-back-button"
        data-testid="house-list-back-button"
        [defaultHref]="defaultBackUrl"
      ></ion-back-button>
    </ion-buttons>
    <ion-title data-pendoid="house-list-title" data-testid="house-list-title">
      {{ farm?.farmName }}
    </ion-title>
    <div
      data-pendoid="house-list-subtitle"
      data-testid="house-list-subtitle"
      class="navigation__subtitle"
    >
      {{ farm?.farmNo }}
    </div>
    <ion-buttons slot="end">
      <ion-button
        *ngIf="showFlockComparisonInfo"
        class="navigation__icon"
        (click)="showFlockComparisionInformation()"
      >
        <i class="ami ami-info ami-xl"></i>
      </ion-button>
      <ng-container *ngIf="navTabs === 'houses' && menuOptions?.length">
        <ion-button
          class="navigation__icon"
          data-pendoid="house-list-menu-button"
          data-testid="house-list-menu-button"
          (click)="openMenuOptions()"
        >
          <i class="ami ami-more ami-xl"></i>
        </ion-button>
      </ng-container>
      <ng-container
        *ngIf="navTabs === 'events' && (events?.length || pastEvents?.length)"
      >
        <ion-button
          class="navigation__icon"
          [class.filters-active]="eventFilters?.length"
          (click)="openMenuOptionsEvents()"
        >
          <i class="ami ami-more ami-xl"></i>
        </ion-button>
      </ng-container>
    </ion-buttons>
  </ion-toolbar>
  <ion-segment
    data-pendoid="house-list-tabs"
    data-testid="house-list-tabs"
    [value]="navTabs"
    (ionChange)="onNavTabsChange($event)"
  >
    <ion-segment-button
      data-pendoid="house-list-house-tab"
      data-testid="house-list-house-tab"
      value="houses"
    >
      {{ 'FARM_DETAIL_PAGE.SEGMENTS.HOUSES' | translate }}
    </ion-segment-button>
    <ion-segment-button
      *ngIf="eventsEnabled"
      data-pendoid="house-list-events-tab"
      data-testid="house-list-events-tab"
      value="events"
    >
      {{ 'FARM_DETAIL_PAGE.SEGMENTS.EVENTS' | translate }}
    </ion-segment-button>
    <ion-segment-button
      data-pendoid="house-list-analytics-tab"
      data-testid="house-list-analytics-tab"
      value="analytics"
    >
      {{ 'FARM_DETAIL_PAGE.SEGMENTS.ANALYTICS' | translate }}
    </ion-segment-button>
    <ion-segment-button
      data-pendoid="house-list-alerts-tab"
      data-testid="house-list-alerts-tab"
      value="alerts"
    >
      {{ 'FARM_DETAIL_PAGE.SEGMENTS.ALERTS' | translate }}
    </ion-segment-button>
  </ion-segment>
</ion-header>
<ion-content>
  <ion-refresher
    data-pendoid="house-list-refresh-animation"
    data-testid="house-list-refresh-animation"
    pullMin="180"
    slot="fixed"
    [enabled]="navTabs !== 'alerts'"
    (ionRefresh)="refresh($event)"
  >
    <ion-refresher-content
      pullingIcon="arrow-down"
      pullingText="{{ 'PULL_TO_REFRESH' | translate }}"
      refreshingSpinner="circles"
      refreshingText="{{ 'REFRESHING' | translate }}"
    ></ion-refresher-content>
  </ion-refresher>
  <ng-container [ngSwitch]="navTabs">
    <ng-container *ngSwitchCase="'houses'">
      <ng-container *ngIf="!housesWithPens && houseDetails">
        <ion-segment
          class="tabs-detail"
          data-pendoid="house-list-card-format-buttons"
          data-testid="house-list-card-format-buttons"
          [value]="houseTabs"
          (ionChange)="onHousesTabsChange($event)"
        >
          <div class="tabs-wrapper">
            <ion-segment-button
              data-pendoid="house-list-slide-card-format-button"
              data-testid="house-list-slide-card-format-button"
              value="slide"
            >
              <div class="tabs-title">
                <i class="ami-big-cards"></i>
              </div>
            </ion-segment-button>
            <ion-segment-button
              data-pendoid="house-list-grid-card-format-button"
              data-testid="house-list-grid-card-format-button"
              value="grid"
            >
              <div class="tabs-title">
                <i class="ami-small-cards"></i>
              </div>
            </ion-segment-button>
          </div>
        </ion-segment>
        <ng-container [ngSwitch]="houseTabs">
          <ng-container *ngSwitchCase="'slide'">
            <mt-swiper
              [enablePagination]="true"
              [initialSlide]="houseClicked"
              (slideClicked)="onSlideClicked($event)"
            >
              <ng-container
                *ngFor="let houseDetail of houseDetails; let i = index;"
              >
                <mt-swiper-slide>
                  <house-card
                    data-pendoid="house-list-slide-card"
                    data-testid="house-list-slide-card"
                    [age]="houseDetail.age"
                    [alertCount]="currentAlertFarmsHouse[i]"
                    [houseDetail]="houseDetail"
                    [houseNo]="houseDetail.houseNo"
                    [isSharedBin]="houseDetail.hasSharedFeedBins"
                    [sensorCount]="sensorCount"
                    [showSexedBirdWeights]="houseDetail.showSexedBirdWeight"
                    [viewMode]="houseTabs"
                  ></house-card>
                </mt-swiper-slide>
              </ng-container>
            </mt-swiper>
          </ng-container>
          <ng-container *ngSwitchCase="'grid'">
            <div class="house-grid">
              <ng-container
                *ngFor="let houseDetail of houseDetails; let i = index;"
              >
                <house-card
                  data-pendoid="house-list-grid-card"
                  data-testid="house-list-grid-card"
                  [age]="houseDetail.age"
                  [alertCount]="currentAlertFarmsHouse[i]"
                  [birdInventory]="houseDetail.birdInventory"
                  [farmType]="houseDetail.farmType"
                  [houseDetail]="houseDetail"
                  [houseNo]="houseDetail.houseNo"
                  [isSharedBin]="houseDetail.hasSharedFeedBins"
                  [sensorCount]="sensorCount"
                  [showSexedBirdWeights]="houseDetail.showSexedBirdWeight"
                  [timestamp]="houseDetail.timestamp"
                  [viewMode]="houseTabs"
                  (houseClick)="openSensorsListPage(i)"
                ></house-card>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="housesWithPens">
        <ion-grid class="pen__level">
          <ion-row class="ion-no-padding">
            <ion-col
              *ngFor="let house of houseDetails; let i = index"
              class="ion-no-padding"
              size="6"
            >
              <div class="pen__level-house" (click)="openSensorsListPage(i)">
                <div class="house__age subhead">
                  {{ 'KPIS.AGE' | translate }} {{ house?.age }}
                </div>
                <div class="house__kpi">
                  <div class="house__kpi-icon">
                    <i class="ami ami-mortality ami-xl"></i>
                  </div>
                  <span class="house__kpi-value subtitle">
                    {{ house?.livability > 0 ? house?.livability : '-' }} {{
                    house?.livability > 0 ? '%' : '' }}
                  </span>
                </div>
                <div class="house__details">
                  <span class="subtitle">
                    {{ 'HOUSE' | translate }} {{ house?.houseNo }}
                  </span>
                  <div
                    class="house__details-alerts has-alerts button"
                    [ngClass]="currentAlertFarmsHouse[i] > 0 ? 'has-alerts' : 'no-alerts'"
                  >
                    {{ currentAlertFarmsHouse[i] }}
                  </div>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'events'">
      <event-timeline
        [filters]="eventFilters"
        [hasMoreEvents]="hasMoreEvents"
        [loaded]="loaded"
        [pastEvents]="pastEvents"
        [upcomingEvents]="events"
        (eventSelected)="onSelectEvent($event)"
        (loadMoreEventsClicked)="loadMoreEvents()"
      ></event-timeline>
    </ng-container>
    <ng-container *ngSwitchCase="'analytics'">
      <farm-analytics
        data-pendoid="house-list-analytics"
        data-testid="house-list-analytics"
        [binsDataSource]="binsDataSource"
        [farmAnalytics]="farmAnalytics"
        [flockStatisticsComparison]="flockStatisticsComparison"
        [isBusy]="isBusyFarmAnalytics"
        [loaded]="loaded"
        (flockSelectionChanged)="onFlockSelectionChanged($event)"
      ></farm-analytics>
    </ng-container>
    <ng-container *ngSwitchCase="'alerts'">
      <ion-grid *ngIf="!currentFarmAlerts?.length" class="empty-state">
        <div
          data-pendoid="house-list-alerts-empty"
          data-testid="house-list-alerts-empty"
          class="empty-state__wrapper"
          text-center
        >
          <img
            class="empty-state__image"
            src="assets/sonar/empty-states/sonar-no-alerts.png"
          />
          <div class="empty-state__title">
            {{ 'ALERTS_LIST_PAGE.ALL_GOOD' | translate }}
          </div>
          <p class="empty-state__message">
            {{ 'ALERTS_LIST_PAGE.NO_ALERTS' | translate }}
          </p>
        </div>
      </ion-grid>
      <div *ngIf="currentFarmAlerts?.length" class="sensors__alerts">
        <alert-timeline
          [dataSource]="currentFarmAlerts"
          [loaded]="loaded"
          (alertSelected)="onAlertSelected($event)"
        ></alert-timeline>
      </div>
    </ng-container>
  </ng-container>
</ion-content>
<interactive-full-screen-chart
  data-pendoid="house-list-analytics-full-screen-chart"
  data-testid="house-list-analytics-full-screen-chart"
  [cardChartArgs]="farmAnalyticsComponent?.fullScreenArgs"
  [cardChartMetadata]="farmAnalyticsComponent?.fullScreenMetadata"
  [chartSettings]="farmAnalyticsComponent?.fullScreenSettings"
  [loaded]="farmAnalyticsComponent?.fullScreenLoaded"
  [showFullScreen]="farmAnalyticsComponent?.showFullScreen"
  (closed)="farmAnalyticsComponent?.onFullScreenClosed()"
></interactive-full-screen-chart>
