/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/member-ordering */
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KPIFormattedType } from '@sonar/core/kpis/kpi-formatted-type';
import { KPIFormattedService } from '@sonar/core/kpis/kpi-formatted.service';
import { WeightPipe } from '@sonar/core/pipes/weight.pipe';
import { UnitConversionService } from '@sonar/core/unit-conversion.service';
import { UserSettingsService } from '@sonar/core/user-settings/user-settings.service';
import { getRootValueVariable } from '@sonar/core/utils';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { KPI } from '../cards/kpi';
import { KPIThreshold } from '../cards/kpi-threshold';
import { CardChartArgs } from '../cards/mt-card-chart/card-chart-args';
import { CardChartMetadata } from '../cards/mt-card-chart/card-chart-metadata';
import { CardTableColumnArgs } from '../cards/mt-card-table/card-table-column-args';
import { ClientDataType } from '../cards/mt-card-table/client-data-type';
import { ColumnArgsTable2 } from '../cards/mt-card-table2/mt-card-table2.component';
import { FarmType } from '../farm/farm-type';
import { MeasurementSystem } from '../measurement-systems';
import { SensorType, SonarDataType } from '../sensor-type';
import { StatisticType, StatisticTypeModel } from '../statistic-type';
import { AnalyticsFlockSelection } from './analytics-flock-selection';
import { FarmAnalytics } from './farm-analytics';
import { WeeklyFlockPerformance } from './weekly-flock-performance';
import { ChartSettings } from '../interactive-charts/interactive-chart-base/chart-settings';
import { FeatureFlags } from '../feature-flags';
import { LaunchDarklyService } from '@sonar/core/launch-darkly.service';
import { Router } from '@angular/router';

@Component({
  selector: 'farm-analytics',
  templateUrl: './farm-analytics.component.html',
  providers: [KPIFormattedService],
  styleUrls: ['./farm-analytics.component.scss'],
  animations: [
    trigger('enterTrigger', [
      state(
        'fadeIn',
        style({
          opacity: '1',
        }),
      ),
      transition('void => *', [style({ opacity: '0' }), animate('.35s')]),
    ]),
  ],
})
export class FarmAnalyticsComponent implements OnChanges, OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject();

  private flockStatisticComparison = [];
  private kpiStatus: KPIThreshold;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly kpiFormatted: KPIFormattedService,
    private readonly translateService: TranslateService,
    private readonly unitConversionService: UnitConversionService,
    private readonly userSettingsService: UserSettingsService,
    private readonly router: Router,
    private readonly launchDarklyService: LaunchDarklyService,
  ) {
    this.weightPipe = new WeightPipe(
      userSettingsService,
      unitConversionService,
      translateService,
    );
  }

  @Input() binsDataSource: any[];
  @Input() farmAnalytics: FarmAnalytics;
  @Input() flockStatisticsComparison: any;
  @Input() isBusy: boolean;
  @Input() loaded: boolean;

  @Output() flockSelectionChanged: EventEmitter<AnalyticsFlockSelection> =
    new EventEmitter<AnalyticsFlockSelection>();

  tab: AnalyticsFlockSelection = AnalyticsFlockSelection.Current;
  flockComparisonChartArgs: CardChartArgs;
  flockComparisonChartSettings: ChartSettings;
  loadedFlockComparisonChart: boolean;
  flockSelection = AnalyticsFlockSelection;
  flockStatisticType: StatisticType = StatisticType.PercentDepletion;
  status: { value: number; color: string }[];
  statusBar: {
    value: number;
    maxValue: number;
    steps: { maxValue: number; prevValues: number; color: string }[];
  };
  sensorType = SensorType;
  fcr: any;
  flockPerformanceTableData: FlockPerformanceTableData[];
  formattedLastUpdatedDate: string;
  flockPerformanceArgs: CardTableColumnArgs[] = [
    {
      caption: 'FARM_ANALYTICS.CATEGORY',
      propertyName: 'category',
      clientDataType: ClientDataType.Number,
    },
    {
      caption: 'FARM_ANALYTICS.ACTUAL',
      propertyName: 'actual',
      clientDataType: ClientDataType.Number,
    },
    {
      caption: 'FARM_ANALYTICS.COMPANY_AVERAGE',
      propertyName: 'average',
      clientDataType: ClientDataType.Number,
    },
    {
      caption: 'FARM_ANALYTICS.PAST_FLOCK_AVERAGE',
      propertyName: 'averagePastFlock',
      clientDataType: ClientDataType.Number,
    },
  ];
  weeklyFlockPerformanceArgs: CardTableColumnArgs[] = [
    {
      caption: 'FARM_ANALYTICS.WEEK',
      propertyName: 'week',
      clientDataType: ClientDataType.Number,
    },
    {
      caption: 'FARM_ANALYTICS.AVERAGE_WEIGHT',
      propertyName: 'avgWeight',
      clientDataType: ClientDataType.Number,
    },
    {
      caption: 'FARM_ANALYTICS.PERCENT_DEPLETION',
      propertyName: 'percentDepletion',
      clientDataType: ClientDataType.Number,
    },
    {
      caption: 'FARM_ANALYTICS.FEED_CONSUMED_PER_BIRD',
      propertyName: 'feedConsumedPerBird',
      clientDataType: ClientDataType.Number,
    },
  ];
  weeklyFlockPerformance: WeeklyFlockPerformance[];
  weightPipe;
  benchmarkingScoreColumns: CardTableColumnArgs[] = [
    {
      caption: 'FARM_ANALYTICS.COMPANY_AVERAGE',
      propertyName: 'companyAverage',
      clientDataType: ClientDataType.Number,
    },
    {
      caption: 'FARM_ANALYTICS.PAST_FLOCK_AVERAGE',
      propertyName: 'pastFlockAverage',
      clientDataType: ClientDataType.Number,
    },
  ];
  benchmarkingScoreData = [];
  measurementSystem: MeasurementSystem;

  //Flock Comparison Variables
  showFlockComparisonInfo = false;
  flockAge: any;
  sharedBinsTableArgs: ColumnArgsTable2[] = [
    {
      clientDataType: ClientDataType.String,
      columnDef: 'houseNo',
      header: 'House No',
      cell: (element) => `${element.houseNo}`,
    },
    {
      clientDataType: ClientDataType.String,
      columnDef: 'binName',
      header: 'Bin Name',
      ignoreConvert: true,
      cell: (element) => `${element.binName}`,
    },
    {
      clientDataType: ClientDataType.Number,
      columnDef: 'inventory',
      header: 'Feed Inventory',
      cell: (element) => `${element.inventory}`,
    },
    {
      clientDataType: ClientDataType.Icon,
      columnDef: 'openBin',
      header: 'Open Bin',
      cell: (element) => `${element.openBin}`,
    },
  ];
  currentTableArgs: ColumnArgsTable2[] = [
    {
      clientDataType: ClientDataType.String,
      columnDef: 'statistic',
      header: 'FARM_ANALYTICS.STATISTIC',
      cell: (element) => `${element.statistic}`,
    },
    {
      clientDataType: ClientDataType.Number,
      columnDef: 'current',
      header: 'FARM_ANALYTICS.CURRENT',
      cell: (element) => `${element.current}`,
    },
    {
      clientDataType: ClientDataType.Number,
      columnDef: 'lastFlock',
      header: 'FARM_ANALYTICS.LAST_FLOCK',
      cell: (element) => `${element.lastFlock}`,
    },
  ];
  currentTableDataSource: any[];
  lifeOfFlockTableArgs: ColumnArgsTable2[] = [
    {
      clientDataType: ClientDataType.Number,
      columnDef: 'statistic',
      header: 'FARM_ANALYTICS.AGE',
      cell: (element) => `${element.statistic}`,
    },
    {
      clientDataType: ClientDataType.Number,
      columnDef: 'current',
      header: 'FARM_ANALYTICS.CURRENT',
      cell: (element) => `${element.current}`,
    },
    {
      clientDataType: ClientDataType.Number,
      columnDef: 'lastFlock',
      header: 'FARM_ANALYTICS.LAST_FLOCK',
      cell: (element) => `${element.lastFlock}`,
    },
  ];
  lifeOfFlockTableDataSource: any[];
  currentStatisticsDropDown: any[] = [
    {
      value: StatisticType.PercentDepletion,
      label: 'KPIS.PERCENT_DEPLETION',
    },
    {
      value: StatisticType.AverageWeight,
      label: 'KPIS.WEIGHT',
    },
    {
      value: StatisticType.WeightGain,
      label: 'KPIS.WEIGHT_GAIN',
    },
    {
      value: StatisticType.FeedPerBird,
      label: 'KPIS.FEED_PER_BIRD',
    },
    {
      value: StatisticType.FeedConversion,
      label: 'KPIS.FEED_CONVERSION',
    },
    {
      value: StatisticType.WaterConsumption,
      label: 'KPIS.WATER_CONSUMPTION_BIRD',
    },
    {
      value: StatisticType.Temperature,
      label: 'KPIS.TEMPERATURE',
    },
    {
      value: StatisticType.Humidity,
      label: 'KPIS.HUMIDITY',
    },
    {
      value: StatisticType.Ammonia,
      label: 'KPIS.AMMONIA',
    },
    {
      value: StatisticType.CarbonDioxide,
      label: 'KPIS.CARBON_DIOXIDE',
    },
    {
      value: StatisticType.AirFlow,
      label: 'KPIS.AIR_FLOW',
    },
    {
      value: StatisticType.Uniformity,
      label: 'KPIS.UNIFORMITY',
    },
    {
      value: StatisticType.CV,
      label: 'KPIS.CV',
    },
  ];
  currentStatisticSelected: string = StatisticTypeModel.toLabelKey(
    StatisticType.PercentDepletion,
  );
  statisticFullScreenSelected: string = StatisticTypeModel.toStringName(
    StatisticType.PercentDepletion,
  );
  currentChartStatistic: any;
  lastChartStatistic: any;
  kpiChartStatisticsComparison: [KPI] | [KPI, KPI];
  farmTypeEnum = FarmType;
  farmAnalyticsAvailable = false;

  /** Interactive Card Chart Variables */
  /** Average Weight Card Chart Variables */
  averageWeightCardChartArgs: CardChartArgs;
  averageWeightChartLoaded = false;
  averageWeightChartSettings: ChartSettings;
  /** Feed Consumed Card Chart Variables */
  feedConsumedPerBirdCardChartArgs: CardChartArgs;
  feedConsumedPerBirdChartLoaded = false;
  feedConsumedPerBirdChartSettings: ChartSettings;
  /** Percent Depletion Variables */
  percentDepletionCardChartArgs: CardChartArgs;
  percentDepletionChartLoaded = false;
  percentDepletionChartSettings: ChartSettings;
  /** Switching Tab Display Variables */
  showAnalyticsFlockPerformanceChart = false;
  showTabLastSold = false;
  /** FullScreen Variables */
  fullScreenArgs: CardChartArgs;
  fullScreenLoaded = false;
  fullScreenMetadata: CardChartMetadata;
  fullScreenSettings: ChartSettings;
  showFullScreen = false;

  async ngOnChanges(changes: SimpleChanges) {
    //Filter dropdown for sensors with values
    if (changes.flockStatisticsComparison && !!this.flockStatisticsComparison) {
      this.currentStatisticsDropDown = this.currentStatisticsDropDown.filter(
        (stat) => {
          //Always return PercentDepletion because it can be input manually
          if (stat.value === StatisticType.PercentDepletion) {
            return true;
          }

          const hasStatisticValue =
            this.flockStatisticsComparison.statistics.some(
              (s) =>
                (s.statistic as StatisticType) === stat.value &&
                (!!s.current || !!s.previous),
            );
          if (hasStatisticValue) {
            return true;
          }

          //Statistics based on most recent age, it is possible to have chart data but no data for tha last age
          const hasChartValues =
            this.getStatisticData(
              this.flockStatisticsComparison.currentAnalyticsCharts,
              stat.value,
            ).length > 0 ||
            this.getStatisticData(
              this.flockStatisticsComparison.lastFlockAnalyticsCharts,
              stat.value,
            ).length > 0;

          return hasChartValues;
        },
      );
    }

    this.farmAnalyticsAvailable = !_.isNil(this.farmAnalytics?.entityNo);
    if (changes.farmAnalytics && !this.isBusy) {
      if (!this.farmAnalyticsAvailable) {
        //In the event that there is no active entity, load chart args to prevent odd looking blank chart
        await this.setPercentDepletionChartArgs([], null);
        await this.setAverageWeightChartArgs([], null);
        await this.setFeedConsumedChartArgs([], null);
        return;
      }

      this.formattedLastUpdatedDate = moment(
        this.farmAnalytics.timestamp,
      ).format('L');
      await this.loadPercentDepletionDataChart(this.farmAnalytics);
      await this.loadAverageWeightDataChart(this.farmAnalytics);
      await this.loadFeedConsumedDataChart(this.farmAnalytics);
      this.weeklyFlockPerformance = this.farmAnalytics.weeklyFlockPerformance
        ? this.farmAnalytics.weeklyFlockPerformance.map((f) => ({
            week: f.week,
            avgWeight: this.weightPipe.transform(
              f.avgWeight,
              this.sensorType.Weight,
              '1.2-2',
              true,
            ),
            percentDepletion: f.percentDepletion,
            feedConsumedPerBird: this.weightPipe.transform(
              f.feedConsumedPerBird,
              this.sensorType.BinFeedAmount,
              '1.2-2',
              true,
            ),
          }))
        : [];
      this.flockPerformanceTableData = [
        {
          category: await this.translateService
            .get('FARM_ANALYTICS.WEIGHT')
            .toPromise(),
          actual: this.farmAnalytics.actualWeight
            ? this.round(
                this.getValue(
                  SensorType.Weight,
                  this.farmAnalytics.actualWeight,
                ),
                this.getWeightDecimalPlaces(),
              )
            : '-',
          average: this.farmAnalytics.flockPerformance
            .companyProductBirdWeightAverage
            ? this.round(
                this.getValue(
                  SensorType.Weight,
                  this.farmAnalytics.flockPerformance
                    .companyProductBirdWeightAverage,
                ),
                this.getWeightDecimalPlaces(),
              )
            : '-',
          averagePastFlock: this.farmAnalytics.flockPerformance
            .farmProductBirdWeightAverage
            ? this.round(
                this.getValue(
                  SensorType.Weight,
                  this.farmAnalytics.flockPerformance
                    .farmProductBirdWeightAverage,
                ),
                this.getWeightDecimalPlaces(),
              )
            : '-',
        },
        {
          category: await this.translateService
            .get('FARM_ANALYTICS.PERCENT_DEPLETION')
            .toPromise(),
          actual: this.farmAnalytics.actualPercentDepletion
            ? this.round(this.farmAnalytics.actualPercentDepletion, 2)
            : '-',
          average: this.farmAnalytics.flockPerformance
            .companyProductPercentDepletionAverage
            ? this.round(
                this.farmAnalytics.flockPerformance
                  .companyProductPercentDepletionAverage,
                2,
              )
            : '-',
          averagePastFlock: this.farmAnalytics.flockPerformance
            .farmProductPercentDepletionAverage
            ? this.round(
                this.farmAnalytics.flockPerformance
                  .farmProductPercentDepletionAverage,
                2,
              )
            : '-',
        },
        {
          category: await this.translateService
            .get('FARM_ANALYTICS.FCR')
            .toPromise(),
          actual: this.farmAnalytics.actualFCR
            ? this.round(this.farmAnalytics.actualFCR, 2)
            : '-',
          average: this.farmAnalytics.flockPerformance.companyProductFCRAverage
            ? this.round(
                this.farmAnalytics.flockPerformance.companyProductFCRAverage,
                2,
              )
            : '-',
          averagePastFlock: this.farmAnalytics.flockPerformance
            .farmProductFCRAverage
            ? this.round(
                this.farmAnalytics.flockPerformance.farmProductFCRAverage,
                2,
              )
            : '-',
        },
      ];
      this.benchmarkingScoreData = [
        {
          companyAverage: this.farmAnalytics.flockPerformance
            .companyProductCostPerformance
            ? Math.round(
                this.farmAnalytics.flockPerformance
                  .companyProductCostPerformance,
              )
            : 'N/A',
          pastFlockAverage: this.farmAnalytics.flockPerformance
            .farmProductCostPerformance
            ? Math.round(
                this.farmAnalytics.flockPerformance.farmProductCostPerformance,
              )
            : 'N/A',
        },
      ];
      this.changeDetectorRef.detectChanges();
    }
    if (changes.flockStatisticsComparison && this.flockStatisticsComparison) {
      this.onChangeFlockStatistic();
    }
  }

  ngOnInit(): void {
    this.userSettingsService.$settings
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (settings) => (this.measurementSystem = settings.measurementSystem),
      );
    this.showAnalyticsFlockPerformanceChart = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onAnalyticsTabsChange(args: any): void {
    this.tab = Number(args.detail.value) as AnalyticsFlockSelection;
    this.percentDepletionChartLoaded = false;
    this.showAnalyticsFlockPerformanceChart =
      this.tab === AnalyticsFlockSelection.Current;
    this.showTabLastSold = this.tab === AnalyticsFlockSelection.LastSold;
    this.showFlockComparisonInfo =
      this.tab === AnalyticsFlockSelection.FlocksComparison;
    this.flockSelectionChanged.emit(args.detail);
  }

  private async loadPercentDepletionDataChart(farmAnalytics: any) {
    this.percentDepletionChartLoaded = false;
    let percentDepletionChartData = [];

    percentDepletionChartData = farmAnalytics?.houseAnalytics
      .filter((analytics) => !!analytics.percentDepletion.data.length)
      .map((houseAnalytics) => ({
        label: `${this.translateService.instant('HOUSE')} ${
          houseAnalytics.houseNo
        }`,
        data: houseAnalytics.percentDepletion.data.map((d) => ({
          age: d.age,
          xValue: d.age,
          yValue: d.value,
        })),
        dataType: 'data',
      }));
    const percentDepletionStandard =
      farmAnalytics?.percentDepletionStandard?.data;
    if (percentDepletionStandard?.length) {
      percentDepletionChartData.push({
        label: this.translateService.instant(
          'FARM_ANALYTICS.PERCENT_DEPLETION_STANDARD',
        ),
        data: farmAnalytics?.percentDepletionStandard
          ? farmAnalytics.percentDepletionStandard.data.map((d) => ({
              age: d.age,
              xValue: d.age,
              yValue: d.value,
            }))
          : [],
        dataType: 'max',
        color: getRootValueVariable('hx-black'),
      });
    }

    await this.setPercentDepletionChartArgs(
      percentDepletionChartData,
      farmAnalytics?.farmType,
    );
  }

  private async setPercentDepletionChartArgs(
    percentDepletionChartData: any,
    farmType: any,
  ) {
    let percentDepletionCardChartArgs = null;
    let percentDepletionChartSettings = null;

    percentDepletionCardChartArgs = {
      chartViewMode: 'lof',
      dataSource: {
        chartData: percentDepletionChartData,
        type: farmType,
      },
      mainChartType: 'line',
      sonarDataType: SonarDataType.None,
    };
    percentDepletionChartSettings = {
      axisSettings: {
        xAxis: {
          format: '{value:.0f}',
          showAxes: true,
          type: 'linear',
        },
        yAxis: {
          format: '{value:.2f}',
          showAxes: true,
          type: 'linear',
        },
      },
      isSensorChart: false,
      primaryAxisLabels: {
        xAxis: await this.translateService.instant('KPIS.AGE'),
        yAxis: await this.translateService.instant('KPIS.PERCENT_DEPLETION'),
      },
      showMarkers: true,
      showTooltips: true,
      sonarDataType: SonarDataType.None,
      title: 'FARM_ANALYTICS.PERCENT_DEPLETION',
      tooltipDecimals: 2,
    };
    this.percentDepletionChartSettings = Object.assign(
      {},
      percentDepletionChartSettings,
    );
    setTimeout(() => {
      this.percentDepletionCardChartArgs = Object.assign(
        {},
        percentDepletionCardChartArgs,
      );
    });
    this.percentDepletionChartLoaded = true;
  }

  private async loadAverageWeightDataChart(farmAnalytics: FarmAnalytics) {
    this.averageWeightChartLoaded = false;
    let averageWeightChartData = [];

    averageWeightChartData = farmAnalytics.houseAnalytics
      .filter((analytics) => !!analytics.averageWeight.data.length)
      .map((houseAnalytics) => ({
        label: `${this.translateService.instant('HOUSE')} ${
          houseAnalytics.houseNo
        }`,
        data: houseAnalytics.averageWeight.data.map((d) => ({
          age: d.age,
          xValue: d.age,
          yValue: this.getValue(SensorType.Weight, d.value),
        })),
        dataType: 'data',
      }));
    const averageWeightStandard = farmAnalytics?.averageWeightStandard?.data;
    if (averageWeightStandard?.length) {
      averageWeightChartData.push({
        label: this.translateService.instant(
          'FARM_ANALYTICS.AVG_WEIGHT_STANDARD',
        ),
        data: farmAnalytics.averageWeightStandard
          ? farmAnalytics.averageWeightStandard.data.map((d) => ({
              age: d.age,
              xValue: d.age,
              yValue: this.getValue(SensorType.Weight, d.value),
            }))
          : [],
        dataType: 'max',
        color: getRootValueVariable('hx-black'),
      });
    }

    await this.setAverageWeightChartArgs(
      averageWeightChartData,
      farmAnalytics?.farmType,
    );
  }

  private async setAverageWeightChartArgs(
    averageWeightChartData: any,
    farmType: any,
  ) {
    let averageWeightCardChartArgs = null;
    let averageWeightChartSettings = null;

    averageWeightCardChartArgs = {
      chartViewMode: 'lof',
      dataSource: {
        chartData: averageWeightChartData,
        type: farmType,
      },
      mainChartType: 'line',
      sonarDataType: SonarDataType.None,
    };
    averageWeightChartSettings = {
      axisSettings: {
        xAxis: {
          format: '{value:.0f}',
          showAxes: true,
          type: 'linear',
        },
        yAxis: {
          format: '{value:.2f}',
          showAxes: true,
          type: 'linear',
        },
      },
      isSensorChart: false,
      primaryAxisLabels: {
        xAxis: await this.translateService.instant('KPIS.AGE'),
        yAxis: await this.translateService.instant(
          'FARM_ANALYTICS.AVERAGE_WEIGHT',
        ),
      },
      showMarkers: true,
      showTooltips: true,
      sonarDataType: SonarDataType.None,
      title: 'FARM_ANALYTICS.AVERAGE_WEIGHT',
      tooltipDecimals: 2,
    };
    this.averageWeightChartSettings = Object.assign(
      {},
      averageWeightChartSettings,
    );
    setTimeout(() => {
      this.averageWeightCardChartArgs = Object.assign(
        {},
        averageWeightCardChartArgs,
      );
    });
    this.averageWeightChartLoaded = true;
  }

  private async loadFeedConsumedDataChart(farmAnalytics: FarmAnalytics) {
    this.feedConsumedPerBirdChartLoaded = false;
    let feedConsumedPerBirdChartData = [];

    feedConsumedPerBirdChartData = farmAnalytics.houseAnalytics
      .filter((analytics) => !!analytics.feedConsumedPerBird.data.length)
      .map((houseAnalytics) => ({
        label: `${this.translateService.instant('HOUSE')} ${
          houseAnalytics.houseNo
        }`,
        data: houseAnalytics.feedConsumedPerBird.data.map((d) => ({
          age: d.age,
          xValue: d.age,
          yValue: this.getValue(SensorType.BinFeedAmount, d.value),
        })),
        dataType: 'data',
      }));
    const feedConsumedPerBirdStandard =
      farmAnalytics?.feedConsumedPerBirdStandard?.data;
    if (feedConsumedPerBirdStandard?.length) {
      feedConsumedPerBirdChartData.push({
        label: this.translateService.instant(
          'FARM_ANALYTICS.FEED_CONSUMED_PER_BIRD_STANDARD',
        ),
        data: farmAnalytics.feedConsumedPerBirdStandard
          ? farmAnalytics.feedConsumedPerBirdStandard.data.map((d) => ({
              age: d.age,
              xValue: d.age,
              yValue: this.getValue(SensorType.BinFeedAmount, d.value),
            }))
          : [],
        dataType: 'max',
        color: getRootValueVariable('hx-black'),
      });
    }

    await this.setFeedConsumedChartArgs(
      feedConsumedPerBirdChartData,
      farmAnalytics?.farmType,
    );
  }

  private async setFeedConsumedChartArgs(
    feedConsumedPerBirdChartData: any,
    farmType: any,
  ) {
    let feedConsumedPerBirdCardChartArgs = null;
    let feedConsumedPerBirdChartSettings = null;

    feedConsumedPerBirdCardChartArgs = {
      chartViewMode: 'lof',
      dataSource: {
        chartData: feedConsumedPerBirdChartData,
        type: farmType,
      },
      mainChartType: 'line',
      sonarDataType: SonarDataType.None,
    };
    feedConsumedPerBirdChartSettings = {
      axisSettings: {
        xAxis: {
          format: '{value:.0f}',
          showAxes: true,
          type: 'linear',
        },
        yAxis: {
          format: '{value:.2f}',
          showAxes: true,
          type: 'linear',
        },
      },
      isSensorChart: false,
      primaryAxisLabels: {
        xAxis: await this.translateService.instant('KPIS.AGE'),
        yAxis: await this.translateService.instant(
          'FARM_ANALYTICS.FEED_CONSUMED_PER_BIRD',
        ),
      },
      showMarkers: true,
      showTooltips: true,
      sonarDataType: SonarDataType.None,
      title: 'FARM_ANALYTICS.FEED_CONSUMED_PER_BIRD',
      tooltipDecimals: 2,
    };
    this.feedConsumedPerBirdChartSettings = Object.assign(
      {},
      feedConsumedPerBirdChartSettings,
    );
    setTimeout(() => {
      this.feedConsumedPerBirdCardChartArgs = Object.assign(
        {},
        feedConsumedPerBirdCardChartArgs,
      );
    });
    this.feedConsumedPerBirdChartLoaded = true;
  }

  private round(value: number, places: number) {
    return Math.round(value * Math.pow(10, places)) / Math.pow(10, places);
  }

  getWeightPerformanceStatus(performance: number): {
    points: number;
    color: string;
  } {
    if (!performance || performance <= 60 || performance >= 131) {
      return { points: 0, color: 'red' };
    }

    const color = performance >= 81 && performance < 121 ? 'green' : 'yellow';
    return { points: Math.round((performance * 40) / 100), color };
  }

  getDepletionPerformanceStatus(performance: number): {
    points: number;
    color: string;
  } {
    if (!performance || performance >= 31) {
      return { points: 0, color: 'red' };
    }

    const color = performance >= 11 && performance < 31 ? 'yellow' : 'green';
    return { points: Math.round((performance * 30) / 100), color };
  }

  getFCRPerformanceStatus(performance: number): {
    points: number;
    color: string;
  } {
    if (!performance || performance <= 60 || performance >= 131) {
      return { points: 0, color: 'red' };
    }

    const color = performance >= 81 && performance < 121 ? 'green' : 'yellow';
    return { points: Math.round((performance * 40) / 100), color };
  }

  onShowFullScreenClicked(chart: string) {
    this.fullScreenLoaded = false;
    this.showFullScreen = true;
    switch (chart) {
      case 'depletion':
        this.fullScreenMetadata = {
          title: 'FARM_ANALYTICS.PERCENT_DEPLETION',
        };
        this.fullScreenSettings = this.percentDepletionChartSettings;
        setTimeout(
          () => (this.fullScreenArgs = this.percentDepletionCardChartArgs),
        );
        this.fullScreenLoaded = true;
        return;
      case 'averageWeight':
        this.fullScreenMetadata = {
          title: 'FARM_ANALYTICS.AVERAGE_WEIGHT',
        };
        this.fullScreenSettings = this.averageWeightChartSettings;
        setTimeout(
          () => (this.fullScreenArgs = this.averageWeightCardChartArgs),
        );
        this.fullScreenLoaded = true;
        return;
      case 'feedConsumedPerBird':
        this.fullScreenMetadata = {
          title: 'FARM_ANALYTICS.FEED_CONSUMED_PER_BIRD',
        };
        this.fullScreenSettings = this.feedConsumedPerBirdChartSettings;
        setTimeout(
          () => (this.fullScreenArgs = this.feedConsumedPerBirdCardChartArgs),
        );
        this.fullScreenLoaded = true;
        return;
      default:
        return;
    }
  }

  onFullScreenClosed() {
    this.showFullScreen = false;
    this.fullScreenArgs = null;
    this.fullScreenMetadata = null;
    this.fullScreenLoaded = false;
    this.fullScreenSettings = null;
  }

  async onChangeFlockStatistic(input?: any) {
    this.loadedFlockComparisonChart = false;
    let statistic: StatisticType;
    if (!!input) {
      statistic = Number(input.detail.value) as StatisticType;
    } else {
      statistic = StatisticType.PercentDepletion;
    }

    const currentStatisticData = this.currentStatisticData(statistic);
    const lastStatisticData = this.lastStatisticData(statistic);

    this.currentStatisticSelected = StatisticTypeModel.toLabelKey(statistic);
    this.statisticFullScreenSelected =
      StatisticTypeModel.toStringName(statistic);
    this.flockAge = currentStatisticData.length
      ? _(currentStatisticData).maxBy((f) => f.age).age
      : '-';
    this.currentTableDataSource = [];
    const currentDataSource = [];
    this.flockStatisticsComparison.statistics.forEach(async (r) => {
      const statisticTitle = await this.translateService
        .get(StatisticTypeModel.toLabelKey(r.statistic))
        .toPromise();
      currentDataSource.push({
        statistic: statisticTitle,
        current: this.customStatisticDataConversion(r.current, r.statistic),
        lastFlock: this.customStatisticDataConversion(r.lastFlock, r.statistic),
        sensorType: this.statisticToSensorTypeForTable(r.statistic),
        statisticType: r.statistic,
        showArrow: true,
        infoArrow: this.customStatisticDetailsArrow(
          r.statistic,
          r.current,
          r.lastFlock,
        ),
      });
    });
    this.kpiChartStatistics(
      statistic,
      currentStatisticData,
      lastStatisticData,
      true,
      false,
    );

    this.currentTableDataSource = currentDataSource;
    switch (statistic) {
      case StatisticType.PercentDepletion:
      case StatisticType.FeedConversion:
      case StatisticType.CV:
        this.kpiChartStatistics(
          statistic,
          currentStatisticData,
          lastStatisticData,
          true,
          true,
        );
        break;
      case StatisticType.Uniformity:
      case StatisticType.AverageWeight:
      case StatisticType.WeightGain:
      case StatisticType.FeedPerBird:
        this.kpiChartStatistics(
          statistic,
          currentStatisticData,
          lastStatisticData,
          true,
          false,
        );
        break;
      case StatisticType.WaterConsumption:
      case StatisticType.Temperature:
      case StatisticType.Humidity:
      case StatisticType.Ammonia:
      case StatisticType.CarbonDioxide:
      case StatisticType.AirFlow:
        this.kpiChartStatistics(
          statistic,
          currentStatisticData,
          lastStatisticData,
          true,
          false,
          true,
        );
        break;
    }
    switch (statistic) {
      case StatisticType.PercentDepletion:
      case StatisticType.FeedConversion:
      case StatisticType.CV:
      case StatisticType.Uniformity:
      case StatisticType.AverageWeight:
      case StatisticType.WeightGain:
      case StatisticType.FeedPerBird:
      case StatisticType.WaterConsumption:
      case StatisticType.Temperature:
      case StatisticType.Humidity:
      case StatisticType.Ammonia:
      case StatisticType.CarbonDioxide:
      case StatisticType.AirFlow:
        this.statisticsDataTable(
          currentStatisticData,
          lastStatisticData,
          statistic,
        );
        break;
    }
    this.statisticsKpiDataTable(
      currentStatisticData,
      lastStatisticData,
      statistic,
    );
    this.loadedFlockComparisonChart = true;
  }

  customStatisticDetailsArrow(
    statistic: StatisticType,
    current: any,
    lastFlock: any,
  ) {
    if (
      statistic === StatisticType.PercentDepletion ||
      statistic === StatisticType.FeedConversion ||
      statistic === StatisticType.CV
    ) {
      if (current > lastFlock) {
        return 'up red';
      } else {
        return 'down green';
      }
    } else if (
      statistic === StatisticType.AverageWeight ||
      statistic === StatisticType.WeightGain ||
      statistic === StatisticType.Uniformity
    ) {
      if (current > lastFlock) {
        return 'up green';
      } else {
        return 'down red';
      }
    } else {
      if (current > lastFlock) {
        return 'up';
      } else {
        return 'down';
      }
    }
  }

  currentStatisticData(statisticType: StatisticType) {
    if (
      !this.flockStatisticComparison ||
      !this.flockStatisticsComparison.currentAnalyticsCharts
    ) {
      return [];
    }
    return this.getStatisticData(
      this.flockStatisticsComparison.currentAnalyticsCharts,
      statisticType,
    );
  }

  lastStatisticData(statisticType: StatisticType) {
    if (
      !this.flockStatisticComparison ||
      !this.flockStatisticsComparison.lastFlockAnalyticsCharts
    ) {
      return [];
    }
    return this.getStatisticData(
      this.flockStatisticsComparison.lastFlockAnalyticsCharts,
      statisticType,
    );
  }

  async statisticsKpiDataTable(
    currentStatisticData: any,
    lastStatisticData: any,
    statisticType: StatisticType,
  ) {
    const sensorType = this.statisticToSensorTypeForTable(statisticType);
    const chartData = [];
    let currentChartStatistic = null;
    currentChartStatistic = {
      label: this.translateService.instant('COMPARISON_CHART.CURRENT'),
      data: _.orderBy(currentStatisticData, ['age'], ['asc']).map((d) => ({
        age: d.age,
        xValue: d.age,
        yValue:
          sensorType != null
            ? SensorType.convertUnit(
                sensorType,
                d.value,
                this.unitConversionService,
              )
            : d.value,
      })),
      dataType: 'data',
      color: getRootValueVariable('hx-accent-400'),
    };
    this.currentChartStatistic = Object.assign({}, currentChartStatistic);
    chartData.push(currentChartStatistic);
    let lastChartStatistic = null;
    lastChartStatistic = {
      label: await this.translateService
        .get('COMPARISON_CHART.LAST')
        .toPromise(),
      data: _.orderBy(lastStatisticData, ['age'], ['asc']).map((d) => ({
        age: d.age,
        xValue: d.age,
        yValue:
          sensorType != null
            ? SensorType.convertUnit(
                sensorType,
                d.value,
                this.unitConversionService,
              )
            : d.value,
      })),
      dataType: 'data',
      color: getRootValueVariable('hx-aux-1'),
    };
    this.lastChartStatistic = Object.assign({}, lastChartStatistic);
    chartData.push(lastChartStatistic);
    this.flockComparisonChartSettings = {
      axisSettings: {
        xAxis: {
          format: '{value:.0f}',
          showAxes: true,
          type: 'linear',
        },
        yAxis: {
          format: '{value:.2f}',
          showAxes: true,
          type: 'linear',
        },
      },
      isSensorChart: false,
      primaryAxisLabels: {
        xAxis: await this.translateService.instant('KPIS.AGE'),
        yAxis: await this.translateService.instant(
          this.currentStatisticSelected,
        ),
      },
      showMarkers: true,
      showTooltips: true,
      sonarDataType: SonarDataType.None,
      title: this.currentStatisticSelected,
      tooltipDecimals:
        sensorType === SensorType.Weight &&
        this.measurementSystem === MeasurementSystem.Metric
          ? 3
          : StatisticTypeModel.getDecimalPlaces(statisticType),
    };
    setTimeout(() => {
      this.flockComparisonChartArgs = {
        chartViewMode: 'lof',
        dataSource: {
          chartData,
          type: 0,
        },
        sonarDataType: SonarDataType.None,
        showYAxisTitle: true,
      };
    });
  }

  statisticsDataTable(
    currentStatisticData: any,
    lastStatisticData: any,
    statisticType: StatisticType,
  ) {
    this.flockStatisticComparison = [];
    for (const current of currentStatisticData) {
      this.flockStatisticComparison.push({
        statistic: current.age,
        current: this.customStatisticDataConversion(
          current.value,
          statisticType,
        ),
        sensorType: this.statisticToSensorTypeForTable(statisticType),
        isLifeOfFlockTable: true,
        statisticType,
      });
    }
    for (const last of lastStatisticData) {
      const row = this.flockStatisticComparison.find(
        (r) => r.statistic === last.age,
      );
      if (row) {
        row.lastFlock = this.customStatisticDataConversion(
          last.value,
          statisticType,
        );
      } else {
        this.flockStatisticComparison.push({
          statistic: last.age,
          lastFlock: this.customStatisticDataConversion(
            last.value,
            statisticType,
          ),
          sensorType: this.statisticToSensorTypeForTable(statisticType),
          isLifeOfFlockTable: true,
          statisticType,
        });
      }
    }
    this.flockStatisticComparison = _.orderBy(
      this.flockStatisticComparison,
      ['statistic'],
      ['asc'],
    );
    this.flockStatisticComparison.forEach((f) => {
      f.showArrow = true;
      f.infoArrow =
        f.current && f.lastFlock
          ? this.customStatisticDetailsArrow(
              f.statisticType,
              f.current,
              f.lastFlock,
            )
          : '-';
    });
    this.lifeOfFlockTableDataSource = this.flockStatisticComparison;
  }

  onShowFullScreenStatisticClicked(chart: string) {
    this.fullScreenLoaded = false;
    this.showFullScreen = true;
    switch (chart) {
      case StatisticTypeModel.toStringName(StatisticType.PercentDepletion):
        this.fullScreenMetadata = { title: 'KPIS.PERCENT_DEPLETION' };
        break;
      case StatisticTypeModel.toStringName(StatisticType.AverageWeight):
        this.fullScreenMetadata = { title: 'KPIS.WEIGHT' };
        break;
      case StatisticTypeModel.toStringName(StatisticType.WeightGain):
        this.fullScreenMetadata = { title: 'KPIS.WEIGHT_GAIN' };
        break;
      case StatisticTypeModel.toStringName(StatisticType.FeedPerBird):
        this.fullScreenMetadata = { title: 'KPIS.FEED_PER_BIRD' };
        break;
      case StatisticTypeModel.toStringName(StatisticType.FeedConversion):
        this.fullScreenMetadata = { title: 'KPIS.FEED_CONVERSION' };
        break;
      case StatisticTypeModel.toStringName(StatisticType.WaterConsumption):
        this.fullScreenMetadata = { title: 'KPIS.WATER_CONSUMPTION_BIRD' };
        break;
      case StatisticTypeModel.toStringName(StatisticType.Temperature):
        this.fullScreenMetadata = { title: 'KPIS.TEMPERATURE' };
        break;
      case StatisticTypeModel.toStringName(StatisticType.Humidity):
        this.fullScreenMetadata = { title: 'KPIS.HUMIDITY' };
        break;
      case StatisticTypeModel.toStringName(StatisticType.Ammonia):
        this.fullScreenMetadata = { title: 'KPIS.AMMONIA' };
        break;
      case StatisticTypeModel.toStringName(StatisticType.CarbonDioxide):
        this.fullScreenMetadata = { title: 'KPIS.CARBON_DIOXIDE' };
        break;
      case StatisticTypeModel.toStringName(StatisticType.AirFlow):
        this.fullScreenMetadata = { title: 'KPIS.AIR_FLOW' };
        break;
      case StatisticTypeModel.toStringName(StatisticType.Uniformity):
        this.fullScreenMetadata = { title: 'KPIS.UNIFORMITY' };
        break;
      case StatisticTypeModel.toStringName(StatisticType.CV):
        this.fullScreenMetadata = { title: 'KPIS.CV' };
        break;
    }
    this.fullScreenSettings = this.flockComparisonChartSettings;
    setTimeout(
      () =>
        (this.fullScreenArgs = Object.assign(
          {},
          this.flockComparisonChartArgs,
        )),
    );
    this.fullScreenLoaded = true;
  }

  async kpiChartStatistics(
    statisticType: StatisticType,
    currentStatisticData: any[],
    lastStatisticData: any[],
    showArrow: boolean,
    invertStatus: boolean,
    excludeColor: boolean = false,
  ) {
    if (!currentStatisticData || currentStatisticData.length === 0) {
      return;
    }
    const currentData = _(currentStatisticData).maxBy((f) => f.age);
    const lastData = _(lastStatisticData).find(
      (f) => f.age === currentData.age,
    );
    if (currentData?.value > lastData?.value) {
      this.kpiStatus = KPIThreshold.Above;
    } else if (currentData?.value < lastData?.value) {
      this.kpiStatus = KPIThreshold.Below;
    } else {
      this.kpiStatus = KPIThreshold.Normal;
    }

    const sensorType =
      StatisticTypeModel.getSensorTypeForConversionByStatisticType(
        statisticType,
      );
    const sensorUnit = this.sensorUnit(sensorType, statisticType);
    const decimalPlaces =
      sensorType === SensorType.Weight &&
      this.measurementSystem === MeasurementSystem.Metric
        ? 3
        : StatisticTypeModel.getDecimalPlaces(statisticType);
    this.kpiChartStatisticsComparison = [
      {
        label: await this.translateService
          .get('COMPARISON_CHART.CURRENT')
          .toPromise(),
        value: currentStatisticData.length
          ? `${this.kpiFormatted.formatDecimal(
              !!sensorType || sensorType === 0
                ? SensorType.convertUnit(
                    sensorType,
                    currentData?.value,
                    this.unitConversionService,
                  )
                : currentData?.value,
              KPIFormattedType.Numeric,
              decimalPlaces,
              decimalPlaces,
            )} ${sensorUnit}`
          : 0,
        status: this.kpiStatus,
        showArrow,
        invertStatus,
        excludeColor,
      },
      {
        label: await this.translateService
          .get('COMPARISON_CHART.LAST')
          .toPromise(),
        value: lastStatisticData.length
          ? `${this.kpiFormatted.formatDecimal(
              !!sensorType || sensorType === 0
                ? SensorType.convertUnit(
                    sensorType,
                    lastData?.value,
                    this.unitConversionService,
                  )
                : lastData?.value,
              KPIFormattedType.Numeric,
              decimalPlaces,
              decimalPlaces,
            )} ${sensorUnit}`
          : 0,
      },
    ];
  }

  statisticToSensorTypeForTable(statisticType: StatisticType) {
    switch (statisticType) {
      case StatisticType.AverageWeight:
      case StatisticType.WeightGain:
        return SensorType.Weight;
      case StatisticType.Temperature:
        return SensorType.Temperature;
      case StatisticType.Ammonia:
        return SensorType.Ammonia;
      case StatisticType.Humidity:
        return SensorType.Humidity;
      case StatisticType.WaterConsumption:
        return SensorType.WaterConsumption;
      case StatisticType.AirFlow:
        return SensorType.AirFlow;
      case StatisticType.CarbonDioxide:
        return SensorType.CarbonDioxide;
      default:
        return null;
    }
  }

  sensorUnit(sensorType: SensorType, statisticType: StatisticType) {
    if (sensorType !== undefined) {
      return SensorType.getSensorUnit(sensorType, this.unitConversionService);
    }
    if (
      statisticType === StatisticType.PercentDepletion ||
      statisticType === StatisticType.Uniformity ||
      statisticType === StatisticType.CV
    ) {
      return '%';
    }
    if (statisticType === StatisticType.FeedConversion) {
      return '';
    }
  }

  customStatisticDataConversion(
    statisticValue: number,
    statisticType: StatisticType,
  ) {
    switch (statisticType) {
      case StatisticType.FeedConversion:
        return this.kpiFormatted.formatDecimal(
          statisticValue,
          KPIFormattedType.Numeric,
          2,
          2,
        );
      case StatisticType.PercentDepletion:
        return `${this.kpiFormatted.formatDecimal(
          statisticValue,
          KPIFormattedType.Numeric,
          2,
          2,
        )} %`;
      case StatisticType.FeedPerBird:
        return this.weightPipe.transform(
          statisticValue,
          this.sensorType.BinFeedAmount,
          '1.2-2',
          true,
        );
      case StatisticType.Uniformity:
      case StatisticType.CV:
        //Needed to prevent trailing '.0000x' digits
        return `${Math.round(statisticValue)} %`;
      default:
        return statisticValue;
    }
  }

  private getWeightDecimalPlaces() {
    return this.measurementSystem === MeasurementSystem.Metric ? 3 : 2;
  }

  private getValue(sensorType: SensorType, value: number) {
    let sensorValue = value;
    switch (sensorType) {
      case SensorType.Temperature:
        sensorValue =
          this.unitConversionService.getTemperatureUnit() === '°F'
            ? this.unitConversionService.convertTemp(value)
            : value;
        break;
      case SensorType.WaterConsumption:
        sensorValue =
          this.unitConversionService.getVolumeUnit() === 'gal'
            ? this.unitConversionService.convertVolume(value)
            : value;
        break;
      case SensorType.BinFeedAmount:
        sensorValue =
          this.unitConversionService.getFeedBinAmountUnit() === 'lbs'
            ? this.unitConversionService.convertWeight(value)
            : value;
        break;
      case SensorType.Weight:
        sensorValue =
          this.unitConversionService.getBirdWeightUnit() === 'lbs'
            ? this.unitConversionService.convertWeight(value / 1000)
            : value / 1000;
        break;
    }
    return sensorValue;
  }

  private getStatisticData(charts: any, statisticType: StatisticType): any[] {
    switch (statisticType) {
      case StatisticType.PercentDepletion:
        return charts.percentDepletion?.data
          ? charts.percentDepletion.data
          : [];
      case StatisticType.AverageWeight:
        if (!charts.averageWeight) {
          return [];
        }
        charts.averageWeight.data.sensorType = SensorType.Weight;
        return charts.averageWeight.data;
      case StatisticType.WeightGain:
        if (!charts.weightGain) {
          return [];
        }
        charts.weightGain.data.sensorType = SensorType.Weight;
        return charts.weightGain.data;
      case StatisticType.FeedPerBird:
        if (!charts.feedPerBird) {
          return [];
        }
        charts.feedPerBird.data.sensorType = SensorType.BinFeedAmount;
        return charts.feedPerBird.data;
      case StatisticType.FeedConversion:
        return charts.feedConversion ? charts.feedConversion.data : [];
      case StatisticType.WaterConsumption:
        if (!charts.waterConsumption) {
          return [];
        }
        charts.waterConsumption.data.sensorType = SensorType.WaterConsumption;
        return charts.waterConsumption.data;
      case StatisticType.Temperature:
        if (!charts.temperature) {
          return [];
        }
        charts.temperature.data.sensorType = SensorType.Temperature;
        return charts.temperature.data;
      case StatisticType.Humidity:
        if (!charts.humidity) {
          return [];
        }
        charts.humidity.data.sensorType = SensorType.Humidity;
        return charts.humidity ? charts.humidity.data : [];
      case StatisticType.Ammonia:
        if (!charts.ammonia) {
          return [];
        }
        charts.ammonia.data.sensorType = SensorType.Ammonia;
        return charts.ammonia.data ? charts.ammonia.data : [];
      case StatisticType.CarbonDioxide:
        if (!charts.carbonDioxide) {
          return [];
        }
        charts.carbonDioxide.data.sensorType = SensorType.CarbonDioxide;
        return charts.carbonDioxide.data ? charts.carbonDioxide.data : [];
      case StatisticType.AirFlow:
        if (!charts.airFlow) {
          return [];
        }
        charts.airFlow.data.sensorType = SensorType.AirFlow;
        return charts.airFlow.data ? charts.airFlow.data : [];
      case StatisticType.Uniformity:
        return charts.uniformity ? charts.uniformity.data : [];
      case StatisticType.CV:
        return charts.cv ? charts.cv.data : [];
    }
  }

  onBinClicked(binInfo: any) {
    this.router.navigate([
      `/farms/${binInfo.proteinFarmsId.toString()}/houses/${binInfo.proteinHousesId.toString()}/sensors/${SonarDataType.toString(
        SonarDataType.BinFeedAmount,
      )}/overview`,
      { tab: 'sensors' },
    ]);
  }
}

interface FlockPerformanceTableData {
  category: string;
  actual: any;
  average: any;
  averagePastFlock: any;
}
