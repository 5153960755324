import { UnitConversionService } from "@sonar/core/unit-conversion.service";
import { SonarData } from "./sonar-data";
import { SonarDataType } from "@sonar/shared/sensor-type";
import { NumberSymbol } from "@angular/common";


export class TemperatureSensor extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;
  sonarDataType: SonarDataType = SonarDataType.Temperature;

  isSensor = true;
  decimalPlaces = '1.0-1';
  maxSensorCount = 20;
  labelKey = 'KPIS.TEMPERATURE';
  statistic = 'temp';
  iconName = 'temperature';
  sensorStyleClass = 'ami-temperature';

  getSensorUnit = (unitConversionService: UnitConversionService): string => unitConversionService.getTemperatureUnit();
  convertUnit = (value: number, unitConversionService: UnitConversionService): number => unitConversionService.convertTemp(value);
  getPropertyNames = (prefix?: string, suffix?: string): string[] => this.generatePropertyNames(prefix, 'temperature', suffix);
}
