/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/member-ordering */
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as _ from 'lodash';
import { SensorPipe } from '@sonar/core/pipes/sensor.pipe';
import { UserSettingsService } from '@sonar/core/user-settings/user-settings.service';
import { UnitConversionService } from '@sonar/core/unit-conversion.service';
import { Alert } from './alert';
import { SensorType } from '../sensor-type';
import { AlertType } from './alert-type';

@Component({
  selector: 'card-alert',
  templateUrl: './card-alert.component.html',
  providers: [SensorPipe],
  styleUrls: [
    './card-alert.component.scss',
    '../cards/mt-card/mt-card.component.scss',
  ],
})
export class CardAlertComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject();

  constructor(
    private readonly userSettingsService: UserSettingsService,
    private readonly translateService: TranslateService,
    private readonly sensorPipe: SensorPipe,
    private readonly unitConversionService: UnitConversionService
  ) {}

  @Input() alert: Alert;

  @Output() cardSelected: EventEmitter<Alert> = new EventEmitter();

  icon: string;
  title: string;
  minThreshold: any;
  maxThreshold: any;
  sensorTitle: string;
  sensorUnit: string;
  message: string;
  value: number;
  depletionAlertKey: string;
  sensorList = [
    {
      value: SensorType.Temperature,
      label: 'KPIS.TEMPERATURE',
      icon: 'temperature',
    },
    { value: SensorType.WaterConsumption, label: 'KPIS.WATER', icon: 'water' },
    { value: SensorType.BinFeedAmount, label: 'KPIS.FEED', icon: 'feed' },
    { value: SensorType.Humidity, label: 'KPIS.HUMIDITY', icon: 'humidity' },
    { value: SensorType.Weight, label: 'KPIS.WEIGHT', icon: 'weight' },
    { value: SensorType.Ammonia, label: 'KPIS.AMMONIA', icon: 'ammonia' },
    { value: SensorType.AirFlow, label: 'KPIS.AIR_FLOW', icon: 'air-flow' },
    {
      value: SensorType.CarbonDioxide,
      label: 'KPIS.CARBON_DIOXIDE',
      icon: 'carbon-dioxide',
    },
  ];
  isSensorAlert = false;
  isDepletionAlert = false;

  ngOnInit() {
    if (!this.alert) {
      return;
    }
    this.updateSensorAndDepletionAlertFlags();

    const currentSensorType = this.sensorList.find(
      (s) => s.value === this.alert.sensorType
    );
    if (currentSensorType) {
      this.title = currentSensorType.label;
      this.icon = currentSensorType.icon;
    } else {
      this.title = `${this.alert.farmName} - ${this.alert.houseNo}`;
      this.icon =
        this.alert.alertType === AlertType.DeviceOffline
          ? 'offline'
          : 'chick-dispose';
    }

    this.userSettingsService.$settings
      .pipe(takeUntil(this.destroy$))
      .subscribe(async () => {
        if (this.alert) {
          this.updateSensorAndDepletionAlertFlags();

          if (!!this.alert.sensorType || this.alert.sensorType === 0) {
            this.sensorTitle = SensorType.toLabelKey(this.alert.sensorType);
            this.sensorUnit = SensorType.getSensorUnit(
              this.alert.sensorType,
              this.unitConversionService
            );
          }
          if (!_.isNil(this.alert.sensorType)) {
            this.value = this.alert.lastValue;
          } else {
            let key = '';
            switch (this.alert.alertType) {
              case AlertType.NoDepletion:
                key = 'KPIS.NO_DEPLETION';
                break;
              case AlertType.DepletionOverMaxTarget:
                key = 'KPIS.DEPLETION_OVER_MAX_TARGET';
                break;
            }
            this.depletionAlertKey = key;
          }

          this.minThreshold = this.alert.minThreshold;
          this.maxThreshold = this.alert.maxThreshold;
        }
        this.message = await this.getAlertMessage(this.alert.alertType);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onCardSelected(args: Alert) {
    this.cardSelected.emit(args);
  }

  async getAlertMessage(alertType: AlertType) {
    switch (alertType) {
      case AlertType.Threshold:
        if (
          this.alert.minThreshold &&
          this.alert.lastValue < this.alert.minThreshold
        ) {
          const value = await this.translateService.get('KPIS.MIN').toPromise();
          return `${value} ${this.sensorPipe.transform(
            this.minThreshold,
            this.alert.sensorType,
            true
          )}`;
        } else if (
          this.alert.maxThreshold &&
          this.alert.lastValue > this.alert.maxThreshold
        ) {
          const value = await this.translateService.get('KPIS.MAX').toPromise();
          return `${value} ${this.sensorPipe.transform(
            this.maxThreshold,
            this.alert.sensorType,
            true
          )}`;
        }
        return '';
      case AlertType.FeedWillBeEmpty:
        return 'KPIS.FEED_WILL_BE_EMPTY_IN_24_HOURS';
      case AlertType.RateOfChange:
        if (this.alert.sensorType === SensorType.Temperature) {
          return 'KPIS.RATE_OF_CHANGE';
        }
        break;
      case AlertType.TotalWaterConsumptionDecrease:
        return 'KPIS.WATER_CONSUMPTION';
      case AlertType.DeclineInPeakFlowWaterRate:
        return 'KPIS.WATER_CONSUMPTION_RATE';
      case AlertType.WeightLoss:
        return 'KPIS.WEIGHT_LOSS';
      case AlertType.WeightGainDecrease:
        return 'KPIS.WEIGHT_GAIN_DECREASE';
      case AlertType.FeedConsumptionDecline:
        return 'KPIS.FEED_CONSUMPTION_DECLINE';
      case AlertType.DeviceOffline:
        return 'OFFLINE';
      case AlertType.FeedConsumptionHourlyThreshold:
        return 'KPIS.FEED_CONSUMPTION_HOURLY_THRESHOLD';
      case AlertType.FeedConsumptionDailyThreshold:
        if (
          this.alert.minThreshold &&
          this.alert.lastValue < this.alert.minThreshold
        ) {
          return 'KPIS.FEED_CONSUMPTION_DAILY_THRESHOLD_BELOW';
        } else {
          return 'KPIS.FEED_CONSUMPTION_DAILY_THRESHOLD_ABOVE';
        }
      default:
        return '';
    }
  }

  private updateSensorAndDepletionAlertFlags() {
    if (!_.isNil(this.alert.sensorType)) {
      this.isSensorAlert = true;
    } else {
      this.isSensorAlert = false;
    }
    if (
      this.alert.alertType === AlertType.NoDepletion ||
      this.alert.alertType === AlertType.DepletionOverMaxTarget
    ) {
      this.isDepletionAlert = true;
    } else {
      this.isDepletionAlert = false;
    }
  }
}
