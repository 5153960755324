import { UnitConversionService } from "@sonar/core/unit-conversion.service";
import { SonarData } from "./sonar-data";
import { SonarDataType } from "@sonar/shared/sensor-type";
import { NumberSymbol } from "@angular/common";


export class EggsProducedSensor extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;
  isSensor = true;
  sonarDataType: SonarDataType = SonarDataType.EggsProduced;
  
  statistic = 'eggs-produced';
  iconName = 'eggs';
  sensorStyleClass = 'ami-eggs-produced';
  decimalPlaces = '1.0-0';
  maxSensorCount = 4;
  labelKey = 'KPIS.EGGS_PRODUCED';

  convertUnit = (value: number, u: UnitConversionService) => value;
  getSensorUnit = (unitConversionService: UnitConversionService): string => unitConversionService.getEggUnit();
  getPropertyNames = (prefix?: string, suffix?: string): string[] => this.generatePropertyNames(prefix, 'eggsProduced', suffix);
}
