import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppSettingsService } from './app-settings.service';
import { LiveUpdateService } from './live-update.service';

@Injectable()
export class LiveUpdateServiceFactory extends LiveUpdateService {
  constructor(
    alertController: AlertController,
    translateService: TranslateService,
    settingsService: AppSettingsService
  ) {
    super(alertController, translateService, settingsService);
  }

  async createService(): Promise<LiveUpdateService> {
    await this.init();
    return this;
  }
}
