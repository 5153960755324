import { UnitConversionService } from "@sonar/core/unit-conversion.service";
import { SonarData } from "./sonar-data";
import { SonarDataType } from "@sonar/shared/sensor-type";
import { NumberSymbol } from "@angular/common";


export class OutsideTemperatureSensor extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;
  isSensor = true;
  sonarDataType: SonarDataType = SonarDataType.OutsideTemperature;
  
  statistic = 'outside-temperature';
  iconName = 'temperature';
  sensorStyleClass = 'ami-temperature';
  decimalPlaces = '1.0-1';
  maxSensorCount = 1;
  labelKey = 'KPIS.OUTSIDE_TEMPERATURE';

  getPropertyNames = (prefix?: string, suffix?: string): string[] => this.generatePropertyNames(prefix, 'outsideTemperature', suffix);
  getSensorUnit = (unitConversionService: UnitConversionService): string => unitConversionService.getTemperatureUnit();
  convertUnit(value: number, unitConversionService: UnitConversionService): number {
    return unitConversionService.convertTemp(value);
  }
}
