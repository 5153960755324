import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { MemoryStorageService } from './memory-storage.service';

/* eslint-disable @typescript-eslint/naming-convention */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private auth: AuthService;
  private readonly memoryStorageService: MemoryStorageService;
  constructor(injector: Injector) {
    this.auth = injector.get(AuthService);
    this.memoryStorageService = injector.get(MemoryStorageService);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.url.toLowerCase().includes('onemunters') ||
      req.url.toLowerCase().includes('assets') ||
      req.url.toLowerCase().includes('b2cprovider') ||
      req.url.toLowerCase().includes('appversion')
    ) {
      return next.handle(req);
    }

    return from(this.auth.getAccessToken()).pipe(
      switchMap((token) => {
        req = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token,
          },
        });
        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
              this.memoryStorageService.clear();
              this.auth.handleLogoutCallback();
              this.auth.logout();
            }

            return throwError(() => error);
          })
        );
      })
    );
  }
}
