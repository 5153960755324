/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, ViewChild } from '@angular/core';
import {
  AlertController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '@sonar/core';
import { DataServiceFactory } from '@sonar/core/data-service.factory';
import { FarmService } from '@sonar/core/data/farm.service';
import { UserSettingsService } from '@sonar/core/user-settings/user-settings.service';
import { MtNumericInputComponent } from '@sonar/shared/mt-numeric-input/mt-numeric-input.component';
import { NumericInputArrowEvent } from '@sonar/shared/mt-numeric-input/numeric-input-arrow.event';
import * as _ from 'lodash';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';

import { CardTableColumnArgs } from '../../cards/mt-card-table/card-table-column-args';
import { ClientDataType } from '../../cards/mt-card-table/client-data-type';
import { FarmType } from '../../farm/farm-type';
import { NumericInputEntryEvent } from '../../mt-numeric-input/numeric-input-entry.event';
import { HouseDepletionComponent } from '../house-depletion/house-depletion.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'depletion-modal',
  templateUrl: './depletion-modal.component.html',
  styleUrls: [
    './depletion-modal.component.scss',
    '../../cards/mt-card/mt-card.component.scss',
  ],
})
export class DepletionModalComponent {
  private farmsDataService: DataService;
  private flockDepletionsDataService: DataService;

  @ViewChild(MtNumericInputComponent, { static: false })
  private readonly ageInput: MtNumericInputComponent;

  constructor(
    private alertController: AlertController,
    private translateService: TranslateService,
    private readonly dataServiceFactory: DataServiceFactory,
    private readonly userSettingsService: UserSettingsService,
    private readonly toastController: ToastController,
    private readonly farmService: FarmService,
    private readonly modalController: ModalController,
  ) {}

  @Input() farmId: number;
  @Input() farmName: string;
  @Input() farmNo: string;
  @Input() farmType: FarmType;
  @Input() date: Date;

  isSaving = false;
  isAgeInWeeks = false;
  ageDaysAdjustment: number;
  minAge = 0;
  maxAge: number;
  selectedAge: number;
  selectedFlock: string;
  currentLoadedFlock: string;
  defaultFlockOption: string;
  currentDay = new Date();
  flocks: any[] = [];
  flockTableColumns: CardTableColumnArgs[];
  originalFlockTableDataSource: any[];
  flockTableDataSource: any[];
  displayDateFormat: string;
  selectableAges: number[];
  loaded: boolean;

  async ionViewWillEnter() {
    this.ageDaysAdjustment = (
      await this.farmService.getFarm(this.farmId)
    ).ageDaysAdjustment;

    this.minAge = this.ageDaysAdjustment > 0 ? this.ageDaysAdjustment : null;
    this.isAgeInWeeks = FarmType.isAgeInWeeks(this.farmType);
    this.defaultFlockOption = _.cloneDeep(this.selectedFlock);
    this.userSettingsService.$settings
      .pipe(filter((settings) => !!settings))
      .subscribe(async (settings) => {
        this.displayDateFormat = moment
          .localeData(settings.language)
          .longDateFormat('l');
      });
    await this.flockList();
    await this.loadFlockDepletions(this.selectedFlock, this.selectedAge);
    this.flockTableColumns = this.getFlockTableColumnArgs();
  }

  hasChanges() {
    const hasChanges = !_.isEqual(
      this.originalFlockTableDataSource,
      this.flockTableDataSource,
    );
    return hasChanges;
  }

  onSelectedHouseTable(data: HouseDepletion) {
    this.updateDepletions(data);
  }

  async goBack() {
    if (this.hasChanges()) {
      await this.hasChangesDialog(this.closeDialog.bind(this));
    } else {
      this.closeDialog();
    }
  }

  closeDialog() {
    this.modalController.dismiss();
  }

  onFlockChanged(args) {
    this.selectedFlock = args.detail.value;
    if (!this.selectedFlock || !this.selectedAge) {
      return;
    }

    this.loadFlockDepletions(this.selectedFlock, this.selectedAge);
  }

  getFlockTableColumnArgs(): CardTableColumnArgs[] {
    let cardTableColumnArgs: CardTableColumnArgs[];
    if (FarmType.isBrimFarmType(this.farmType)) {
      cardTableColumnArgs = [
        {
          caption: 'FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.HOUSE',
          propertyName: 'house',
          clientDataType: ClientDataType.String,
        },
        {
          caption: 'FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.DATE',
          propertyName: 'date',
          clientDataType: ClientDataType.DateTime,
        },
        {
          caption: 'FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.MORTALITY',
          propertyName: 'mortality',
          clientDataType: ClientDataType.Number,
        },
        {
          caption: 'FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.CULLS',
          propertyName: 'culls',
          clientDataType: ClientDataType.Number,
        },
      ];
    } else if (FarmType.isBimFarmType(this.farmType)) {
      cardTableColumnArgs = [
        {
          caption: 'FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.HOUSE',
          propertyName: 'house',
          clientDataType: ClientDataType.String,
        },
        {
          caption: 'FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.DATE',
          propertyName: 'date',
          clientDataType: ClientDataType.DateTime,
        },
        {
          caption: 'FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.MORTALITY_FEMALE',
          propertyName: 'mortalityF',
          clientDataType: ClientDataType.Number,
        },
        {
          caption: 'FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.MORTALITY_MALE',
          propertyName: 'mortalityM',
          clientDataType: ClientDataType.Number,
        },
        {
          caption: 'FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.CULLS_FEMALE',
          propertyName: 'femalesCulled',
          clientDataType: ClientDataType.Number,
        },
        {
          caption: 'FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.CULLS_MALE',
          propertyName: 'malesCulled',
          clientDataType: ClientDataType.Number,
        },
      ];
    }
    return cardTableColumnArgs;
  }

  async saveDepletionChanges() {
    this.isSaving = true;
    try {
      this.getStageFieldTransDetail();
      const promises = this.flockTableDataSource.map((data) => {
        if (FarmType.isBrimFarmType(this.farmType)) {
          return this.flockDepletionsDataService.saveEntity({
            farmId: this.farmId,
            sonarBrimFieldTransDetails: [
              {
                proteinHouseId: data.id,
                entityNo: this.selectedFlock,
                houseNo: data.house,
                timestamp: data.date,
                mortality: Number(data.mortality),
                cull: Number(data.culls),
              },
            ],
          });
        } else if (FarmType.isBimFarmType(this.farmType)) {
          return this.flockDepletionsDataService.saveEntity({
            farmId: this.farmId,
            sonarBimFieldTransDetails: [
              {
                proteinHouseId: data.id,
                entityNo: this.selectedFlock,
                houseNo: data.house,
                timestamp: data.date,
                mortalityf: Number(data.mortalityF),
                mortalityM: Number(data.mortalityM),
                malesCulled: Number(data.malesCulled),
                femalesCulled: Number(data.femalesCulled),
              },
            ],
          });
        }
      });

      forkJoin(promises).subscribe(async (response) => {
        const validationToast = await this.toastController.create({
          message: 'Saved',
          duration: 2000,
          position: 'bottom',
        });
        await validationToast.present();
        this.originalFlockTableDataSource = _.cloneDeep(
          this.flockTableDataSource,
        );
        setTimeout(() => (this.isSaving = false));
      });
    } catch (err) {
      TrackJS.track(err);
      setTimeout(() => (this.isSaving = false));
    }
  }

  private async flockList() {
    this.farmsDataService = this.dataServiceFactory.create({
      endPoint: 'FarmEntities',
    });
    const farmEntities = await this.farmsDataService
      .getEntities<any>({ proteinFarmsId: this.farmId })
      .toPromise();
    farmEntities.forEach((flock) => {
      this.flocks.push({
        id: flock.proteinFarmsId,
        label: flock.entityNo,
      });
    });
    // Select Current Day and Fist Flock of the list as default
    if (this.flocks.length && this.hasChanges()) {
      return;
    }

    const ageInDays = _(farmEntities.map((f) => f.currentAge)).max();
    const allAgeValues = Array.from(
      { length: ageInDays + 1 },
      (__, i) => i + this.ageDaysAdjustment,
    ).map((x) => this.formatAgeToWeeks(x));

    if (this.date && ageInDays > 0) {
      const age =
        moment(moment(this.date)).diff(
          moment(_(farmEntities.map((f) => f.startDate)).min()),
          'days',
        ) + this.ageDaysAdjustment;
      this.selectedAge = this.formatAgeToWeeks(age);
    } else {
      this.selectedAge = this.formatAgeToWeeks(ageInDays);
    }

    this.maxAge = this.selectedAge;
    this.selectableAges = allAgeValues.filter(
      (value) => value <= this.selectedAge,
    );

    this.selectedFlock = this.flocks[0].label;
  }

  private async updateDepletions(houseDepletionData: HouseDepletion) {
    const modal = await this.modalController.create({
      component: HouseDepletionComponent,
      componentProps: {
        dataSource: this.flockTableDataSource,
        currentItem: houseDepletionData,
        farmType: this.farmType,
        isBrimFarmType: FarmType.isBrimFarmType(this.farmType),
        isBimFarmType: FarmType.isBimFarmType(this.farmType),
      },
    });

    modal.onDidDismiss().then((data) => {
      if (!data) {
        return;
      }
      this.flockTableDataSource = data?.data;
    });
    return await modal.present();
  }

  private formatAgeToWeeks(age: number): number {
    if (this.farmType === FarmType.BRIM) {
      return age;
    }

    const decimalAge = age / 7;
    return (
      Math.round(
        (Math.floor(decimalAge) + Math.round((decimalAge % 1) * 7) * 0.1) * 10,
      ) / 10
    );
  }

  private formatAgeToDays(age: number): number {
    if (this.farmType === FarmType.BRIM) {
      return age;
    }

    return Math.round(Math.floor(age) * 7 + (age - Math.floor(age)) * 10);
  }

  private async loadFlockDepletions(entityNo: string, age: number) {
    if (
      !this.selectedAge ||
      (this.selectedAge === age && this.currentLoadedFlock === entityNo)
    ) {
      return;
    }

    this.selectedAge = age;
    this.currentLoadedFlock = entityNo;
    this.flockTableDataSource = [];
    this.loaded = false;
    this.getStageFieldTransDetail();
    const depletions = await this.flockDepletionsDataService
      .getEntities<any>({
        proteinFarmsId: this.farmId,
        entityNo,
        age: this.formatAgeToDays(this.selectedAge),
      })
      .toPromise();
    if (!depletions) {
      this.loaded = true;
      return;
    }
    this.originalFlockTableDataSource = depletions.map((depletion) => {
      if (FarmType.isBrimFarmType(this.farmType)) {
        return {
          id: depletion.proteinHouseId,
          house: depletion.houseNo,
          date: moment(depletion.timestamp).format('YYYY-MM-DD'),
          mortality: depletion.mortality !== 0 ? depletion.mortality : null,
          culls: depletion.cull !== 0 ? depletion.cull : null,
        };
      } else if (FarmType.isBimFarmType(this.farmType)) {
        return {
          id: depletion.proteinHouseId,
          house: depletion.houseNo,
          date: moment(depletion.timestamp).format('YYYY-MM-DD'),
          mortalityF: depletion.mortalityF !== 0 ? depletion.mortalityF : null,
          mortalityM: depletion.mortalityM !== 0 ? depletion.mortalityM : null,
          malesCulled:
            depletion.malesCulled !== 0 ? depletion.malesCulled : null,
          femalesCulled:
            depletion.femalesCulled !== 0 ? depletion.femalesCulled : null,
        };
      }
    });
    this.originalFlockTableDataSource = _.sortBy(
      this.originalFlockTableDataSource,
      'house',
    );
    this.flockTableDataSource = _.cloneDeep(this.originalFlockTableDataSource);
    this.loaded = true;
  }

  private getStageFieldTransDetail() {
    if (FarmType.isBrimFarmType(this.farmType)) {
      this.flockDepletionsDataService = this.dataServiceFactory.create({
        endPoint: 'SonarBrimFieldTransDetails',
      });
    } else if (FarmType.isBimFarmType(this.farmType)) {
      this.flockDepletionsDataService = this.dataServiceFactory.create({
        endPoint: 'SonarBimFieldTransDetails',
      });
    }
  }

  async hasChangesDialog(okCallback: () => void, cancelCallback?: () => void) {
    const cancelText = await this.translateService.get('CANCEL').toPromise();
    const continueText = await this.translateService
      .get('CONTINUE')
      .toPromise();
    const messageText = await this.translateService
      .get('YOU_HAVE_CHANGES')
      .toPromise();
    const alert = await this.alertController.create({
      message: messageText,
      buttons: [
        {
          text: cancelText,
          role: 'cancel',
          handler: () => {
            if (cancelCallback) {
              cancelCallback();
            }
          },
        },
        {
          text: continueText,
          handler: () => {
            okCallback();
          },
        },
      ],
    });
    await alert.present();
  }

  async onAgeArrowTap(args: NumericInputArrowEvent) {
    if (this.hasChanges()) {
      this.hasChangesDialog(
        this.loadFlockDepletions.bind(
          this,
          this.selectedFlock,
          args.currentValue,
        ),
        this.resetAge.bind(this, args.previousValue),
      );
    } else {
      await this.loadFlockDepletions(this.selectedFlock, args.currentValue);
    }
  }

  async onAgeEntered(args: NumericInputEntryEvent) {
    if (this.hasChanges()) {
      this.hasChangesDialog(
        this.loadFlockDepletions.bind(
          this,
          this.selectedFlock,
          args.currentValue,
        ),
        this.resetAge.bind(this, args.previousValue),
      );
    } else {
      await this.loadFlockDepletions(this.selectedFlock, args.currentValue);
    }
  }

  resetAge(previousAge: number) {
    this.ageInput.reset(previousAge);
  }
}

export interface HouseDepletion {
  id: string;
  house: string;
  date: string;
  mortality: string;
  mortalityM: string;
  mortalityF: string;
  culls: string;
  malesCulled: string;
  femalesCulled: string;
}
