/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/no-namespace */

import _ from 'lodash';

/* eslint-disable @typescript-eslint/naming-convention */
export enum FlockEventType {
  PlannedPlacement = 0,
  PlannedPickup = 1,
  PlannedFeedDelivery = 2,
  Placement = 3,
  Processing = 4,
  FeedDelivery = 5,
  FeedHaulback = 6,
  FeedReturn = 7,
  FeedCredit = 8,
  FeedDebit = 9,
}

export namespace FlockEventType {
  export function toColor(eventType: FlockEventType): string {
    switch (eventType) {
      case FlockEventType.PlannedPlacement:
      case FlockEventType.Placement:
        return '';
      case FlockEventType.PlannedPickup:
      case FlockEventType.Processing:
        return 'blue';
      case FlockEventType.PlannedFeedDelivery:
      case FlockEventType.FeedDelivery:
      case FlockEventType.FeedHaulback:
      case FlockEventType.FeedReturn:
      case FlockEventType.FeedCredit:
      case FlockEventType.FeedDebit:
        return 'yellow';
    }
  }

  export function isFeedEvent(eventType: FlockEventType): boolean {
    switch (eventType) {
      case FlockEventType.FeedCredit:
      case FlockEventType.FeedDebit:
      case FlockEventType.FeedDelivery:
      case FlockEventType.FeedHaulback:
      case FlockEventType.FeedReturn:
      case FlockEventType.PlannedFeedDelivery:
        return true;
      default:
        return false;
    }
  }

  export function getFeedTransCodeLabel(eventType: FlockEventType) {
    switch (eventType) {
      case FlockEventType.FeedDelivery:
        return 'FEED_EVENTS.DELIVERY';
      case FlockEventType.FeedHaulback:
        return 'FEED_EVENTS.HAULBACK';
      case FlockEventType.FeedReturn:
        return 'FEED_EVENTS.RETURN';
      case FlockEventType.FeedCredit:
        return 'FEED_EVENTS.CREDIT';
      case FlockEventType.FeedDebit:
        return 'FEED_EVENTS.DEBIT';
      default:
        return 'INVALID_EVENT';
    }
  }

  export function getEventName(eventType: FlockEventType) {
    switch (eventType) {
      case FlockEventType.PlannedPlacement:
        return 'EVENTS.PLANNED_PLACEMENT';
      case FlockEventType.PlannedPickup:
        return 'EVENTS.PLANNED_PROCESSING';
      case FlockEventType.PlannedFeedDelivery:
        return 'EVENTS.PLANNED_FEED_DELIVERY';
      case FlockEventType.Placement:
        return 'EVENTS.PLACEMENT';
      case FlockEventType.Processing:
        return 'EVENTS.PROCESSING';
      case FlockEventType.FeedDelivery:
      case FlockEventType.FeedHaulback:
      case FlockEventType.FeedReturn:
      case FlockEventType.FeedCredit:
      case FlockEventType.FeedDebit:
        return 'EVENTS.FEED_TRANSACTION';
    }
  }

  export function isPlacementEvent(eventType: FlockEventType): boolean {
    return (
      eventType === FlockEventType.Placement ||
      eventType === FlockEventType.PlannedPlacement
    );
  }

  export function isPickupEvent(eventType: FlockEventType): boolean {
    return (
      eventType === FlockEventType.Processing ||
      eventType === FlockEventType.PlannedPickup
    );
  }

  export function isPlannedEvent(eventType: FlockEventType): boolean {
    switch (eventType) {
      case FlockEventType.PlannedFeedDelivery:
      case FlockEventType.PlannedPickup:
      case FlockEventType.PlannedPlacement:
        return true;
      default:
        return false;
    }
  }

  export function isActualEvent(eventType: FlockEventType): boolean {
    return !isPlannedEvent(eventType);
  }
}
