<ion-header class="navigation">
  <ion-toolbar color="secondary">
    <ion-title>
      {{ farmName }}
      <div class="navigation__subtitle caption">{{ farmNo }}</div>
    </ion-title>
    <ion-buttons slot="end">
      <div end class="navigation__cancel-icon" (click)="goBack()">
        <i class="ami-cancel"></i>
      </div>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="container">
  <ion-item>
    <ion-label>{{ "KPIS.AGE" | translate }}</ion-label>
    <mt-numeric-input
      #ageInput
      [useWeeksAge]="isAgeInWeeks"
      [maxValue]="maxAge"
      [minValue]="minAge"
      [placeholder]="'KPIS.AGE' | translate"
      [selectedValue]="selectedAge"
      (arrowTapped)="onAgeArrowTap($event)"
      (numberEntered)="onAgeEntered($event)"
    >
    </mt-numeric-input>
  </ion-item>
  <ion-item>
    <ion-label>{{
      "FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.FLOCK" | translate
    }}</ion-label>
    <ion-select
      placeholder="{{
        'FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.FLOCK' | translate
      }}"
      [value]="selectedFlock"
      interface="action-sheet"
      (ionChange)="onFlockChanged($event)"
    >
      <ng-container *ngFor="let flock of flocks">
        <ion-select-option [value]="flock.label">
          {{ flock.label | translate }}
        </ion-select-option>
      </ng-container>
    </ion-select>
  </ion-item>

  <div class="container__table">
    <mt-card-table
      [cardTableColumnArgs]="flockTableColumns"
      [dataSource]="flockTableDataSource"
      [loaded]="loaded"
      (onSelectedRow)="onSelectedHouseTable($event)"
    >
    </mt-card-table>
    <ion-button
      class="raised"
      (click)="saveDepletionChanges()"
      data-testid="button-save"
      [disabled]="isSaving"
    >
      {{ "FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.SAVE" | translate }}
      <ng-container *ngIf="isSaving">
        <div class="ball-pulse">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </ng-container>
    </ion-button>
  </div>
</ion-content>
