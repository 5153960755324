/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/member-ordering */
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FarmType } from '@sonar/shared/farm/farm-type';

import { HouseDepletion } from '../depletion/depletion-modal.component';

@Component({
  selector: 'app-house-depletion',
  templateUrl: './house-depletion.component.html',
  styleUrls: ['./house-depletion.component.scss'],
})
export class HouseDepletionComponent implements AfterViewInit {
  @ViewChildren('list', { read: ElementRef }) lists: QueryList<ElementRef>;

  constructor(
    private readonly modalController: ModalController,
    private readonly renderer: Renderer2
  ) {}

  @Input() currentItem: HouseDepletion;
  @Input() dataSource: HouseDepletion[] | any[];
  @Input() farmType: FarmType;
  @Input() isBrimFarmType: boolean;
  @Input() isBimFarmType: boolean;

  itemIndex: any;
  lastItemIndex: any;

  ngAfterViewInit() {
    this.itemIndex = this.dataSource
      .map((o) => o.id)
      .indexOf(this.currentItem.id);
    this.lastItemIndex = this.dataSource.lastIndexOf(
      this.dataSource[this.dataSource.length - 1]
    );
    this.getCurrentListItem();
  }

  getCurrentListItem() {
    const itemLists = Array.from(this.lists);
    if (!itemLists) {
      return;
    }
    itemLists.forEach((l) => {
      if (l.nativeElement.id === this.currentItem.house) {
        this.renderer.setStyle(l.nativeElement, 'display', 'block');
      } else {
        this.renderer.setStyle(l.nativeElement, 'display', 'none');
      }
    });
  }

  goBack() {
    this.modalController.dismiss(this.dataSource);
  }

  previous() {
    this.spliceData();
    if (this.itemIndex !== -1 && this.itemIndex !== 0) {
      this.currentItem = this.dataSource[this.itemIndex - 1];
      this.itemIndex--;
      this.getCurrentListItem();
    }
  }

  next() {
    this.spliceData();
    if (this.itemIndex !== -1 && this.dataSource.length - 1 > this.itemIndex) {
      this.currentItem = this.dataSource[this.itemIndex + 1];
      this.itemIndex++;
      this.getCurrentListItem();
    }
  }

  spliceData() {
    const inputs = Array.from(document.querySelectorAll('ion-input')).filter(
      (i) => i.id === this.currentItem.house
    );
    if (!inputs) {
      return;
    }
    const currentInputs = inputs.map(({ name, value }) => ({ name, value }));
    const currentValues = Object.assign(
      {},
      ...currentInputs.map((c) => ({ [c.name]: c.value }))
    );
    if (!currentValues) {
      return;
    }
    if (this.isBimFarmType) {
      this.dataSource.splice(this.itemIndex, 1, {
        id: this.currentItem.id,
        house: this.currentItem.house,
        date: this.currentItem.date,
        mortalityF: !!currentValues.mortalityF
          ? currentValues.mortalityF
          : undefined,
        mortalityM: !!currentValues.mortalityM
          ? currentValues.mortalityM
          : undefined,
        malesCulled: !!currentValues.malesCulled
          ? currentValues.malesCulled
          : undefined,
        femalesCulled: !!currentValues.femalesCulled
          ? currentValues.femalesCulled
          : undefined,
      });
    }
    if (this.isBrimFarmType) {
      this.dataSource.splice(this.itemIndex, 1, {
        id: this.currentItem.id,
        house: this.currentItem.house,
        date: this.currentItem.date,
        mortality: !!currentValues.mortality
          ? currentValues.mortality
          : undefined,
        culls: !!currentValues.culls ? currentValues.culls : undefined,
      });
    }
  }

  closeModal() {
    this.spliceData();
    this.modalController.dismiss(this.dataSource);
  }
}
