import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { FarmType } from '@sonar/shared/farm/farm-type';

@Pipe({
  name: 'isBimFarmType',
})
@Injectable()
export class IsBimFarmTypePipe implements PipeTransform {
  transform(value: FarmType): boolean {
    return FarmType.isBimFarmType(value);
  }
}
