import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Alert } from '@sonar/shared/alerts/alert';
import { SensorType } from '@sonar/shared/sensor-type';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

import { CacheService } from '../cache.service';
import { DataServiceFactory } from '../data-service.factory';
import { DataService } from '../data.service';
import { MaxCacheAge } from '../max-cache-age';

@Injectable()
export class AlertService {
  public readonly alerts = new BehaviorSubject<Alert[]>([]);
  readonly alertCount = new BehaviorSubject<number>(0);
  readonly birdPerfAlertCount = new BehaviorSubject<number>(0);
  readonly envAlertCount = new BehaviorSubject<number>(0);
  public readonly limitedAlerts = new BehaviorSubject<Alert[]>([]);
  readonly limitedAlertCount = new BehaviorSubject<number>(0);
  readonly limitedBirdPerfAlertCount = new BehaviorSubject<number>(0);
  readonly limitedEnvAlertCount = new BehaviorSubject<number>(0);
  readonly showToast = new BehaviorSubject<boolean>(false);

  alertDataService: DataService;
  alerts$ = this.alerts.asObservable();
  alertCount$ = this.alertCount.asObservable();
  birdPerfAlertCount$ = this.birdPerfAlertCount.asObservable();
  envAlertCount$ = this.envAlertCount.asObservable();
  limitedAlerts$ = this.limitedAlerts.asObservable();
  limitedAlertCount$ = this.limitedAlertCount.asObservable();
  limitedBirdPerfAlertCount$ = this.limitedBirdPerfAlertCount.asObservable();
  limitedEnvAlertCount$ = this.limitedEnvAlertCount.asObservable();
  toastElement: any;
  showToast$ = this.showToast.asObservable();

  constructor(
    private readonly cacheService: CacheService,
    private readonly dataServiceFactory: DataServiceFactory,
    private readonly toastController: ToastController,
    private readonly translateService: TranslateService
  ) {
    this.alertDataService = this.dataServiceFactory.create({
      endPoint: 'Alerts',
      maxCacheAge: MaxCacheAge.Short,
    });
  }

  async getAlerts(): Promise<Alert[]> {
    let alerts = this.alerts.getValue();
    if (!alerts.length) {
      await this.refreshAlerts();
      alerts = this.alerts.getValue();
    }

    return alerts;
  }

  async refreshAlerts() {
    const alerts = await lastValueFrom(
      this.alertDataService.getEntities<Alert>()
    );
    this.alerts.next(alerts);
    if (alerts) {
      this.alertCount.next(alerts.length);
      this.birdPerfAlertCount.next(
        alerts.filter(
          (a) =>
            a.sensorType === SensorType.BinFeedAmount ||
            a.sensorType === SensorType.WaterConsumption ||
            a.sensorType === SensorType.Weight
        ).length
      );
      this.envAlertCount.next(
        alerts.filter(
          (a) =>
            a.sensorType === SensorType.Temperature ||
            a.sensorType === SensorType.Humidity ||
            a.sensorType === SensorType.Ammonia
        ).length
      );
    }
    // Added a separate call to get just the last 24 hours of alerts for the main Alerts list page;
    // Different from Farms > House > Alerts, which uses all outstanding alerts, not just last 24 hours.
    // Derrick indicated we should try this out in Dev to see how it performs and then make further decisions
    const limitedAlerts = await lastValueFrom(
      this.alertDataService.getEntities<Alert>(null, 'LastDayAlerts')
    );
    this.limitedAlerts.next(limitedAlerts);
    if (limitedAlerts) {
      this.limitedAlertCount.next(limitedAlerts.length);
      this.limitedBirdPerfAlertCount.next(
        limitedAlerts.filter(
          (a) =>
            a.sensorType === SensorType.BinFeedAmount ||
            a.sensorType === SensorType.WaterConsumption ||
            a.sensorType === SensorType.Weight
        ).length
      );
      this.limitedEnvAlertCount.next(
        limitedAlerts.filter(
          (a) =>
            a.sensorType === SensorType.Temperature ||
            a.sensorType === SensorType.Humidity ||
            a.sensorType === SensorType.Ammonia
        ).length
      );
    }
  }

  async getAlert(alertId: string): Promise<Alert> {
    let alerts = this.alerts.getValue();
    if (!alerts || !alerts.length) {
      await this.refreshAlerts();
      alerts = this.alerts.getValue();
    }
    return alerts.find((a) => a.id === alertId);
  }

  clear() {
    this.cacheService.removePartial('Alerts');
    this.alerts.next([]);
  }

  async configToast() {
    const active = await this.toastController.getTop();
    if (!!active) {
      return; // No reason to create another toast if one is already showing
    }
    this.toastElement = await this.toastController.create({
      message: this.translateService.instant('NEW_ALERTS'),
      position: 'top',
      buttons: [
        {
          text: this.translateService.instant('REFRESH'),
          role: 'close',
          handler: async () => {
            await this.refreshAlerts();
            this.hide();
          },
        },
      ],
    });
    await this.toastElement.present();
  }

  async closeToast() {
    let active = await this.toastController.getTop();
    while (!!active) {
      await active.dismiss();
      active = await this.toastController.getTop();
    }
    this.hide();
  }

  show() {
    this.configToast();
  }

  hide() {
    this.showToast.next(false);
  }
}
