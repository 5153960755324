// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { IonicAuthOptions } from '@ionic-enterprise/auth';

export const azureWebConfig: IonicAuthOptions = {
  // the auth provider
  authConfig: 'azure',
  // The platform which we are running on
  platform: 'web',
  // client or application id for provider
  clientID: '84ff279d-3db7-4612-96ba-5a78f76b69e0',
  // the discovery url for the provider
  // OpenID configuration
  discoveryUrl:
    'https://onemuntersdev.b2clogin.com/onemuntersdev.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SONAR_EXTERNAL_SIGNIN',
  // the URI to redirect to after log in
  redirectUri: 'https://sonardev.mtech-systems.com/login',
  // requested scopes from provider
  scope:
    'https://onemuntersdev.onmicrosoft.com/SonarApiAuth/access_as_user openid profile Offline_access',
  // the URL to redirect to after log out
  logoutUrl: 'https://sonardev.mtech-systems.com/login',
  // Show provider login in either current window or new tab
  // implicitLogin: 'CURRENT',
};

export const androidNativeConfig: IonicAuthOptions = {
  // the auth provider
  authConfig: 'azure',
  // The platform which we are running on
  platform: 'cordova',
  // client or application id for provider
  clientID: '84ff279d-3db7-4612-96ba-5a78f76b69e0',
  // the discovery url for the provider
  // OpenID configuration
  discoveryUrl:
    'https://onemuntersdev.b2clogin.com/onemuntersdev.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SONAR_EXTERNAL_SIGNIN',
  // the URI to redirect to after log in
  redirectUri: 'sonar://app/login',
  // requested scopes from provider
  scope:
    'https://onemuntersdev.onmicrosoft.com/SonarApiAuth/access_as_user openid profile Offline_access',
  // the URL to redirect to after log out
  logoutUrl: 'sonar://app/login',
  // The type of iOS webview to use. 'shared' will use a webview that can share session/cookies
  // on iOS to provide SSO across multiple apps but will cause a prompt for the user which asks them
  // to confirm they want to share site data with the app. 'private' uses a webview which will not
  // prompt the user but will not be able to share session/cookie data either for true SSO across
  // multiple apps.
  iosWebView: 'private',
};

export const iosNativeConfig: IonicAuthOptions = {
  // the auth provider
  authConfig: 'azure',
  // The platform which we are running on
  platform: 'cordova',
  // client or application id for provider
  clientID: '84ff279d-3db7-4612-96ba-5a78f76b69e0',
  // the discovery url for the provider
  // OpenID configuration
  discoveryUrl:
    'https://onemuntersdev.b2clogin.com/onemuntersdev.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SONAR_EXTERNAL_SIGNIN',
  // the URI to redirect to after log in
  redirectUri: 'com.ionicframework.SonarApp://login',
  // requested scopes from provider
  scope:
    'https://onemuntersdev.onmicrosoft.com/SonarApiAuth/access_as_user openid profile Offline_access',
  // the URL to redirect to after log out
  logoutUrl: 'com.ionicframework.SonarApp://login',
  // The type of iOS webview to use. 'shared' will use a webview that can share session/cookies
  // on iOS to provide SSO across multiple apps but will cause a prompt for the user which asks them
  // to confirm they want to share site data with the app. 'private' uses a webview which will not
  // prompt the user but will not be able to share session/cookie data either for true SSO across
  // multiple apps.
  iosWebView: 'private',
};

export const azureWebConfigProd: IonicAuthOptions = {
  // the auth provider
  authConfig: 'azure',
  // The platform which we are running on
  platform: 'web',
  // client or application id for provider
  clientID: '0ad02f86-5557-4811-9563-365ecb8233e7',
  // the discovery url for the provider
  // OpenID configuration
  discoveryUrl:
    'https://onemunters.b2clogin.com/onemunters.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SONAR_EXTERNAL_SIGNIN',
  // the URI to redirect to after log in
  redirectUri: 'https://sonar.mtech-systems.com/login',
  // requested scopes from provider
  scope:
    'https://onemunters.onmicrosoft.com/SonarApiAuth/access_as_user openid profile Offline_access',
  // the URL to redirect to after log out
  logoutUrl: 'https://sonar.mtech-systems.com/login',
  // Show provider login in either current window or new tab
  // implicitLogin: 'CURRENT',
};

export const androidNativeConfigProd: IonicAuthOptions = {
  // the auth provider
  authConfig: 'azure',
  // The platform which we are running on
  platform: 'cordova',
  // client or application id for provider
  clientID: '0ad02f86-5557-4811-9563-365ecb8233e7',
  // the discovery url for the provider
  // OpenID configuration
  discoveryUrl:
    'https://onemunters.b2clogin.com/onemunters.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SONAR_EXTERNAL_SIGNIN',
  // the URI to redirect to after log in
  redirectUri: 'sonar://app/login',
  // requested scopes from provider
  scope:
    'https://onemunters.onmicrosoft.com/SonarApiAuth/access_as_user openid profile Offline_access',
  // the URL to redirect to after log out
  logoutUrl: 'sonar://app/login',
  // The type of iOS webview to use. 'shared' will use a webview that can share session/cookies
  // on iOS to provide SSO across multiple apps but will cause a prompt for the user which asks them
  // to confirm they want to share site data with the app. 'private' uses a webview which will not
  // prompt the user but will not be able to share session/cookie data either for true SSO across
  // multiple apps.
  iosWebView: 'private',
};

export const iosNativeConfigProd: IonicAuthOptions = {
  // the auth provider
  authConfig: 'azure',
  // The platform which we are running on
  platform: 'cordova',
  // client or application id for provider
  clientID: '0ad02f86-5557-4811-9563-365ecb8233e7',
  // the discovery url for the provider
  // OpenID configuration
  discoveryUrl:
    'https://onemunters.b2clogin.com/onemunters.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SONAR_EXTERNAL_SIGNIN',
  // the URI to redirect to after log in
  redirectUri: 'com.ionicframework.SonarApp://login',
  // requested scopes from provider
  scope:
    'https://onemunters.onmicrosoft.com/SonarApiAuth/access_as_user openid profile Offline_access',
  // the URL to redirect to after log out
  logoutUrl: 'com.ionicframework.SonarApp://login',
  // The type of iOS webview to use. 'shared' will use a webview that can share session/cookies
  // on iOS to provide SSO across multiple apps but will cause a prompt for the user which asks them
  // to confirm they want to share site data with the app. 'private' uses a webview which will not
  // prompt the user but will not be able to share session/cookie data either for true SSO across
  // multiple apps.
  iosWebView: 'private',
};

export const azureWebConfigDemo: IonicAuthOptions = {
  // the auth provider
  authConfig: 'azure',
  // The platform which we are running on
  platform: 'web',
  // client or application id for provider
  clientID: '0ad02f86-5557-4811-9563-365ecb8233e7',
  // the discovery url for the provider
  // OpenID configuration
  discoveryUrl:
    'https://onemunters.b2clogin.com/onemunters.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SONAR_EXTERNAL_SIGNIN',
  // the URI to redirect to after log in
  redirectUri: 'https://sonardemo.mtech-systems.com/login',
  // requested scopes from provider
  scope:
    'https://onemunters.onmicrosoft.com/SonarApiAuth/access_as_user openid profile Offline_access',
  // the URL to redirect to after log out
  logoutUrl: 'https://sonardemo.mtech-systems.com/login',
  // Show provider login in either current window or new tab
  // implicitLogin: 'CURRENT',
};

export const androidNativeConfigDemo: IonicAuthOptions = {
  // the auth provider
  authConfig: 'azure',
  // The platform which we are running on
  platform: 'cordova',
  // client or application id for provider
  clientID: '0ad02f86-5557-4811-9563-365ecb8233e7',
  // the discovery url for the provider
  // OpenID configuration
  discoveryUrl:
    'https://onemunters.b2clogin.com/onemunters.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SONAR_EXTERNAL_SIGNIN',
  // the URI to redirect to after log in
  redirectUri: 'sonar://app/login',
  // requested scopes from provider
  scope:
    'https://onemunters.onmicrosoft.com/SonarApiAuth/access_as_user openid profile Offline_access',
  // the URL to redirect to after log out
  logoutUrl: 'sonar://app/login',
  // The type of iOS webview to use. 'shared' will use a webview that can share session/cookies
  // on iOS to provide SSO across multiple apps but will cause a prompt for the user which asks them
  // to confirm they want to share site data with the app. 'private' uses a webview which will not
  // prompt the user but will not be able to share session/cookie data either for true SSO across
  // multiple apps.
  iosWebView: 'private',
};

export const iosNativeConfigDemo: IonicAuthOptions = {
  // the auth provider
  authConfig: 'azure',
  // The platform which we are running on
  platform: 'cordova',
  // client or application id for provider
  clientID: '0ad02f86-5557-4811-9563-365ecb8233e7',
  // the discovery url for the provider
  // OpenID configuration
  discoveryUrl:
    'https://onemunters.b2clogin.com/onemunters.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SONAR_EXTERNAL_SIGNIN',
  // the URI to redirect to after log in
  redirectUri: 'com.ionicframework.SonarApp://login',
  // requested scopes from provider
  scope:
    'https://onemunters.onmicrosoft.com/SonarApiAuth/access_as_user openid profile Offline_access',
  // the URL to redirect to after log out
  logoutUrl: 'com.ionicframework.SonarApp://login',
  // The type of iOS webview to use. 'shared' will use a webview that can share session/cookies
  // on iOS to provide SSO across multiple apps but will cause a prompt for the user which asks them
  // to confirm they want to share site data with the app. 'private' uses a webview which will not
  // prompt the user but will not be able to share session/cookie data either for true SSO across
  // multiple apps.
  iosWebView: 'private',
};

// export const environment = {
//   production: false,
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
