import { UnitConversionService } from "@sonar/core/unit-conversion.service";
import { SonarData } from "./sonar-data";
import { SonarDataType } from "@sonar/shared/sensor-type";
import { NumberSymbol } from "@angular/common";


export class CarbonDioxideSensor extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;
  isSensor = true;
  sonarDataType: SonarDataType = SonarDataType.CarbonDioxide;
  
  statistic = 'carbon-dioxide';
  iconName = 'carbon-dioxide';
  sensorStyleClass = 'ami-carbon-dioxide';
  decimalPlaces = '1.2-2';
  maxSensorCount = 4;
  labelKey = 'KPIS.CARBON_DIOXIDE';

  convertUnit = (value: number, u: UnitConversionService) => value;
  getSensorUnit = (unitConversionService: UnitConversionService): string => unitConversionService.getCarbonDioxideUnit();
  getPropertyNames = (prefix?: string, suffix?: string): string[] => this.generatePropertyNames(prefix, 'carbonDioxide', suffix);
}
