import { Injectable, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MeasurementSystem } from '@sonar/shared/measurement-systems';
import { UserSettingsService } from '../user-settings/user-settings.service';
import { UnitConversionService } from '../unit-conversion.service';
import { SensorType, SonarDataType } from '@sonar/shared/sensor-type';

@Pipe({
  name: 'weight',
})
@Injectable()
export class WeightPipe implements OnDestroy, PipeTransform {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  private measurementSystem: MeasurementSystem;

  constructor(
    private readonly userSettingsService: UserSettingsService,
    private readonly unitConversionService: UnitConversionService,
    private readonly translateService: TranslateService
  ) {
    this.userSettingsService.$settings
      .pipe(
        takeUntil(this.destroy$),
        filter((settings) => !!settings)
      )
      .subscribe((settings) => {
        this.measurementSystem = settings.measurementSystem;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  transform(
    item: number,
    sensorType: SensorType | SonarDataType,
    digits?: string,
    showUnits?: boolean
  ) {
    if (isNaN(item)) {
      return;
    }

    const numberPipe = new DecimalPipe(
      this.translateService.currentLang || this.translateService.defaultLang
    );
    if (!digits) {
      digits = '1.2-2';
    }
    let weight;
    let sourceUnit: string;

    switch (sensorType) {
      case SensorType.RuntimeConsumption:
      case SensorType.BinFeedAmount:
        sourceUnit = 'kg';
        break;
      case SensorType.Weight:
        sourceUnit = 'g';
        if (this.measurementSystem === MeasurementSystem.Metric) {
          digits = '1.3-3';
        }
        break;
      default:
        sourceUnit = undefined;
        break;
    }
    const destUnit =
      sensorType === SensorType.BinFeedAmount ||
      sensorType === SensorType.RuntimeConsumption
        ? this.unitConversionService.getFeedBinAmountUnit()
        : this.unitConversionService.getBirdWeightUnit();

    const units = showUnits ? destUnit : '';
    if (sourceUnit === destUnit) {
      weight = numberPipe.transform(item, digits);
    }
    if (sourceUnit === 'kg' && destUnit === 'lbs') {
      weight = numberPipe.transform(
        this.unitConversionService.convertWeight(item),
        digits
      );
    }
    if (sourceUnit === 'g' && destUnit === 'lbs') {
      weight = numberPipe.transform(
        this.unitConversionService.convertWeight(item / 1000),
        digits
      );
    }
    if (sourceUnit === 'g' && destUnit === 'kg') {
      weight = numberPipe.transform(item / 1000, digits);
    }
    if (showUnits && weight) {
      return `${weight} ${units}`;
    }
    return weight;
  }
}
