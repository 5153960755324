<div class="farm-analytics-tabs">
  <ion-segment
    class="tabs-detail"
    data-pendoid="farm-analytics-tabs"
    data-testid="farm-analytics-tabs"
    [value]="tab"
    (ionChange)="onAnalyticsTabsChange($event)"
  >
    <div class="tabs-wrapper">
      <ion-segment-button
        data-pendoid="farm-analytics-comparison-tab"
        data-testid="farm-analytics-comparison-tab"
        [value]="flockSelection.FlocksComparison"
      >
        <div class="tabs-title">
          {{
            "FARM_DETAIL_PAGE.ANALYTICS_SEGMENTS.FLOCKS_COMPARISON" | translate
          }}
        </div>
      </ion-segment-button>
      <ion-segment-button
        data-pendoid="farm-analytics-current-tab"
        data-testid="farm-analytics-current-tab"
        [value]="flockSelection.Current"
      >
        <div class="tabs-title">
          {{ "FARM_DETAIL_PAGE.ANALYTICS_SEGMENTS.ACTIVE_FLOCK" | translate }}
        </div>
      </ion-segment-button>
      <ion-segment-button
        data-pendoid="farm-analytics-last-sold-tab"
        data-testid="farm-analytics-last-sold-tab"
        [value]="flockSelection.LastSold"
      >
        <div class="tabs-title">
          {{ "FARM_DETAIL_PAGE.ANALYTICS_SEGMENTS.LAST_SOLD" | translate }}
        </div>
      </ion-segment-button>
    </div>
  </ion-segment>
</div>

<ng-container *ngIf="!showFlockComparisonInfo">
  <ion-list-header>
    <ion-label
      data-pendoid="farm-analytics-flock-no-subtitle"
      data-testid="farm-analytics-flock-no-subtitle"
      class="caption"
    >
      {{ "FARM_DETAIL_PAGE.ENTITY_NO" | translate }}
      {{ farmAnalytics?.entityNo }}
    </ion-label>
    <ion-label
      data-pendoid="farm-analytics-last-updated-subtitle"
      data-testid="farm-analytics-last-updated-subtitle"
      class="caption"
    >
      {{ "FARM_DETAIL_PAGE.LAST_UPDATED" | translate }}
      {{ formattedLastUpdatedDate }}
    </ion-label>
    <ion-label
      data-pendoid="farm-analytics-update-notice-subtitle"
      data-testid="farm-analytics-update-notice-subtitle"
      class="caption"
    >
      {{ "FARM_DETAIL_PAGE.NOTICE" | translate }}
    </ion-label>
    <ng-container *ngIf="!farmAnalyticsAvailable && !isBusy">
      <ion-label
        data-pendoid="farm-analytics-no-flock-message"
        data-testid="farm-analytics-no-flock-message"
        class="caption"
      >
        {{ "NO_FLOCK_FOUND" | translate }}</ion-label
      >
    </ng-container>
  </ion-list-header>
  <ng-container *ngIf="farmAnalytics?.processingData">
    <div class="kpi-cards">
      <div
        data-pendoid="farm-analytics-processing-weight-card"
        data-testid="farm-analytics-processing-weight-card"
        class="kpi"
      >
        <span
          data-pendoid="farm-analytics-processing-weight-card-value"
          data-testid="farm-analytics-processing-weight-card-value"
          class="value"
        >
          {{
            farmAnalytics?.processingData?.processingAverageWeight
              | weight: sensorType.Weight : "1.2-2" : true
          }}
        </span>
        <span
          data-pendoid="farm-analytics-processing-weight-card-legend"
          data-testid="farm-analytics-processing-weight-card-legend"
          class="legend"
        >
          {{ "FARM_ANALYTICS.FINAL_PROCESSING" | translate }}
          <br />
          {{ "FARM_ANALYTICS.AVG_WEIGHT" | translate }}
        </span>
      </div>
      <div
        data-pendoid="farm-analytics-condemn-weight-card"
        data-testid="farm-analytics-condemn-weight-card"
        class="kpi"
      >
        <span
          data-pendoid="farm-analytics-condemn-weight-card-value"
          data-testid="farm-analytics-condemn-weight-card-value"
          class="value"
        >
          {{
            farmAnalytics?.processingData?.totalFarmCondemnationWeight
              | weight: sensorType.Weight : "1.2-2" : true
          }}
        </span>
        <span
          data-pendoid="farm-analytics-condemn-weight-card-legend"
          data-testid="farm-analytics-condemn-weight-card-legend"
          class="legend"
        >
          {{ "FARM_ANALYTICS.FARM_CONDEMNATION" | translate }}
          <br />
          {{ "FARM_ANALYTICS.TOTAL_WEIGHT" | translate }}
        </span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="showAnalyticsFlockPerformanceChart">
    <mt-card-table
      class="flock__card-table"
      data-pendoid="farm-analytics-flock-benchmarking-table"
      data-testid="farm-analytics-flock-benchmarking-table"
      [title]="'FARM_ANALYTICS.FLOCK_PERFORMANCE' | translate"
      [cardTableColumnArgs]="flockPerformanceArgs"
      [dataSource]="flockPerformanceTableData"
    ></mt-card-table>
    <mt-card-table
      data-pendoid="farm-analytics-benchmarking-score-table"
      data-testid="farm-analytics-benchmarking-score-table"
      [title]="'FARM_ANALYTICS.BECHMARKING_SCORE' | translate"
      [cardTableColumnArgs]="benchmarkingScoreColumns"
      [dataSource]="benchmarkingScoreData"
    ></mt-card-table>
    <div class="active-flock__shared-bins">
      <mt-card-table2
        data-pendoid="farm-analytics-shared-bins-table"
        data-testid="farm-analytics-shared-bins-table"
        [columns]="sharedBinsTableArgs"
        [dataSource]="binsDataSource"
        [loaded]="loaded"
        (iconClicked)="onBinClicked($event)"
      >
      </mt-card-table2>
    </div>
  </ng-container>
  <div class="farm-analytics__swiper">
    <ng-container *ngIf="showAnalyticsFlockPerformanceChart">
      <mt-swiper [enablePagination]="true">
        <mt-swiper-slide>
          <interactive-card-chart
            [cardChartArgs]="percentDepletionCardChartArgs"
            [cardChartMetadata]="{ title: 'FARM_ANALYTICS.PERCENT_DEPLETION' }"
            [chartSettings]="percentDepletionChartSettings"
            [loaded]="percentDepletionChartLoaded"
            [showFullScreen]="showFullScreen"
            (fullScreenClicked)="onShowFullScreenClicked('depletion')"
          ></interactive-card-chart>
        </mt-swiper-slide>
        <mt-swiper-slide>
          <interactive-card-chart
            [cardChartArgs]="averageWeightCardChartArgs"
            [cardChartMetadata]="{ title: 'FARM_ANALYTICS.AVERAGE_WEIGHT' }"
            [chartSettings]="averageWeightChartSettings"
            [loaded]="averageWeightChartLoaded"
            [showFullScreen]="showFullScreen"
            (fullScreenClicked)="onShowFullScreenClicked('averageWeight')"
          ></interactive-card-chart>
        </mt-swiper-slide>
        <mt-swiper-slide>
          <interactive-card-chart
            [cardChartArgs]="feedConsumedPerBirdCardChartArgs"
            [cardChartMetadata]="{
              title: 'FARM_ANALYTICS.FEED_CONSUMED_PER_BIRD'
            }"
            [chartSettings]="feedConsumedPerBirdChartSettings"
            [loaded]="feedConsumedPerBirdChartLoaded"
            [showFullScreen]="showFullScreen"
            (fullScreenClicked)="onShowFullScreenClicked('feedConsumedPerBird')"
          ></interactive-card-chart>
        </mt-swiper-slide>
      </mt-swiper>
    </ng-container>
    <ng-container *ngIf="showTabLastSold">
      <mt-swiper [enablePagination]="true">
        <mt-swiper-slide>
          <interactive-card-chart
            [cardChartArgs]="percentDepletionCardChartArgs"
            [cardChartMetadata]="{ title: 'FARM_ANALYTICS.PERCENT_DEPLETION' }"
            [chartSettings]="percentDepletionChartSettings"
            [loaded]="percentDepletionChartLoaded"
            [showFullScreen]="showFullScreen"
            (fullScreenClicked)="onShowFullScreenClicked('depletion')"
          ></interactive-card-chart>
        </mt-swiper-slide>
        <mt-swiper-slide>
          <interactive-card-chart
            [cardChartArgs]="averageWeightCardChartArgs"
            [cardChartMetadata]="{ title: 'FARM_ANALYTICS.AVERAGE_WEIGHT' }"
            [chartSettings]="averageWeightChartSettings"
            [loaded]="averageWeightChartLoaded"
            [showFullScreen]="showFullScreen"
            (fullScreenClicked)="onShowFullScreenClicked('averageWeight')"
          ></interactive-card-chart>
        </mt-swiper-slide>
        <mt-swiper-slide>
          <interactive-card-chart
            [cardChartArgs]="feedConsumedPerBirdCardChartArgs"
            [cardChartMetadata]="{
              title: 'FARM_ANALYTICS.FEED_CONSUMED_PER_BIRD'
            }"
            [chartSettings]="feedConsumedPerBirdChartSettings"
            [loaded]="feedConsumedPerBirdChartLoaded"
            [showFullScreen]="showFullScreen"
            (fullScreenClicked)="onShowFullScreenClicked('feedConsumedPerBird')"
          ></interactive-card-chart>
        </mt-swiper-slide>
      </mt-swiper>
    </ng-container>
  </div>
  <mt-card-table
    data-pendoid="farm-analytics-weekly-performance-table"
    data-testid="farm-analytics-weekly-performance-table"
    [title]="'FARM_ANALYTICS.WEEKLY_PERFORMANCE' | translate"
    [cardTableColumnArgs]="weeklyFlockPerformanceArgs"
    [dataSource]="weeklyFlockPerformance"
    [loaded]="loaded"
  ></mt-card-table>
</ng-container>
<ng-container *ngIf="showFlockComparisonInfo">
  <div class="flocks-comparison">
    <div class="flocks-comparison__title">
      <div
        data-pendoid="farm-analytics-flock-comparison-table-current-title"
        data-testid="farm-analytics-flock-comparison-table-current-title"
        class="flocks-comparison__title-current"
      >
        {{ "FARM_DETAIL_PAGE.ANALYTICS_SEGMENTS.CURRENT" | translate }}
      </div>
      <div
        data-pendoid="farm-analytics-flock-comparison-table-age-title"
        data-testid="farm-analytics-flock-comparison-table-age-title"
        class="flocks-comparison__title-age"
      >
        {{ "FARM_DETAIL_PAGE.ANALYTICS_SEGMENTS.AGE" | translate }} :
        {{ flockAge }}
      </div>
    </div>
    <div class="flocks-comparison__current">
      <mt-card-table2
        *ngIf="currentTableDataSource?.length > 0"
        data-pendoid="farm-analytics-flock-comparison-table"
        data-testid="farm-analytics-flock-comparison-table"
        [columns]="currentTableArgs"
        [dataSource]="currentTableDataSource"
        [loaded]="loaded"
      >
      </mt-card-table2>
    </div>
    <div class="flocks-comparison__title">
      <div
        data-pendoid="farm-analytics-flock-comparison-lof-title"
        data-testid="farm-analytics-flock-comparison-lof-title"
        class="flocks-comparison__title-flock"
      >
        {{ "FARM_DETAIL_PAGE.ANALYTICS_SEGMENTS.LIFE_OF_FLOCK" | translate }}
      </div>
    </div>
    <div class="flocks-comparison__flock">
      <div class="flocks-comparison__flock-dropdown">
        <button
          data-pendoid="farm-analytics-flock-comparison-chart-statistic-dropdown-button"
          data-testid="farm-analytics-flock-comparison-chart-statistic-dropdown-button"
          (click)="statistics?.open()"
        >
          {{ currentStatisticSelected | translate }}
          <div class="flocks-comparison__flock-dropdown-icon">
            <i class="ami-drop-down"></i>
          </div>
        </button>
        <ion-item>
          <ion-select
            #statistics
            data-pendoid="farm-analytics-flock-comparison-statistic-dropdown"
            data-testid="farm-analytics-flock-comparison-statistic-dropdown"
            interface="action-sheet"
            [value]="flockStatisticType"
            (ionChange)="onChangeFlockStatistic($event)"
          >
            <ng-container
              *ngFor="let currentStatistic of currentStatisticsDropDown"
            >
              <ion-select-option
                [attr.data-pendoid]="
                  'farm-analytics-flock-comparison-' +
                  currentStatistic.label +
                  '-dropdown-option'
                "
                [attr.data-testid]="
                  'farm-analytics-flock-comparison-' +
                  currentStatistic.label +
                  '-dropdown-option'
                "
                [value]="currentStatistic.value"
              >
                {{ currentStatistic.label | translate }}
              </ion-select-option>
            </ng-container>
          </ion-select>
        </ion-item>
      </div>
      <div class="flocks-comparison__flock-life-chart">
        <interactive-card-chart
          #flockComparisonCardChart
          [cardChartArgs]="flockComparisonChartArgs"
          [cardChartMetadata]="{ title: currentStatisticSelected }"
          [chartSettings]="flockComparisonChartSettings"
          [kpis]="kpiChartStatisticsComparison"
          [loaded]="loadedFlockComparisonChart"
          [showFullScreen]="showFullScreen"
          (fullScreenClicked)="
            onShowFullScreenStatisticClicked(statisticFullScreenSelected)
          "
        ></interactive-card-chart>
      </div>
      <div class="flocks-comparison__flock-life-table">
        <mt-card-table2
          data-pendoid="farm-analytics-flock-comparison-lof-table"
          data-testid="farm-analytics-flock-comparison-lof-table"
          [columns]="lifeOfFlockTableArgs"
          [dataSource]="lifeOfFlockTableDataSource"
          [loaded]="loaded"
        ></mt-card-table2>
      </div>
    </div>
  </div>
</ng-container>
