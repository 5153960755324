import { UnitConversionService } from "@sonar/core/unit-conversion.service";
import { SonarData } from "./sonar-data";
import { SonarDataType } from "@sonar/shared/sensor-type";
import { NumberSymbol } from "@angular/common";

export class RuntimeConsumptionSensor extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;
  isSensor = true;
  sonarDataType: SonarDataType = SonarDataType.RuntimeConsumption;
  
  statistic = 'runtime-consumption';
  iconName = 'feed';
  sensorStyleClass = 'ami-feed';
  decimalPlaces = '1.0-0';
  maxSensorCount = 1;
  labelKey = 'KPIS.RUNTIME_CONSUMPTION';

  getPropertyNames = (prefix?: string, suffix?: string): string[] => this.generatePropertyNames(prefix, 'runtimeConsumption', suffix);
  getSensorUnit = (unitConversionService: UnitConversionService): string => unitConversionService.getFeedBinAmountUnit();
  convertUnit(value: number, unitConversionService: UnitConversionService): number {
    if (value === 0) return value;
    return unitConversionService.convertWeight(value);
  }
}
