/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/no-namespace */

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { UnitConversionService } from '../core/unit-conversion.service';
import { TemperatureSensor } from '@sonar/farms/sensor/sonar-data/temperature-sensor';
import { HumiditySensor } from '@sonar/farms/sensor/sonar-data/humidity-sensor';
import { WaterConsumptionSensor } from '@sonar/farms/sensor/sonar-data/water-consumption-sensor';
import { WeightSensor } from '@sonar/farms/sensor/sonar-data/weight-sensor';
import { BinFeedAmountSensor } from '@sonar/farms/sensor/sonar-data/bin-feed-amount-sensor';
import { AmmoniaSensor } from '@sonar/farms/sensor/sonar-data/ammonia-sensor';
import { HealthSensor } from '@sonar/farms/sensor/sonar-data/health-sensor';
import { AirFlowSensor } from '@sonar/farms/sensor/sonar-data/air-flow-sensor';
import { CarbonDioxideSensor } from '@sonar/farms/sensor/sonar-data/carbon-dioxide-sensor';
import { RuntimeConsumptionSensor } from '@sonar/farms/sensor/sonar-data/runtime-consumption-sensor';
import { LightSensor } from '@sonar/farms/sensor/sonar-data/light-sensor';
import { MovementSensor } from '@sonar/farms/sensor/sonar-data/movement-sensor';
import { DistressSensor } from '@sonar/farms/sensor/sonar-data/distress-sensor';
import { DailyGasConsumptionSensor } from '@sonar/farms/sensor/sonar-data/daily-gas-consumption-sensor';
import { DailyPowerConsumptionSensor } from '@sonar/farms/sensor/sonar-data/daily-power-consumption-sensor';
import { OutsideTemperatureSensor } from '@sonar/farms/sensor/sonar-data/outside-temperature-sensor';
import { EggsProducedSensor } from '@sonar/farms/sensor/sonar-data/eggs-produced-sensor';
import { StaticPressureSensor } from '@sonar/farms/sensor/sonar-data/static-pressure-sensor';
import { AnimalWelfareIndexSensor } from '@sonar/farms/sensor/sonar-data/animal-welfare-index-sensor';
import { OutsideHumiditySensor } from '@sonar/farms/sensor/sonar-data/outside-humidity-sensor';
import { SonarData } from '../farms/sensor/sonar-data/sonar-data';
import { DepletionSonarData } from '@sonar/farms/sensor/sonar-data/depletion-sonar-data';
import { PercentDepletionSonarData } from '@sonar/farms/sensor/sonar-data/percent-depletion-sonar-data';
import { FeedPerBirdSonarData } from '@sonar/farms/sensor/sonar-data/feed-per-bird-sonar-data';
import { WeightDifferenceSonarData } from '@sonar/farms/sensor/sonar-data/weight-difference-sonar-data';
import { NumberSymbol } from '@angular/common';
import { UnknownSonarData } from '@sonar/farms/sensor/sonar-data/unknown-sonar-data';
import { ProjectedWeightSonarData } from '@sonar/farms/sensor/sonar-data/projected-weight-sonar-data';

/* eslint-disable @typescript-eslint/naming-convention */
export enum SensorType {
  Temperature = 0,
  Humidity = 1,
  WaterConsumption = 2,
  Weight = 3,
  BinFeedAmount = 4,
  Ammonia = 5,
  Health = 6,
  AirFlow = 7,
  CarbonDioxide = 8,
  RuntimeConsumption = 9,
  Light = 10,
  Movement = 11,
  Distress = 12,
  DailyGasConsumption = 13,
  DailyPowerConsumption = 14,
  OutsideTemperature = 15,
  EggsProduced = 16,
  StaticPressure = 17,
  AnimalWelfareIndex = 18,
  OutsideHumidity = 19,
}

export enum SonarDataType {
  Temperature = SensorType.Temperature,
  Humidity = SensorType.Humidity,
  Weight = SensorType.Weight,
  BinFeedAmount = SensorType.BinFeedAmount,
  Ammonia = SensorType.Ammonia,
  Health = SensorType.Health,
  AirFlow = SensorType.AirFlow,
  CarbonDioxide = SensorType.CarbonDioxide,
  RuntimeConsumption = SensorType.RuntimeConsumption,
  Light = SensorType.Light,
  Movement = SensorType.Movement,
  Distress = SensorType.Distress,
  DailyGasConsumption = SensorType.DailyGasConsumption,
  DailyPowerConsumption = SensorType.DailyPowerConsumption,
  OutsideTemperature = SensorType.OutsideTemperature,
  EggsProduced = SensorType.EggsProduced,
  StaticPressure = SensorType.StaticPressure,
  AnimalWelfareIndex = SensorType.AnimalWelfareIndex,
  OutsideHumidity = SensorType.OutsideHumidity,
  WaterConsumption = SensorType.WaterConsumption,

  //Non sensor types
  None = 0,
  Depletion = 998,
  PercentDepletion = 999,
  FeedPerBird = 1000,
  WeightDifference = 1001,
  ProjectedWeightByFc = 1002,
}

export function getSonarDataClass(sonarDataType: SonarDataType): SonarData {
  switch (sonarDataType) {
    case SonarDataType.Temperature:
      return new TemperatureSensor();
    case SonarDataType.Humidity:
      return new HumiditySensor();
    case SonarDataType.WaterConsumption:
      return new WaterConsumptionSensor();
    case SonarDataType.Weight:
      return new WeightSensor();
    case SonarDataType.BinFeedAmount:
      return new BinFeedAmountSensor();
    case SonarDataType.Ammonia:
      return new AmmoniaSensor();
    case SonarDataType.Health:
      return new HealthSensor();
    case SonarDataType.AirFlow:
      return new AirFlowSensor();
    case SonarDataType.CarbonDioxide:
      return new CarbonDioxideSensor();
    case SonarDataType.RuntimeConsumption:
      return new RuntimeConsumptionSensor();
    case SonarDataType.Light:
      return new LightSensor();
    case SonarDataType.Movement:
      return new MovementSensor();
    case SonarDataType.Distress:
      return new DistressSensor();
    case SonarDataType.DailyGasConsumption:
      return new DailyGasConsumptionSensor();
    case SonarDataType.DailyPowerConsumption:
      return new DailyPowerConsumptionSensor();
    case SonarDataType.OutsideTemperature:
      return new OutsideTemperatureSensor();
    case SonarDataType.EggsProduced:
      return new EggsProducedSensor();
    case SonarDataType.StaticPressure:
      return new StaticPressureSensor();
    case SonarDataType.AnimalWelfareIndex:
      return new AnimalWelfareIndexSensor();
    case SonarDataType.OutsideHumidity:
      return new OutsideHumiditySensor();

    // Non-sensor types
    case SonarDataType.Depletion:
      return new DepletionSonarData();
    case SonarDataType.PercentDepletion:
      return new PercentDepletionSonarData();
    case SonarDataType.FeedPerBird:
      return new FeedPerBirdSonarData();
    case SonarDataType.WeightDifference:
      return new WeightDifferenceSonarData();
    case SonarDataType.ProjectedWeightByFc:
      return new ProjectedWeightSonarData();

    default:
      console.error(`Unknown SonarDataType: ${sonarDataType}`);
      return new UnknownSonarData();
  }
}

export namespace SonarDataType {
  export function toSensorType(sonarDataType: SonarDataType): SensorType {
    if (getSonarDataClass(sonarDataType).isSensor) {
      return sonarDataType as unknown as SensorType;
    } else {
      console.error(`SonarDataType ${sonarDataType} is not a sensor type`);
      return -1 as unknown as SensorType;
    }
  }

  export function getNumberSymbol(sonarDataType: SonarDataType): NumberSymbol {
    return getSonarDataClass(sonarDataType).numberSymbol;
  }

  export function isSensorType(
    sonarDataType: SonarDataType | SensorType
  ): boolean {
    try {
      const sonarDataClass = getSonarDataClass(sonarDataType as any);
      return sonarDataClass.isSensor;
    } catch {
      return false;
    }
  }

  export function getDecimalPlaces(sonarDataType: SonarDataType): string {
    return getSonarDataClass(sonarDataType).decimalPlaces;
  }

  export function getNumericDecimalPlaces(
    sonarDataType: SonarDataType
  ): number {
    return getSonarDataClass(sonarDataType).getNumericDecimalPlaces();
  }

  export function toString(sonarDataType: SonarDataType): string {
    return getSonarDataClass(sonarDataType).toString();
  }

  export function maxSensorCount(sonarDataType: SonarDataType): number {
    return getSonarDataClass(sonarDataType).maxSensorCount;
  }

  export function getPropertyNames(
    sonarDataType: SonarDataType,
    prefix?: string,
    suffix?: string
  ): string[] {
    return getSonarDataClass(sonarDataType).getPropertyNames(prefix, suffix);
  }

  export async function toLabelString(
    translateService: TranslateService,
    sensorType: SonarDataType
  ) {
    return await getSonarDataClass(sensorType).toLabelString(translateService);
  }

  export function toLabelKey(sonarDataType: SonarDataType): string {
    return getSonarDataClass(sonarDataType).labelKey;
  }

  export function parse(sonarDataName: string): SonarDataType {
    return SonarData.parse(sonarDataName);
  }

  export function parseStatistic(statistic: string): SonarDataType {
    return SonarData.parseStatistic(statistic);
  }

  export function toStatistic(sonarDataType: SonarDataType): string {
    return getSonarDataClass(sonarDataType).statistic;
  }

  export function toIconName(sonarDataType: SonarDataType): string {
    return getSonarDataClass(sonarDataType).iconName;
  }

  export function toSensorStyleClass(sonarDataType: SonarDataType): string {
    return getSonarDataClass(sonarDataType).sensorStyleClass;
  }

  export function getSensorUnit(
    sonarDataType: SonarDataType,
    unitConversionService: UnitConversionService
  ): string {
    return getSonarDataClass(sonarDataType).getSensorUnit(
      unitConversionService
    );
  }

  export function convertUnit(
    sonarDataType: SonarDataType,
    value: number,
    unitConversionService: UnitConversionService
  ): number {
    return getSonarDataClass(sonarDataType).convertUnit(
      value,
      unitConversionService
    );
  }

  export function getValuesForSonarDataType(
    objectsWithSensorProperties: any[],
    sonarDataType: SonarDataType,
    suffixes: string[] = []
  ): number[] {
    return getSonarDataClass(sonarDataType).getValuesForSonarDataType(
      objectsWithSensorProperties,
      sonarDataType,
      suffixes
    );
  }
}

/** @deprecated Use SonarData class or SonarDataType functions instead */
export namespace SensorType {
  export function getDecimalPlaces(sensorType: SensorType): string {
    return getSonarDataClass(sensorType as unknown as SonarDataType)
      .decimalPlaces;
  }

  export function getNumericDecimalPlaces(sensorType: SensorType): number {
    return getSonarDataClass(
      sensorType as unknown as SonarDataType
    ).getNumericDecimalPlaces();
  }

  export function toString(sensorType: SensorType): string {
    return getSonarDataClass(sensorType as unknown as SonarDataType).toString();
  }

  export function maxSensorCount(sensorType: SensorType | SonarDataType): number {
    return getSonarDataClass(sensorType as unknown as SonarDataType)
      .maxSensorCount;
  }

  export function getPropertyNames(
    sensorType: SensorType,
    prefix?: string,
    suffix?: string
  ): string[] {
    return getSonarDataClass(
      sensorType as unknown as SonarDataType
    ).getPropertyNames(prefix, suffix);
  }

  export async function toLabelString(
    translateService: TranslateService,
    sensorType: SensorType
  ) {
    return await getSonarDataClass(
      sensorType as unknown as SonarDataType
    ).toLabelString(translateService);
  }

  export function toLabelKey(sensorType: SensorType): string {
    return getSonarDataClass(sensorType as unknown as SonarDataType).labelKey;
  }

  export function parse(sensorName: string): SensorType {
    return SonarData.parse(sensorName) as unknown as SensorType;
  }

  export function parseStatistic(statistic: string): SensorType {
    return SonarData.parseStatistic(statistic) as unknown as SensorType;
  }

  export function toStatistic(sensorType: SensorType): string {
    return getSonarDataClass(sensorType as unknown as SonarDataType).statistic;
  }

  export function toIconName(sensorType: SensorType): string {
    return getSonarDataClass(sensorType as unknown as SonarDataType).iconName;
  }

  export function toSensorStyleClass(sensorType: SensorType): string {
    return getSonarDataClass(sensorType as unknown as SonarDataType)
      .sensorStyleClass;
  }

  export function getSensorUnit(
    sensorType: SensorType,
    unitConversionService: UnitConversionService
  ): string {
    return getSonarDataClass(
      sensorType as unknown as SonarDataType
    ).getSensorUnit(unitConversionService);
  }

  export function convertUnit(
    sensorType: SensorType,
    value: number,
    unitConversionService: UnitConversionService
  ): number {
    return getSonarDataClass(
      sensorType as unknown as SonarDataType
    ).convertUnit(value, unitConversionService);
  }

  export function getValuesForSensorType(
    objectsWithSensorProperties: any[],
    sensorType: SensorType,
    suffixes: string[] = []
  ): number[] {
    return getSonarDataClass(
      sensorType as unknown as SonarDataType
    ).getValuesForSonarDataType(
      objectsWithSensorProperties,
      sensorType as unknown as SonarDataType,
      suffixes
    );
  }
}
