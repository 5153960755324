import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { FarmType } from '@sonar/shared/farm/farm-type';

@Pipe({
  name: 'age',
})
@Injectable()
export class AgePipe implements PipeTransform {
  transform(age: number, farmType: FarmType) {
    if (FarmType.isBimFarmType(farmType)) {
      const weeks = Math.floor(age / 7);
      return (weeks + (age % 7) / 10).toFixed(1);
    }
    return age;
  }
}
