import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { UnitConversionService } from '../unit-conversion.service';
import { SensorType } from '@sonar/shared/sensor-type';

@Pipe({
  name: 'pressure',
})
@Injectable()
export class PressurePipe implements PipeTransform {
  constructor(
    private readonly unitConversionService: UnitConversionService,
    private readonly translateService: TranslateService
  ) {}

  transform(item: number, showUnits?: boolean) {
    const numberPipe = new DecimalPipe(
      this.translateService.currentLang || this.translateService.defaultLang
    );
    const value = numberPipe.transform(
      this.unitConversionService.convertPressure(item),
      SensorType.getDecimalPlaces(SensorType.StaticPressure)
    );
    if (showUnits) {
      return `${value} ${this.unitConversionService.getStaticPressureUnit()}`;
    }
    return value;
  }
}
