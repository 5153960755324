<div class="highcharts__wrapper {{ orientation }}">
  <div
    *ngIf="showFullScreenLegends"
    class="highcharts__fullscreen-legends"
  >
    <div class="highcharts__fullscreen-legends-header">
      <span class="button">{{ 'LABELS' | translate }}</span>
      <i
        class="ami ami-cancel ami-xs"
        (click)="onFullScreenLegendsClose()"
      ></i>
    </div>
    <div
      *ngIf="customLegends?.length"
      class="highcharts__fullscreen-legends-list"
    >
      <ul>
        <ng-container *ngFor="let legend of customLegends">
          <li
            class="caption custom__legend"
            [class.hide]="
              orientation === 'portrait' &&
              (
                legend.caption === 'Max Target' ||
                legend.caption === 'Min Target'
              )
            "
            [class.marker]="legend.isMarker"
            [class.selected]="legend.selected"
            (click)="onCustomLegendClick(legend)"
          >
            <div
              class="custom__legend-mark"
              [style.backgroundColor]="legend.color"
            ></div>
            <span>{{ legend.caption }}</span>
          </li>
        </ng-container>
      </ul>
      <ul *ngIf="orientation === 'portrait'">
        <ng-container *ngFor="let legend of customLegends">
          <li
            class="caption custom__legend"
            [class.hide]="
              !(
                legend.caption === 'Max Target' ||
                legend.caption === 'Min Target'
              )
            "
            [class.marker]="legend.isMarker"
            [class.selected]="legend.selected"
            (click)="onCustomLegendClick(legend)"
          >
            <div
              class="custom__legend-mark"
              [style.backgroundColor]="legend.color"
            ></div>
            <span>{{ legend.caption }}</span>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <ng-container *ngIf="loaded">
    <div
      #highchartsContent
      class="highcharts__chart"
      [class.highcharts__chart-legends-open]="showFullScreenLegends && orientation === 'landscape'"
    >
      <highcharts-chart
        *ngIf="chartOptions"
        style="width: 100%;"
        [constructorType]="'stockChart'"
        [Highcharts]="Highcharts"
        [options]="chartOptions"
        (chartInstance)="setChartInstance($event)"
      ></highcharts-chart>
    </div>
  </ng-container>
  <ng-container *ngIf="!loaded">
    <div class="highcharts__chart-loader">
      <ion-skeleton-text
        *ngFor="let column of [].constructor(16)"
        animated
        class="skeleton-loader"
      ></ion-skeleton-text>
    </div>
  </ng-container>
  <ul
    *ngIf="customLegends?.length && !showFullScreen"
    class="highcharts__card-legends"
  >
    <li
      *ngFor="let legend of customLegends"
      class="custom__legend"
      [class.marker]="legend.isMarker"
      [class.selected]="legend.selected"
      [style.color]="legend.color"
      (click)="onCustomLegendClick(legend)"
    >
      <span>{{ legend.caption }}</span>
    </li>
  </ul>
</div>
