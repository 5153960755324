import { UnitConversionService } from '@sonar/core/unit-conversion.service';
import { SonarData } from './sonar-data';
import { SonarDataType } from '@sonar/shared/sensor-type';
import { NumberSymbol } from '@angular/common';

export class WeightSensor extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.Group;

  sonarDataType: SonarDataType = SonarDataType.Weight;

  isSensor = true;
  statistic = 'weight';
  iconName = 'weight';
  sensorStyleClass = 'ami-weight';
  decimalPlaces = '1.2-2';
  maxSensorCount = 4;
  labelKey = 'KPIS.WEIGHT';

  getPropertyNames = (prefix?: string, suffix?: string): string[] =>
    this.generatePropertyNames(prefix, 'birdWeights', suffix);
  getSensorUnit = (unitConversionService: UnitConversionService): string =>
    unitConversionService.getBirdWeightUnit();
  convertUnit(
    value: number,
    unitConversionService: UnitConversionService
  ): number {
    if (value === 0) return value;
    return unitConversionService.convertWeight(value / 1000);
  }
}
