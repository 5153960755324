import { SensorType } from './sensor-type';
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-namespace */
export enum StatisticType {
  PercentDepletion = 0,
  AverageWeight = 1,
  WeightGain = 2,
  FeedPerBird = 3,
  FeedConversion = 4,
  WaterConsumption = 5,
  Temperature = 6,
  Humidity = 7,
  Ammonia = 8,
  CarbonDioxide = 9,
  AirFlow = 10,
  Uniformity = 11,
  CV = 12,
  OutsideTemperature = 13,
  EggsProduced = 14,
  StaticPressure = 15,
  AnimalWelfareIndex = 16,
  OutsideHumidity = 17,
  PowerConsumption = 18,
  GasConsumption = 19,
  Light = 20,
}

export namespace StatisticTypeModel {
  export function toLabelKey(sensorType: number) {
    switch (sensorType) {
      case StatisticType.PercentDepletion:
        return 'KPIS.PERCENT_DEPLETION';
      case StatisticType.AverageWeight:
        return 'KPIS.WEIGHT';
      case StatisticType.WeightGain:
        return 'KPIS.WEIGHT_GAIN';
      case StatisticType.FeedPerBird:
        return 'KPIS.FEED_PER_BIRD';
      case StatisticType.FeedConversion:
        return 'KPIS.FEED_CONVERSION';
      case StatisticType.WaterConsumption:
        return 'KPIS.WATER_CONSUMPTION_BIRD';
      case StatisticType.Temperature:
        return 'KPIS.TEMPERATURE';
      case StatisticType.Humidity:
        return 'KPIS.HUMIDITY';
      case StatisticType.Ammonia:
        return 'KPIS.AMMONIA';
      case StatisticType.CarbonDioxide:
        return 'KPIS.CARBON_DIOXIDE';
      case StatisticType.AirFlow:
        return 'KPIS.AIR_FLOW';
      case StatisticType.Uniformity:
        return 'KPIS.UNIFORMITY';
      case StatisticType.CV:
        return 'KPIS.CV';
      case StatisticType.OutsideTemperature:
        return 'KPIS.OUTSIDE_TEMPERATURE';
      case StatisticType.EggsProduced:
        return 'KPIS.EGGS_PRODUCED';
      case StatisticType.StaticPressure:
        return 'KPIS.STATIC_PRESSURE';
      case StatisticType.AnimalWelfareIndex:
        return 'KPIS.ANIMAL_WELFARE_INDEX';
      case StatisticType.OutsideHumidity:
        return 'KPIS.OUTSIDE_HUMIDITY';
      case StatisticType.PowerConsumption:
        return 'KPIS.POWER_CONSUMPTION';
      case StatisticType.GasConsumption:
        return 'KPIS.GAS_CONSUMPTION';
      case StatisticType.Light:
        return 'KPIS.LIGHT';
    }
  }

  export function toStringName(sensorType: StatisticType): string {
    switch (sensorType) {
      case StatisticType.PercentDepletion:
        return 'percentDepletion';
      case StatisticType.AverageWeight:
        return 'averageWeight';
      case StatisticType.WeightGain:
        return 'weightGain';
      case StatisticType.FeedPerBird:
        return 'feedPerBird';
      case StatisticType.FeedConversion:
        return 'feedConversion';
      case StatisticType.WaterConsumption:
        return 'waterConsumption';
      case StatisticType.Temperature:
        return 'temperature';
      case StatisticType.Humidity:
        return 'humidity';
      case StatisticType.Ammonia:
        return 'ammonia';
      case StatisticType.CarbonDioxide:
        return 'carbonDioxide';
      case StatisticType.AirFlow:
        return 'airFlow';
      case StatisticType.Uniformity:
        return 'uniformity';
      case StatisticType.CV:
        return 'cv';
      case StatisticType.OutsideTemperature:
        return 'outsideTemperature';
      case StatisticType.EggsProduced:
        return 'eggsProduced';
      case StatisticType.StaticPressure:
        return 'staticPressure';
      case StatisticType.AnimalWelfareIndex:
        return 'animalWelfareIndex';
      case StatisticType.AnimalWelfareIndex:
        return 'light';
    }
  }

  export function getStatisticsBySensorType(
    sensorType: SensorType
  ): StatisticType[] {
    switch (sensorType) {
      case SensorType.AirFlow:
        return [StatisticType.AirFlow];
      case SensorType.Ammonia:
        return [StatisticType.Ammonia];
      case SensorType.AnimalWelfareIndex:
        return [StatisticType.AnimalWelfareIndex];
      case SensorType.BinFeedAmount:
        return [StatisticType.FeedPerBird, StatisticType.FeedConversion];
      case SensorType.CarbonDioxide:
        return [StatisticType.CarbonDioxide];
      case SensorType.DailyGasConsumption:
        return [StatisticType.GasConsumption];
      case SensorType.DailyPowerConsumption:
        return [StatisticType.PowerConsumption];
      case SensorType.EggsProduced:
        return [StatisticType.EggsProduced];
      case SensorType.Humidity:
        return [StatisticType.Humidity];
      case SensorType.OutsideHumidity:
        return [StatisticType.OutsideHumidity];
      case SensorType.OutsideTemperature:
        return [StatisticType.OutsideTemperature];
      case SensorType.StaticPressure:
        return [StatisticType.StaticPressure];
      case SensorType.Temperature:
        return [StatisticType.Temperature];
      case SensorType.WaterConsumption:
        return [StatisticType.WaterConsumption];
      case SensorType.Weight:
        return [
          StatisticType.WeightGain,
          StatisticType.AverageWeight,
          StatisticType.Uniformity,
          StatisticType.CV,
        ];
      default:
        return [];

      //All of these sensor types do not have statistic types
      // case SensorType.Distress:
      // case SensorType.Health:
      // case SensorType.Light:
      // case SensorType.Movement:
      // case SensorType.RuntimeConsumption:
    }
  }

  export function getSensorTypeForConversionByStatisticType(
    statisticType: StatisticType
  ): SensorType {
    switch (statisticType) {
      case StatisticType.AirFlow:
        return SensorType.AirFlow;
      case StatisticType.Ammonia:
        return SensorType.Ammonia;
      case StatisticType.AnimalWelfareIndex:
        return SensorType.AnimalWelfareIndex;
      case StatisticType.AverageWeight:
        return SensorType.Weight;
      case StatisticType.CarbonDioxide:
        return SensorType.CarbonDioxide;
      case StatisticType.EggsProduced:
        return SensorType.EggsProduced;
      case StatisticType.FeedPerBird:
        return SensorType.BinFeedAmount;
      case StatisticType.GasConsumption:
        return SensorType.DailyGasConsumption;
      case StatisticType.Humidity:
        return SensorType.Humidity;
      case StatisticType.Light:
        return SensorType.Light;
      case StatisticType.OutsideHumidity:
        return SensorType.OutsideHumidity;
      case StatisticType.OutsideTemperature:
        return SensorType.OutsideTemperature;
      case StatisticType.PowerConsumption:
        return SensorType.DailyPowerConsumption;
      case StatisticType.StaticPressure:
        return SensorType.StaticPressure;
      case StatisticType.Temperature:
        return SensorType.Temperature;
      case StatisticType.WaterConsumption:
        return SensorType.WaterConsumption;
      case StatisticType.WeightGain:
        return SensorType.Weight;
      default:
        return undefined;
    }
  }

  export function toIconName(statisticType: StatisticType): string {
    switch (statisticType) {
      case StatisticType.AirFlow:
        return 'air-flow';
      case StatisticType.Ammonia:
        return 'ammonia';
      case StatisticType.AnimalWelfareIndex:
        return 'animal-welfare-index';
      case StatisticType.AverageWeight:
        return 'weight';
      case StatisticType.CarbonDioxide:
        return 'carbon-dioxide';
      case StatisticType.CV:
        return 'cv';
      case StatisticType.EggsProduced:
        return 'eggs';
      case StatisticType.FeedConversion:
        return 'feed-conversion';
      case StatisticType.FeedPerBird:
        return 'feed';
      case StatisticType.GasConsumption:
        return 'gas-consumption';
      case StatisticType.Humidity:
      case StatisticType.OutsideHumidity:
        return 'humidity';
      case StatisticType.OutsideTemperature:
      case StatisticType.Temperature:
        return 'temperature';
      case StatisticType.PercentDepletion:
        return 'mortality';
      case StatisticType.PowerConsumption:
        return 'power-consumption';
      case StatisticType.StaticPressure:
        return 'static-pressure';
      case StatisticType.Uniformity:
        return 'uniformity';
      case StatisticType.WaterConsumption:
        return 'water';
      case StatisticType.WeightGain:
        return 'weight';
      case StatisticType.Light:
        return 'light-intensity';
      default:
        return '';
    }
  }

  export function getDecimalPlaces(statisticType: StatisticType): number {
    switch (statisticType) {
      case StatisticType.Uniformity:
      case StatisticType.CV:
        return 0;
      case StatisticType.PercentDepletion:
      case StatisticType.FeedConversion:
      case StatisticType.FeedPerBird:
        return 2;
      default:
        return SensorType.getNumericDecimalPlaces(
          this.getSensorTypeForConversionByStatisticType(statisticType)
        );
    }
  }
}
