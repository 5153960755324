import { Injectable, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Alert } from '@sonar/shared/alerts/alert';
import { FarmType } from '@sonar/shared/farm/farm-type';
import { SensorType, SonarDataType } from '@sonar/shared/sensor-type';
import { DataService } from '..';
import { DataServiceFactory } from '../data-service.factory';
import { MaxCacheAge } from '../max-cache-age';
import { UnitConversionService } from '../unit-conversion.service';
import { HouseDataType } from '../user-settings/house-sensor-type';
import { UserSetting } from '../user-settings/user-settings';
import { UserSettingsService } from '../user-settings/user-settings.service';
import { AlertService } from './alert.service';
import { SensorSummary } from '@sonar/shared/sensor-summary';
import { HouseMetadata } from '../../shared/house-metadata';
import { LaunchDarklyService } from '../launch-darkly.service';
import { FeatureFlags } from '@sonar/shared/feature-flags';
import { HouseDataTypeSorterService } from './house-data-type-sorter.service';

@Injectable()
export class HouseDataService implements OnDestroy {
  readonly sensorCount: number = 5;
  private readonly housesDataService: DataService;
  private readonly unsubscribe$ = new Subject<void>();
  private alerts: Alert[];
  private userSetting: UserSetting;

  constructor(
    private readonly alertService: AlertService,
    private readonly launchDarklyService: LaunchDarklyService,
    private readonly dataServiceFactory: DataServiceFactory,
    private readonly unitConversionService: UnitConversionService,
    private readonly userSettingsService: UserSettingsService,
    private readonly houseDataTypeSorter: HouseDataTypeSorterService,
  ) {
    this.housesDataService = this.dataServiceFactory.create({
      endPoint: 'Houses',
      maxCacheAge: MaxCacheAge.Medium,
    });
    this.alertService.alerts$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((alerts) => !!alerts),
      )
      .subscribe((alerts) => {
        this.alerts = alerts;
      });
    this.userSettingsService.$settings
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((s) => {
        this.userSetting = s;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async getHouseMetadatas(farmId: number): Promise<HouseMetadata[]> {
    const houses: HouseMetadata[] = await this.housesDataService
      .getEntities<HouseMetadata>({ farmId }, 'GetHouseMetadatas')
      .toPromise();
    if (!houses) {
      return [];
    }

    const houseNosAreNumeric = this.houseNosAreNumeric(houses);
    return houses
      ? _.sortBy(houses, (h) =>
          houseNosAreNumeric ? h.houseNoNumeric : h.houseNo,
        )
      : houses;
  }

  async getHouseDetails(farmId: number) {
    const houses: SensorSummary[] = await this.housesDataService
      .getEntities<SensorSummary>({ farmId })
      .toPromise();
    if (!houses) {
      return [];
    }

    const houseNosAreNumeric = this.houseNosAreNumeric(houses);

    const houseDetails = houses
      ? _.sortBy(houses, (h) =>
          houseNosAreNumeric ? h.houseNoNumeric : h.houseNo,
        )
      : houses;
    return houseDetails;
  }

  houseNosAreNumeric(houses) {
    let isNumeric = true;
    houses.forEach((house) => {
      const numbers = house.houseNo.replace(/[^0-9]/g, '');
      const numericHouseNo = parseInt(numbers, 10);
      if (!isNaN(numericHouseNo)) {
        house.houseNoNumeric = numericHouseNo;
      } else {
        isNumeric = false;
      }
    });

    return isNumeric;
  }

  async getHouseDetail(farmId: number, houseId: number) {
    const allHouseDetails = await this.getHousePenDetails(farmId, houseId);

    if (Array.isArray(allHouseDetails)) {
      return allHouseDetails.find((detail) => _.isNil(detail.penId));
    }

    return allHouseDetails;
  }

  async getHousePenDetails(farmId: number, houseId: number) {
    const houseDetails = await this.housesDataService
      .getEntity<SensorSummary[]>({ farmId, houseId }, 'GetHouse')
      .toPromise();

    return houseDetails;
  }

  buildHouseData(sensorSummary: SensorSummary): HouseDataType[] {
    const sensorData = this.buildSensorData(sensorSummary);
    sensorData.push(
      this.buildSonarDataTypeData(
        sensorSummary,
        SonarDataType.PercentDepletion,
      ),
    );

    if (
      this.launchDarklyService.boolVariation(
        FeatureFlags.ShowProjectedWeightAsSensor,
      ) &&
      sensorSummary.sensorCounts.some(
        (s) => (s.sensorType as SensorType) === SensorType.BinFeedAmount,
      ) &&
      !sensorSummary.sensorCounts.some(
        (s) => (s.sensorType as SensorType) === SensorType.Weight,
      )
    ) {
      sensorData.push(
        this.buildSonarDataTypeData(
          sensorSummary,
          SonarDataType.ProjectedWeightByFc,
        ),
      );
    }

    return this.houseDataTypeSorter.sortAlphabetically(sensorData);
  }

  private buildSensorData(sensorSummary: SensorSummary): HouseDataType[] {
    return sensorSummary.sensorCounts.map((s) => {
      const sensorType: HouseDataType = {
        sensorType: s.sensorType,
        iconName: SensorType.toIconName(s.sensorType),
        sensorStyleClass: SensorType.toSensorStyleClass(s.sensorType),
        label: SensorType.toLabelKey(s.sensorType),
        sensorCount: s.count,
        kpiClass: this.getKpiClass(sensorSummary, s.sensorType),
        value: this.getFarmDetailValue(sensorSummary, s.sensorType),
        unit: this.getUnitConversionMethod(s.sensorType),
        status:
          (this.alerts &&
            !!this.alerts.find(
              (f) =>
                !f.end &&
                f.sensorType === s.sensorType &&
                f.houseId === sensorSummary.houseId,
            )) ||
          (sensorSummary.offlineSensors &&
            !!sensorSummary.offlineSensors.find(
              (f) => f.sensorType === s.sensorType,
            )),
        screenIndex: this.getOrDefaultScreenIndex(s.sensorType),
      };

      const secondaryValue = this.getFarmDetailSecondaryValue(
        sensorSummary,
        s.sensorType,
      );

      if (!_.isNil(secondaryValue)) {
        sensorType.secondaryValue = secondaryValue;
      }
      return sensorType;
    });
  }

  private buildSonarDataTypeData(
    sensorSummary: SensorSummary,
    sonarDataType: SonarDataType,
  ): HouseDataType {
    return {
      sensorType: sonarDataType,
      iconName: SonarDataType.toIconName(sonarDataType),
      sensorStyleClass: SonarDataType.toSensorStyleClass(sonarDataType),
      label: SonarDataType.toLabelKey(sonarDataType),
      sensorCount: SonarDataType.maxSensorCount(sonarDataType),
      value: this.getFarmDetailValue(sensorSummary, sonarDataType),
      secondaryValue: this.getFarmDetailSecondaryValue(
        sensorSummary,
        sonarDataType,
      ),
      unit: this.getUnitConversionMethod(sonarDataType),
      kpiClass: this.getKpiClass(sensorSummary, sonarDataType),
      status: false,
      screenIndex: this.getOrDefaultScreenIndex(sonarDataType),
    };
  }

  private getOrDefaultScreenIndex(sensorType: any): number {
    const hasUserDefinedIndexForSensor = (s: SensorType) =>
      this.userSetting.houseCardSensorsOrder.some((so) => so === sensorType);

    return this.hasUserDefinedSensorOrder() &&
      hasUserDefinedIndexForSensor(sensorType)
      ? this.userSetting.houseCardSensorsOrder.indexOf(sensorType)
      : -1;
  }

  private hasUserDefinedSensorOrder = () =>
    this.userSetting && this.userSetting.houseCardSensorsOrder;

  private getKpiClass(data: any, sensorType: SonarDataType | SensorType) {
    switch (sensorType) {
      case SensorType.Temperature:
      case SensorType.OutsideTemperature:
      case SensorType.Humidity:
      case SensorType.AirFlow:
      case SensorType.CarbonDioxide:
        return SensorType.getPropertyNames(sensorType, '', 'HasAlert').some(
          (p) => data[p],
        )
          ? 'warning'
          : '';
      case SensorType.BinFeedAmount:
        return data.binFeedAmountHasAlert ? 'danger' : '';
      case SensorType.Weight:
        return data.birdWeightHasAlert ? 'danger' : '';
      case SensorType.Ammonia:
        return data.ammoniaHasAlert ? 'ammonia' : '';
      default:
        return '';
    }
  }

  private getFarmDetailValue(
    data: SensorSummary,
    sonarDataType: SonarDataType | SensorType,
  ) {
    let sensorValue: number;
    switch (sonarDataType) {
      case SonarDataType.PercentDepletion:
        const livability = data.livability ? data.livability : 100;
        const depletion = 100 - livability;
        sensorValue = depletion;
        break;
      case SonarDataType.ProjectedWeightByFc:
        return data.projectedWeightByFc;
      case SensorType.Temperature:
        sensorValue = data.averageTemperature ? data.averageTemperature : 0;
        break;
      case SensorType.WaterConsumption:
        sensorValue = data.totalWaterConsumption
          ? data.totalWaterConsumption
          : 0;
        break;
      case SensorType.BinFeedAmount:
        sensorValue = data.hasSharedFeedBins
          ? data.allocatedFeedAmount
            ? data.allocatedFeedAmount
            : 0
          : data.totalFeedAmount
            ? data.totalFeedAmount
            : 0;
        break;
      case SensorType.Humidity:
        sensorValue = data.averageHumidity ? data.averageHumidity : 0;
        break;
      case SensorType.Weight:
        if (data.showSexedBirdWeight) {
          sensorValue = data.femaleAverageBirdWeight;
        } else {
          sensorValue = data.averageBirdWeight ? data.averageBirdWeight : 0;
        }
        break;
      case SensorType.Ammonia:
        sensorValue = data.averageAmmonia ? data.averageAmmonia : 0;
        break;
      case SensorType.AirFlow:
        sensorValue = data.averageAirFlow ? data.averageAirFlow : 0;
        break;
      case SensorType.CarbonDioxide:
        sensorValue = data.averageCarbonDioxide ? data.averageCarbonDioxide : 0;
        break;
      case SensorType.RuntimeConsumption:
        sensorValue = data.runtimeConsumption ? data.runtimeConsumption : 0;
        break;
      case SensorType.Light:
        sensorValue = data.averageLight ? data.averageLight : 0;
        break;
      case SensorType.Movement:
        sensorValue = data.movement ? data.movement : 0;
        break;
      case SensorType.Distress:
        sensorValue = data.distress ? data.distress : 0;
        break;
      case SensorType.DailyGasConsumption:
        sensorValue = data.totalGasConsumption ? data.totalGasConsumption : 0;
        break;
      case SensorType.DailyPowerConsumption:
        sensorValue = data.totalPowerConsumption
          ? data.totalPowerConsumption
          : 0;
        break;
      case SensorType.OutsideTemperature:
        sensorValue = data.outsideTemperature ? data.outsideTemperature : 0;
        break;
      case SensorType.EggsProduced:
        sensorValue = data.eggsProduced ? data.eggsProduced : 0;
        break;
      case SensorType.StaticPressure:
        sensorValue = data.staticPressure ? data.staticPressure : 0;
        break;
      case SensorType.AnimalWelfareIndex:
        sensorValue = data.animalWelfareIndex ? data.animalWelfareIndex : 0;
        break;
      case SensorType.OutsideHumidity:
        sensorValue = data.outsideHumidity ? data.outsideHumidity : 0;
        break;
    }
    return sensorValue;
  }

  private getUnitConversionMethod(sonarDataType: SonarDataType | SensorType) {
    switch (sonarDataType) {
      case SensorType.Temperature:
      case SensorType.OutsideTemperature:
        return this.unitConversionService.getTemperatureUnit();
      case SensorType.WaterConsumption:
      case SensorType.DailyGasConsumption:
        return this.unitConversionService.getVolumeUnit();
      case SensorType.BinFeedAmount:
        return this.unitConversionService.getFeedBinAmountUnit();
      case SonarDataType.PercentDepletion:
      case SensorType.Humidity:
      case SensorType.OutsideHumidity:
        return '%';
      case SensorType.Weight:
      case SonarDataType.ProjectedWeightByFc:
        return this.unitConversionService.getBirdWeightUnit();
      case SensorType.Ammonia:
        return this.unitConversionService.getAmmoniaUnit();
      case SensorType.AirFlow:
        return this.unitConversionService.getAirFlowUnit();
      case SensorType.CarbonDioxide:
        return this.unitConversionService.getCarbonDioxideUnit();
      case SensorType.RuntimeConsumption:
        return this.unitConversionService.getFeedBinAmountUnit();
      case SensorType.DailyPowerConsumption:
        return this.unitConversionService.getPowerUnit();
      case SensorType.EggsProduced:
        return this.unitConversionService.getEggUnit();
      case SensorType.StaticPressure:
        return this.unitConversionService.getStaticPressureUnit();
      case SensorType.AnimalWelfareIndex:
        return this.unitConversionService.getAnimalWelfareIndexUnit();
      case SensorType.Light:
        return this.unitConversionService.getLightUnit();
      default:
        return '';
    }
  }

  private getFarmDetailSecondaryValue(
    data: SensorSummary,
    sensorType: SonarDataType | SensorType,
  ): any {
    if (
      sensorType === SensorType.Weight &&
      data.femaleAverageBirdWeight &&
      data.maleAverageBirdWeight &&
      (data.farmType === FarmType.BIM || data.farmType === FarmType.LIM)
    ) {
      return data.maleAverageBirdWeight;
    }

    if (sensorType === SonarDataType.ProjectedWeightByFc) {
      return data.projectedWeightByFcAge?.toString();
    }

    return null;
  }
}
