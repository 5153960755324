import { UnitConversionService } from '@sonar/core/unit-conversion.service';
import { SonarDataType } from '@sonar/shared/sensor-type';
import { SonarData } from './sonar-data';
import { NumberSymbol } from '@angular/common';

export class PercentDepletionSonarData extends SonarData {
  numberSymbol: NumberSymbol = NumberSymbol.PercentSign;
  sonarDataType: SonarDataType = SonarDataType.PercentDepletion;

  isSensor = false;
  decimalPlaces = '1.2-2';
  maxSensorCount: number;
  labelKey = 'KPIS.DEPLETION';
  statistic = 'mortality';
  iconName = 'mortality';
  sensorStyleClass = 'ami-mortality';

  getPropertyNames = (prefix?: string, suffix?: string): string[] => [];
  getSensorUnit = (u: UnitConversionService): string => '';
  convertUnit(
    value: number,
    unitConversionService: UnitConversionService
  ): number {
    throw new Error('Method not implemented.');
  }
}
