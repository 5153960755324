import { Injectable, Pipe, PipeTransform, Inject } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { UnitConversionService } from '../unit-conversion.service';
import { SensorType } from '@sonar/shared/sensor-type';

@Pipe({
  name: 'volume',
})
@Injectable()
export class VolumePipe implements PipeTransform {
  constructor(
    private readonly unitConversionService: UnitConversionService,
    private readonly translateService: TranslateService
  ) {}

  transform(item: number, unit: 'L' | 'gal', showUnits?: boolean) {
    const numberPipe = new DecimalPipe(
      this.translateService.currentLang || this.translateService.defaultLang
    );
    if (unit === 'gal') {
      const imperialValue = numberPipe.transform(
        this.unitConversionService.convertVolume(item),
        SensorType.getDecimalPlaces(SensorType.WaterConsumption)
      );
      if (showUnits) {
        return `${imperialValue} ${this.unitConversionService.getVolumeUnit()}`;
      }
      return imperialValue;
    }
    const value = numberPipe.transform(
      item,
      SensorType.getDecimalPlaces(SensorType.WaterConsumption)
    );
    if (showUnits) {
      return `${value} ${this.unitConversionService.getVolumeUnit()}`;
    }
    return value;
  }
}
