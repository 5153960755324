import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TemperaturePipe } from './temperature.pipe';
import { AmmoniaPipe } from './ammonia.pipe';
import { HumidityPipe } from './humidity.pipe';
import { VolumePipe } from './volume.pipe';
import { WeightPipe } from './weight.pipe';
import { LightPipe } from './light.pipe';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { AirFlowPipe } from './air-flow.pipe';
import { CarbonDioxidePipe } from './carbon-dioxide.pipe';
import { PowerPipe } from './power.pipe';
import { PressurePipe } from './pressure.pipe';
import { UserSettingsService } from '../user-settings/user-settings.service';
import { UnitConversionService } from '../unit-conversion.service';
import { SensorType } from '@sonar/shared/sensor-type';

@Pipe({
  name: 'sensor',
})
@Injectable()
export class SensorPipe implements PipeTransform {
  constructor(
    private readonly userSettingsService: UserSettingsService,
    private readonly unitConversionService: UnitConversionService,
    private readonly translateService: TranslateService
  ) {}

  transform(
    item: number | number[],
    sensorType: SensorType,
    showUnits?: boolean
  ) {
    if (Array.isArray(item)) {
      const itemsTransformed = [];
      if (item.some((v) => !v)) {
        return `N/A`;
      }
      item.forEach((i) =>
        itemsTransformed.push(this.transform(i, sensorType, showUnits))
      );
      return itemsTransformed.join(' / ');
    } else {
      return this.convert(item, sensorType, showUnits);
    }
  }

  private convert(item: number, sensorType: SensorType, showUnits?: boolean) {
    switch (sensorType) {
      case SensorType.Ammonia:
        return new AmmoniaPipe(
          this.unitConversionService,
          this.translateService
        ).transform(item, showUnits);
      case SensorType.Humidity:
      case SensorType.OutsideHumidity:
        return new HumidityPipe(
          this.unitConversionService,
          this.translateService
        ).transform(item, showUnits);
      case SensorType.Temperature:
      case SensorType.OutsideTemperature:
        return new TemperaturePipe(
          this.unitConversionService,
          this.translateService
        ).transform(
          item,
          this.unitConversionService.getTemperatureUnit(),
          showUnits
        );
      case SensorType.WaterConsumption:
      case SensorType.DailyGasConsumption:
        return new VolumePipe(
          this.unitConversionService,
          this.translateService
        ).transform(
          item,
          this.unitConversionService.getVolumeUnit(),
          showUnits
        );
      case SensorType.Weight:
        return new WeightPipe(
          this.userSettingsService,
          this.unitConversionService,
          this.translateService
        ).transform(
          item,
          sensorType,
          SensorType.getDecimalPlaces(sensorType),
          showUnits
        );
      case SensorType.RuntimeConsumption:
      case SensorType.BinFeedAmount:
        return new WeightPipe(
          this.userSettingsService,
          this.unitConversionService,
          this.translateService
        ).transform(
          item,
          sensorType,
          SensorType.getDecimalPlaces(sensorType),
          showUnits
        );
      case SensorType.AirFlow:
        return new AirFlowPipe(
          this.unitConversionService,
          this.translateService
        ).transform(
          item,
          this.unitConversionService.getAirFlowUnit(),
          showUnits
        );
      case SensorType.CarbonDioxide:
        return new CarbonDioxidePipe(
          this.unitConversionService,
          this.translateService
        ).transform(item, showUnits);
      case SensorType.Light:
        return new LightPipe(
          this.unitConversionService,
          this.translateService
        ).transform(item, this.unitConversionService.getLightUnit(), showUnits);
      case SensorType.DailyPowerConsumption:
        return new PowerPipe(
          this.unitConversionService,
          this.translateService
        ).transform(item, showUnits);
      case SensorType.StaticPressure:
        return new PressurePipe(
          this.unitConversionService,
          this.translateService
        ).transform(item, showUnits);
      default:
        return new DecimalPipe(
          this.translateService.currentLang || this.translateService.defaultLang
        ).transform(item, SensorType.getDecimalPlaces(sensorType));
    }
  }
}
