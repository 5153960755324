import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_Stock from 'highcharts/modules/stock';
import { cloneDeep } from 'lodash';
import { CardChartArgs } from '@sonar/shared/cards/mt-card-chart/card-chart-args';
import { CardChartMetadata } from '@sonar/shared/cards/mt-card-chart/card-chart-metadata';
import { KPI } from '@sonar/shared/cards/kpi';
import { KPIThreshold } from '@sonar/shared/cards/kpi-threshold';
import { ChartNavigatedArgs } from './chart-navigated-args';
import { InteractiveChartComponent } from './interactive-chart.component';
import { ChartSettings } from './chart-settings';

HC_Stock(Highcharts);
@Component({
  selector: 'interactive-card-chart',
  styleUrls: ['./interactive-card-chart.component.scss'],
  templateUrl: './interactive-card-chart.component.html',
})
export class InteractiveCardChartComponent implements OnChanges, OnDestroy {
  @HostBinding('style.display') displayCard: 'none' | 'block';

  @ViewChild(InteractiveChartComponent)
  interactiveChart: InteractiveChartComponent;

  @Input() cardChartArgs: CardChartArgs;
  @Input() cardChartMetadata: CardChartMetadata;
  @Input() chartSettings: ChartSettings;
  @Input() kpis: KPI[];
  @Input() loaded = false;
  @Input() showFullScreen = false;

  @Output() chartNavigated: EventEmitter<ChartNavigatedArgs> =
    new EventEmitter<ChartNavigatedArgs>();
  @Output() fullScreenClicked: EventEmitter<void> = new EventEmitter<void>();

  formattedKpis: any[];
  kpiThresholdEnum = KPIThreshold;

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.showFullScreen) {
      this.displayCard = this.showFullScreen ? 'none' : 'block';
    }
    if (changes.kpis && this.kpis) {
      this.formattedKpis = cloneDeep(this.kpis);
      this.formattedKpis.forEach((kpi) => {
        kpi.color = this.getStatusColor(kpi);
      });
    }
  }

  ngOnDestroy(): void {
    this.loaded = false;
    this.cardChartArgs = null;
    this.cardChartMetadata = null;
    this.chartSettings = null;
  }

  onChartNavigated(args: ChartNavigatedArgs): void {
    this.chartNavigated.emit(args);
  }

  onFullScreenClicked(): void {
    this.showFullScreen = true;
    this.fullScreenClicked.emit();
  }

  async updateData(data: any[]): Promise<void> {
    await this.interactiveChart.updateData(data);
  }

  toggleLoading(toggle: boolean): void {
    this.interactiveChart.toggleLoading(toggle);
  }

  private getStatusColor(kpi: KPI): string {
    const defaultColor = '';
    if (kpi.excludeColor) {
      return defaultColor;
    }
    switch (kpi.status) {
      case KPIThreshold.Below:
        return kpi.invertStatus ? 'green' : 'red';
      case KPIThreshold.Above:
        return kpi.invertStatus ? 'red' : 'green';
      default:
        return defaultColor;
    }
  }
}
