/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { ClientDataType } from './client-data-type';
import {
  Component,
  OnChanges,
  SimpleChanges,
  Input,
  Renderer2,
  EventEmitter,
  Output,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { CardTableColumnArgs } from './card-table-column-args';
import { KPI } from '@sonar/core/kpis/kpi';

@Component({
  selector: 'mt-card-table',
  templateUrl: 'mt-card-table.component.html',
  styleUrls: ['./mt-card-table.component.scss', '../mt-card/mt-card.component.scss']
})
export class MtCardTableComponent implements OnChanges, OnDestroy {
  constructor(
    protected readonly renderer: Renderer2,
    private readonly element: ElementRef
  ) {}

  @Input() cardTableColumnArgs: CardTableColumnArgs[];
  @Input() dataSource: any[];
  @Input() title: string;
  @Input() kpis: [KPI] | [KPI, KPI];
  @Input() loaded: boolean;

  @Output() onSelectedRow = new EventEmitter<any>();

  ClientDataTypeEnum = ClientDataType;
  loadingCardTable: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cardTableColumnArgs && this.cardTableColumnArgs) {
      if (this.cardTableColumnArgs.some((f) => !!f.index)) {
        this.cardTableColumnArgs = this.cardTableColumnArgs.sort(
          (a, b) => a.index - b.index
        );
      }
      this.loadingCardTable = setTimeout(() => this.loaded = true);
    }
  }

  ngOnDestroy() {
    clearTimeout(this.loadingCardTable);
  }

  selectRow(rowElement: HTMLElement, data: any) {
    const isRowSelected = this.element.nativeElement.querySelector('.active');
    if (isRowSelected) {
      document.querySelector('.active').classList.remove('active');
    }
    if (rowElement.classList.contains('active')) {
      this.renderer.removeClass(rowElement, 'active');
    } else {
      this.renderer.addClass(rowElement, 'active');
    }
    this.onSelectedRow.emit(data);
  }

  isString(data: any) {
    return typeof data === 'string';
  }
}
