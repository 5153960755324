<ion-tabs (ionTabsWillChange)="tabsWillChange($event)">
  <ion-tab-bar slot="bottom">
    <ion-tab-button data-testid="benchmarking-tab" data-pendoid="benchmarking-tab" tab="benchmarking">
      <i class="ami-reports"></i>
      <ion-label>{{'TABS.TAB4_TITLE' | translate}}</ion-label>
    </ion-tab-button>

    <ion-tab-button data-testid="alerts-tab" data-pendoid="alerts-tab" tab="alerts">
      <i class="ami-alert"></i>
      <ion-label>{{'TABS.TAB1_TITLE' | translate}}</ion-label>
    </ion-tab-button>

    <ion-tab-button data-testid="farms-tab" data-pendoid="farms-tab" tab="farms">
      <i class="ami-farm"></i>
      <ion-label>{{'TABS.TAB2_TITLE' | translate}}</ion-label>
    </ion-tab-button>

    <ion-tab-button data-testid="settings-tab" data-pendoid="settings-tab" tab="settings">
      <i class="ami-gear"></i>
      <ion-label>{{'TABS.TAB3_TITLE' | translate}}</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
