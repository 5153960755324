/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/no-namespace */

import { SensorType } from '../sensor-type';
import { AlertSettingHeader } from './alert-setting-header';
import { AlertSettingOption } from './alert-setting-option';
import { AlertType } from './alert-type';

export interface AlertSetting {
  label: string;
  alertSettingHeader: AlertSettingHeader;
  options: AlertSettingOption[];
}

export namespace AlertSetting {
  export function getSettingKey(option: AlertSettingOption): string {
    if (!!option.sensorType) {
      option.sensorType = verifySensorType(option.alertType, option.sensorType);
    }

    return `${AlertType[option.alertType].toString()}${
      !!option.sensorType || option.sensorType === 0
        ? `-${SensorType[option.sensorType].toString()}`
        : ''
    }${option.isCritical ? '-Critical' : ''}`;
  }

  export function getAlertSettings(): AlertSetting[] {
    return [
      {
        alertSettingHeader: AlertSettingHeader.AirFlow,
        label: 'KPIS.AIRFLOW',
        options: [
          {
            label: 'ALERTS.THRESHOLD',
            alertType: AlertType.Threshold,
            sensorType: SensorType.AirFlow,
            isCritical: false,
            isSelected: true,
          },
          {
            label: 'ALERTS.CRITICAL_THRESHOLD',
            alertType: AlertType.Threshold,
            sensorType: SensorType.AirFlow,
            isCritical: true,
            isSelected: true,
          },
        ],
      },
      {
        alertSettingHeader: AlertSettingHeader.Ammonia,
        label: 'KPIS.AMMONIA',
        options: [
          {
            label: 'ALERTS.THRESHOLD',
            alertType: AlertType.Threshold,
            sensorType: SensorType.Ammonia,
            isCritical: false,
            isSelected: true,
          },
          {
            label: 'ALERTS.CRITICAL_THRESHOLD',
            alertType: AlertType.Threshold,
            sensorType: SensorType.Ammonia,
            isCritical: true,
            isSelected: true,
          },
        ],
      },
      {
        alertSettingHeader: AlertSettingHeader.CarbonDioxide,
        label: 'KPIS.CARBON_DIOXIDE',
        options: [
          {
            label: 'ALERTS.THRESHOLD',
            alertType: AlertType.Threshold,
            sensorType: SensorType.CarbonDioxide,
            isCritical: false,
            isSelected: true,
          },
          {
            label: 'ALERTS.CRITICAL_THRESHOLD',
            alertType: AlertType.Threshold,
            sensorType: SensorType.CarbonDioxide,
            isCritical: true,
            isSelected: true,
          },
        ],
      },
      {
        alertSettingHeader: AlertSettingHeader.Depletion,
        label: 'KPIS.DEPLETION',
        options: [
          {
            label: 'KPIS.NO_DEPLETION',
            alertType: AlertType.NoDepletion,
            isCritical: false,
            isSelected: true,
          },
          {
            label: 'ALERT_HIGH_DEPLETION',
            alertType: AlertType.DepletionOverMaxTarget,
            isCritical: false,
            isSelected: true,
          },
        ],
      },
      {
        alertSettingHeader: AlertSettingHeader.Device,
        label: 'KPIS.DEVICE',
        options: [
          {
            label: 'SENSORS_OFFLINE',
            alertType: AlertType.DeviceOffline,
            isCritical: false,
            isSelected: true,
          },
        ],
      },
      {
        alertSettingHeader: AlertSettingHeader.Events,
        label: 'KPIS.EVENTS',
        options: [
          {
            label: 'UPCOMING_PLACEMENT',
            alertType: AlertType.UpcomingPlacement,
            isCritical: false,
            isSelected: true,
          },
          {
            label: 'UPCOMING_FEED_DELIVERY',
            alertType: AlertType.UpcomingFeedDelivery,
            isCritical: false,
            sensorType: SensorType.BinFeedAmount,
            isSelected: true,
          },
          {
            label: 'UPCOMING_PROCESSING',
            alertType: AlertType.UpcomingProcessing,
            isCritical: false,
            isSelected: true,
          },
          {
            label: 'PLACEMENT_TRANSACTION',
            alertType: AlertType.PlacementTransaction,
            isCritical: false,
            isSelected: true,
          },
          {
            label: 'FEED_TRANSACTION',
            alertType: AlertType.FeedTransaction,
            sensorType: SensorType.BinFeedAmount,
            isCritical: false,
            isSelected: true,
          },
          {
            label: 'PROCESSING_TRANSACTION',
            alertType: AlertType.ProcessingTransaction,
            isCritical: false,
            isSelected: true,
          },
        ],
      },
      {
        alertSettingHeader: AlertSettingHeader.Feed,
        label: 'KPIS.FEED',
        options: [
          {
            label: 'ALERT_FEED_WILL_BE_EMPTY',
            alertType: AlertType.FeedWillBeEmpty,
            sensorType: SensorType.BinFeedAmount,
            isCritical: false,
            isSelected: true,
          },
          {
            label: 'KPIS.FEED_CONSUMPTION_DECLINE',
            alertType: AlertType.FeedConsumptionDecline,
            sensorType: SensorType.BinFeedAmount,
            isCritical: false,
            isSelected: true,
          },
          {
            label: 'ALERTS.FEED_HOURLY_THRESHOLD',
            alertType: AlertType.FeedConsumptionHourlyThreshold,
            sensorType: SensorType.BinFeedAmount,
            isCritical: false,
            isSelected: true,
          },
          {
            label: 'ALERTS.FEED_DAILY_THRESHOLD',
            alertType: AlertType.FeedConsumptionDailyThreshold,
            sensorType: SensorType.BinFeedAmount,
            isCritical: false,
            isSelected: true,
          },
        ],
      },
      {
        alertSettingHeader: AlertSettingHeader.Humidity,
        label: 'KPIS.HUMIDITY',
        options: [
          {
            label: 'ALERTS.THRESHOLD',
            alertType: AlertType.Threshold,
            sensorType: SensorType.Humidity,
            isCritical: false,
            isSelected: true,
          },
          {
            label: 'ALERTS.CRITICAL_THRESHOLD',
            alertType: AlertType.Threshold,
            sensorType: SensorType.Humidity,
            isCritical: true,
            isSelected: true,
          },
        ],
      },
      {
        alertSettingHeader: AlertSettingHeader.Temperature,
        label: 'KPIS.TEMPERATURE',
        options: [
          {
            label: 'ALERTS.THRESHOLD',
            alertType: AlertType.Threshold,
            sensorType: SensorType.Temperature,
            isCritical: false,
            isSelected: true,
          },
          {
            label: 'ALERTS.CRITICAL_THRESHOLD',
            alertType: AlertType.Threshold,
            sensorType: SensorType.Temperature,
            isCritical: true,
            isSelected: true,
          },
          {
            label: 'KPIS.RATE_OF_CHANGE',
            alertType: AlertType.RateOfChange,
            sensorType: SensorType.Temperature,
            isCritical: false,
            isSelected: true,
          },
        ],
      },
      {
        alertSettingHeader: AlertSettingHeader.Water,
        label: 'KPIS.WATER',
        options: [
          {
            label: 'ALERTS.THRESHOLD',
            alertType: AlertType.Threshold,
            sensorType: SensorType.WaterConsumption,
            isCritical: false,
            isSelected: true,
          },
          {
            label: 'ALERTS.WATER_FLOW_RATE_DECLINE',
            alertType: AlertType.DeclineInPeakFlowWaterRate,
            sensorType: SensorType.WaterConsumption,
            isCritical: false,
            isSelected: true,
          },
          {
            label: 'ALERTS.WATER_CONSUMPTION_DECREASE',
            alertType: AlertType.TotalWaterConsumptionDecrease,
            sensorType: SensorType.WaterConsumption,
            isCritical: false,
            isSelected: true,
          },
        ],
      },
      {
        alertSettingHeader: AlertSettingHeader.Weight,
        label: 'KPIS.WEIGHT',
        options: [
          {
            label: 'ALERTS.THRESHOLD',
            alertType: AlertType.Threshold,
            sensorType: SensorType.Weight,
            isCritical: false,
            isSelected: true,
          },
          {
            label: 'KPIS.WEIGHT_LOSS',
            alertType: AlertType.WeightLoss,
            sensorType: SensorType.Weight,
            isCritical: false,
            isSelected: true,
          },
          {
            label: 'KPIS.WEIGHT_GAIN_DECREASE',
            alertType: AlertType.WeightGainDecrease,
            sensorType: SensorType.Weight,
            isCritical: false,
            isSelected: true,
          },
        ],
      },
    ];
  }

  function verifySensorType(
    alertType: AlertType,
    sensorType?: SensorType
  ): SensorType | null {
    switch (alertType) {
      case AlertType.DeviceOffline:
      case AlertType.NoDepletion:
      case AlertType.DepletionOverMaxTarget:
        return null;
      default:
        return sensorType;
    }
  }
}
